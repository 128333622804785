import React from "react";
import { createContext } from "react";

const initSglContextPropsState = {
  company_id: '-1',
  company_name: '',
  company_urlpath: '',
  current_user: '',
  is_mobile: false,
  reportToGA: () => { },
  logWPPluginError: () => { },
  logWPPlugin: () => { },
  fetchMyEntriesData: () => { },
  checkLoginOnSGL: () => { },
  loginOnSGL: () => { },
  loginOnSGL2: () => { },
  LogoutSGLSession: () => { },
  getUser: () => { }
}
const SglContext = createContext(initSglContextPropsState);
export default SglContext