import React from "react";
import axios from "axios";
import SglContext from "../common/SglContext";
import { Link } from "react-router-dom";
import video_icon from "../images/video_icon.png";
import play from "../images/play19.png";
import "./liveclass.css";
import { useNavigate } from "react-router-dom";
import { env } from "../env";

export default class LiveClassDetail extends React.Component {
  state = {
    liveclass_data: {},
    timer: {},
    timer_updated_at: 0,
    current_time: 0,
    timer_timeout: null,
    loading: true,
    is_admin: false,
    userData: {},
    trip_videos: [],

    video_to_play: {},
    playing_video: false,
    is_open_play_video_modal: false,
    is_open_do_subscription_modal: false,
    status: "",
    show_se: true,
    flag_img_dir: this.importAll(
      require.context("../images/Flags/Large", false, /\.(png|jpe?g|svg)$/)
    ),
  };

  constructor() {
    super();
    this.ShowPickerElement = React.createRef();
    this.VideoPlayerElement = React.createRef();
    this.VideoSubscriptionElement = React.createRef();
    // bind function in constructor instead of render
    this.closeModal = this.closeModal.bind(this);
    this.closeSubscriptionModal = this.closeSubscriptionModal.bind(this);
    this.playVideoAfterSubscribe = this.playVideoAfterSubscribe.bind(this);
  }

  componentDidMount() {
    this.getAllData();
    window.addEventListener("message", (e) => {
      if (e.data.event && e.data.event == "getliveclass") {
        this.setState({ liveclass_data: e.data.data });
        var liveclass_data = e.data.data;
        if (e.data.data.class_type == "Jumpers") {
          //this.getTeamLeaderboardPositions(e.data.data);
        }

        // clear live timer and check for new timer data
        if (this.state.timer_timeout) {
          clearInterval(this.state.timer_timeout);
          this.setState({ timer_timeout: null });
        }

        // compute new timer for jumper
        if (
          liveclass_data.live_trip &&
          liveclass_data.live_trip.timer &&
          liveclass_data.live_trip.timer.value &&
          (parseInt(
            liveclass_data.live_trip.timer.value.match(/\d+/)
          ) ||
            parseInt(
              liveclass_data.live_trip.timer.value.match(/\d+/)
            ) == 0)
        ) {
          this.setState({ timer: liveclass_data.live_trip.timer });
          let current_time = Date.now();
          this.setState({ timer_updated_at: current_time });
          let timer_timeout = setTimeout(() => this.ComputeTimer(), 500);
          this.setState({ timer_timeout: timer_timeout });
        } else {
          this.setState({
            timer: {},
            timer_updated_at: 0,
            current_time: 0,
            timer_timeout: null,
          });
        }

        // loaded first time
        if (this.state.loading) {
          // document.title =
          //   "Live Class: " + liveclass_data.class_group_name;
          // this.context.reportToGA(
          //   "/sgl-wp-plugin/" +
          //     this.context.company_urlpath +
          //     "/liveclass/detail/cid/" +
          //     this.props.cid +
          //     "/class_group_id/" +
          //     this.props.class_group_id +
          //     "&sid=" +
          //     this.props.sid
          // );

          // get videos on first page load
          this.GetTripVideos();
          this.getClassStatus();
          // this.GetActiveRingClass();
        }
        this.state.loading = false;
      }
    });
  }

  importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }
  getClassStatus() {
    axios
      .get(
        env.REACT_APP_SGL_API +
          "/iphonev2/index.php/" +
          this.context.company_urlpath +
          "/liveclassv2/ClassStatus",
        {
          params: {
            from_wp_api: true,
            class_group_id: this.props.class_group_id,
            class_id: this.props.cid,
            show_id: this.props.sid,
            from_live_class: true,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.status == "Upcoming") {
            this.setState({ status: "Not Started" });
          } else if (res.data.status == "Underway") {
            this.setState({ status: "Live" });
          } else if (res.data.status == "Completed") {
            this.setState({ status: "Completed" });
          }
        }
        let old = this.getQueryVariable("old");
        // if (res.data.status == "Completed" && old != 1) {
        //   window.location.href =
        //     "//" +
        //     window.location.host +
        //     "/classes/detail?cid=" +
        //     this.props.cid;
        // }
        // var class_ring = this.state.liveclass_data.ring;

        // var obj = res.data;
        // var last_updated_class = this.state.liveclass_data.class_ids.find(item => item == obj.last_updated_class);
        // // var leaderboard_exists = leaderboard_data

        //     if(class_ring == obj.ring_number && obj.has_JSON){ //Class for ring is live
        //         //If current class is not live class of ring then redirect to current live class
        //         if(!last_updated_class || obj.class_group_id != this.getQueryVariable('class_group_id')){
        //             window.location.href = "//" +window.location.host + "/liveclass/detail?cid=" + obj.last_updated_class + "&class_group_id=" + obj.class_group_id;
        //           }
        //     }
      });
  }
  // GetActiveRingClass() {
  //   axios
  //     .get(
  //       env.REACT_APP_SGL_API +
  //         "/iphonev2/index.php/" +
  //         this.context.company_urlpath +
  //         "/liveclassv2/ClassStatus",
  //       {
  //         params: {
  //           from_wp_api: true,
  //           class_group_id: this.props.class_group_id,
  //           class_id: this.props.cid,
  //           show_id: this.props.sid,
  //           from_live_class: 0,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       var class_ring = this.state.liveclass_data.ring;

  //       var obj = res.data.live_classGroup;
  //       var last_updated_class = this.state.liveclass_data.class_ids.find(
  //         (item) => item == obj.last_updated_class
  //       );
  //       // var leaderboard_exists = leaderboard_data

  //       if (class_ring == obj.ring_number && obj.has_JSON) {
  //         //Class for ring is live
  //         //If current class is not live class of ring then redirect to current live class
  //         if (
  //           !last_updated_class ||
  //           obj.class_group_id != this.props.class_group_id
  //         ) {
  //           this.navigate(
  //             "/liveclass/detail?cid=" +
  //               obj.last_updated_class +
  //               "&class_group_id=" +
  //               obj.class_group_id +
  //               "&sid=" +
  //               this.props.sid
  //           );
  //         }
  //       }
  //       setTimeout(() => {
  //         this.GetActiveRingClass();
  //       }, 1000 * 60 * 3);
  //     });
  // }
  getTeamLeaderboardData(leaderboards) {
    var leaderboard_data = [];
    for (var leaderboard of leaderboards) {
      var trip = {
        ENo: leaderboard.ENo,
        id: leaderboard.id,
        Hor: leaderboard.Hor,
        Rid: leaderboard.Rid,
        RCntry: leaderboard.RCntry,
        R1F: leaderboard.R1F,
        R1T: leaderboard.R1T,
        JOF: leaderboard.JOF,
        JOT: leaderboard.JOT,
        Actual_OOG: leaderboard.Actual_OOG,
        JOQualified: leaderboard?.JOQualified,
        UpcomingJO: leaderboard?.UpcomingJO,
        JOGone: leaderboard?.JOGone,
      };

      var leaderboard_exists = leaderboard_data.find(
        (item) => item.TID == leaderboard.TID
      );

      if (!leaderboard_exists || !leaderboard.TID || leaderboard.TID == '0'){ //Trip is an Independent trip OR first trip for team found
        leaderboard["trips"] = [trip];
        leaderboard_data.push(leaderboard);
      }
      else {
        leaderboard_exists["trips"].push(trip);
      }
    }
    return leaderboard_data;
  }

  GetTripVideos() {
    // loop on classes
    let videos = [];
    let promises = [];
    for (let class_data in this.state.liveclass_data.class_trips) {
      class_data = this.state.liveclass_data.class_trips[class_data];

      // prepare trip ids
      let trip_ids = [];
      for (let completed_trip in class_data.leaderboard) {
        completed_trip = class_data.leaderboard[completed_trip];

        trip_ids.push(completed_trip.id);
      }

      // get trip data
      promises.push(
        axios
          .get(
            env.REACT_APP_SGL_API +
              "/iphonev2/index.php/" +
              this.context.company_urlpath +
              "/videos/getTripVideos",
            {
              params: {
                from_wp_api: true,
                ShowManagementID: this.context.company_id,
                class_id: class_data.class_id,
                r1_trips: trip_ids.join(","),
              },
            }
          )
          .then((res) => {
            if (res.data.result) {
              videos.push({
                class_id: class_data.class_id,
                videos: res.data.videos,
              });
            } else {
              videos.push({
                class_id: class_data.class_id,
                videos: [],
              });
            }
          })
      );
    }

    Promise.all(promises).then(() => {
      this.setState({ trip_videos: videos });
    });
    setTimeout(() => {
      this.GetTripVideos();
    }, 1000 * 60);
  }

  playVideo = (video_id) => (e) => {
    this.playVideoAPICall(video_id);
  };
  playVideoAPICall(video_id) {
    localStorage.setItem("playing_video_id", video_id);
    localStorage.setItem("playing_video_url", window.location.pathname);
    axios
      .get(env.REACT_APP_API_URL + "/videos/play/" + video_id, {
        params: {
          // show_id: this.props.sid,
          customer_id: this.context.company_id,
        },
      })
      .then((res) => {
        this.closeSubscriptionModal();
        localStorage.removeItem("playing_video_id");
        localStorage.removeItem("playing_video_url");
        if (res.data.subscribed_to_play) {
          this.setState({
            video_to_play: res.data.video,
            is_admin: res.data.is_admin,
            is_open_play_video_modal: true,
            playing_video: true,
          });
        } else {
          this.setState({
            video_to_play: res.data.video,
            is_admin: res.data.is_admin,
            is_open_do_subscription_modal: true,
            playing_video: true,
          });
        }
      });
  }

  closeModal() {
    this.setState({
      is_open_play_video_modal: false,
      playing_video: false,
    });
  }
  closeSubscriptionModal() {
    this.setState({
      is_open_do_subscription_modal: false,
      playing_video: false,
    });
  }
  playVideoAfterSubscribe() {
    this.playVideoAPICall(this.state.video_to_play.videos_sgl_id);
    this.setState({
      // is_open_do_subscription_modal: false,
      // is_open_play_video_modal: true,
      playing_video: true,
    });
  }

  getVideoTitle(video) {
    if (video["class_type"] == "Jumpers") {
      if (video["trip_type"].toLowerCase() != "jo") {
        return " - Round 1";
      } else {
        return " - Jump Off";
      }
    }
  }

  ComputeTimer() {
    if (this.state.timer) {
      let current_time = Date.now();
      let elasped_time = Math.trunc(
        (current_time - this.state.timer_updated_at) / 1000
      );

      if (this.state.timer.countdown) {
        elasped_time =
          parseInt(this.state.timer.value.match(/\d+/)) - elasped_time;
        if (elasped_time < 0) {
          elasped_time = 0;
          this.state.current_time = "";
        } else {
          this.state.current_time = "CD: " + elasped_time.toString();
        }
      } else {
        elasped_time = elasped_time + parseInt(this.state.timer.value);
        if (elasped_time > 200) {
          elasped_time = "0";
        }
        this.state.current_time = elasped_time.toString();
      }

      if (this.state.timer_timeout) {
        clearTimeout(this.state.timer_timeout);
        this.setState({ timer_timeout: null });
      }
      this.state.timer_timeout = setTimeout(() => this.ComputeTimer(), 500);
    }
    if (this.state.current_time == "0") {
      this.state.current_time = "";
    }
    this.setState({ current_time: this.state.current_time });
  }

  GetLiveClassData() {
    let liveframe = document.getElementById("liveclassframe");
    if (liveframe) {
      liveframe.contentWindow.postMessage({ event: "sendliveclass" }, "*");
      // if(this.state.timeout) {
      //   clearTimeout(this.state.timeout);
      // }
      // this.state.timeout = setTimeout(() => this.GetLiveClassData(), 5000);
    }
  }

  getTeamImagePath(TAbbr, TType, is_thumbnail) {
    var path = "";
    if (TType == "") {
      return path;
    }

    path = "https://showgroundslive.com/images/";

    //Check team type
    if (TType == "Country") {
      path += "Countries";
    } else {
      path += "Organizations";
    }

    //Check image type to return
    if (is_thumbnail) {
      path += "/Thumbnails";
    } else {
      path += "/Large";
    }

    path += "/" + TAbbr + ".png";

    return path;
  }
  getAllData() {
    this.getUserData();
  }

  getUserData() {
    axios.get(env.REACT_APP_API_URL + "/users/getUser").then(
      (response) => {
        if (response.data.user && response.data.user != null) {
          this.setState({
            userData: response.data.user,
          });
        } else {
        }
      },
      (error) => {}
    );
  }

  HideSE = (event) => {
    this.setState({ show_se: false });
  };

  ShowSE = (event) => {
    this.setState({ show_se: true });
  };

  renderLiveClassFrame() {
    return [
      <iframe
        style={{ display: "none" }}
        id='liveclassframe'
        name='liveclassframe'
        src={
          env.REACT_APP_SGL_API +
          "/iphonev2/index.php/" +
          this.context.company_urlpath +
          "/liveclassv2/Detail" +
          "?cid=" +
          this.props.cid +
          "&class_group_id=" +
          this.props.class_group_id
        }></iframe>,
      this.state.liveclass_data.class_trips && false && (
        <section className='search-area'>
          {/* <table>
            <tbody>
              <tr> */}
                <h2 className='sgl-h2'>
                  {this.state.liveclass_data.class_group_name}
                  &nbsp;(
                  {this.state.liveclass_data.class_trips.map(
                    (class_data, index) => {
                      return [
                        class_data.number,
                        index < this.state.liveclass_data.class_trips.length - 1
                          ? ", "
                          : "",
                      ];
                    }
                  )}
                  )
                </h2>
              {/* </tr>
            </tbody>
          </table> */}
        </section>
      ),
      <div className='credit-text'>
        {process.env.REACT_APP_CREDIT_TEXT}{" "}
        <Link to={process.env.REACT_APP_CREDIT_URL}>
          {process.env.REACT_APP_CREDIT_COMPANY}
        </Link>
      </div>,
      <br />,
    ];
  }

  renderLoading() {
    return (
      this.state.loading && (
        <div
          style={{
            border: "6px solid #f3f3f3",
            "border-radius": "50%",
            "border-top": "6px solid #999",
            width: "40px",
            height: "40px",
            "-webkit-animation": "spin 2s linear infinite",
            animation: "spin 2s linear infinite",
            margin: "0 auto",
          }}></div>
      )
    );
  }

  renderJumperLiveNowDesktop() {
    return (
      <table
        id='liveclass_livenow'
        className='liveclass_livenow table table-striped table-hover table-responsive contains-border'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='8'>LIVE NOW</th>
          </tr>
        </thead>
        <tbody>
          <tr className='sgl-secondary-header'>
            <th className='center-align'>&nbsp;</th>
            <th className='center-align'>Entry#</th>
            <th className='center-align'>Horse</th>
            <th className='center-align'>Rider</th>
            <th className='center-align'>Faults</th>
            <th className='center-align'>Time</th>
            <th className='center-align'>Faults</th>
            <th className='center-align'>Time</th>
          </tr>
          {this.state.liveclass_data.live_trip.id && (
            <tr className='live_row'>
              <td>NOW</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.ENo}
              </td>
              <td>{this.state.liveclass_data.live_trip.Hor}</td>
              <td>
                {this.state.liveclass_data.live_trip.RCntry &&
                  this.state.liveclass_data.live_trip.RCntry != "" && (
                    <img
                      className='rider_flag_inline_image with_wrapping_text'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.live_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
                {this.state.liveclass_data.live_trip.Rid}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1F}
              </td>
              <td
                className={
                  "center-align" +
                  (this.state.liveclass_data.live_trip.R1T == "" &&
                  this.state.timer &&
                  this.state.timer.countdown &&
                  this.state.timer.round == "R1"
                    ? " cd_timer"
                    : "")
                }>
                {this.state.liveclass_data.live_trip.R1T == "" &&
                this.state.timer &&
                this.state.timer.round == "R1"
                  ? this.state.current_time
                  : this.state.liveclass_data.live_trip.R1T}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.JOF}
              </td>
              <td
                className={
                  "center-align" +
                  (this.state.liveclass_data.live_trip.JOT == "" &&
                  this.state.timer &&
                  this.state.timer.countdown &&
                  this.state.timer.round == "JO"
                    ? " cd_timer"
                    : "")
                }>
                {this.state.liveclass_data.live_trip.JOT == "" &&
                this.state.timer &&
                this.state.timer.round == "JO"
                  ? this.state.current_time
                  : this.state.liveclass_data.live_trip.JOT}
              </td>
            </tr>
          )}
          {this.state.liveclass_data.next_trip.id ? (
            <tr className='next_row'>
              <td>NEXT</td>
              <td className='center-align'>
                {this.state.liveclass_data.next_trip.ENo}
              </td>
              <td>
                {this.state.liveclass_data.next_trip.id
                  ? this.state.liveclass_data.next_trip.Hor
                  : "NOT AVAILABLE"}
              </td>
              <td>
                {this.state.liveclass_data.next_trip.RCntry &&
                  this.state.liveclass_data.next_trip.RCntry != "" && (
                    <img
                      className='rider_flag_inline_image with_wrapping_text'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.next_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
                {this.state.liveclass_data.next_trip.Rid}
              </td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.R1F}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.R1T}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.JOF}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.JOT}</td>
            </tr>
          ) : (
            <tr className='next_row'>
              <td>NEXT</td>
              <td colSpan='7'>NOT AVAILABLE</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  renderJumperLiveNowMobile() {
    return [
      this.state.liveclass_data.live_trip.id && (
        <table
          id='liveclass_livenow'
          className='liveclass_livenow mobile table table-hover table-responsive'>
          <thead>
            <tr className='sgl-primary-header'>
              <th className='center-align' style={{ "vertical-align": "top" }}>
                <div>LIVE</div>
                {this.state.liveclass_data.live_trip.RCntry &&
                  this.state.liveclass_data.live_trip.RCntry != "" && (
                    <img
                      className='rider_flag_mobile'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.live_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
              </th>
              <th style={{ width: "80%" }}>
                {this.state.timer && (
                  <div
                    className={
                      "floatRight live_timer" +
                      (this.state.timer && this.state.timer.countdown
                        ? " cd_timer"
                        : "")
                    }>
                    {this.state.timer.round == "R1"
                      ? this.state.liveclass_data.live_trip.R1T == ""
                        ? this.state.current_time
                        : this.state.liveclass_data.live_trip.R1T
                      : this.state.liveclass_data.live_trip.JOT == ""
                      ? this.state.current_time
                      : this.state.liveclass_data.live_trip.JOT}
                  </div>
                )}
                <div className='floatLeft'>
                  <span className='entry'>
                    {this.state.liveclass_data.live_trip.ENo} -{" "}
                    {this.state.liveclass_data.live_trip.Hor}
                  </span>
                  <br />
                  <span>{this.state.liveclass_data.live_trip.Rid}</span>
                </div>
                <div className='jumper_result'>
                  <div>
                    <label>R1F:&nbsp;</label>
                    {parseInt(this.state.liveclass_data.live_trip.R1F) || 0}
                  </div>
                  <div>
                    <label>R1T:&nbsp;</label>
                    {this.state.liveclass_data.live_trip.R1T}
                  </div>
                  {(this.state.liveclass_data.live_trip.JOF != "" ||
                    this.state.liveclass_data.live_trip.JOT != "") && (
                    <div>
                      <label>JOF:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.JOF}
                    </div>
                  )}
                  {(this.state.liveclass_data.live_trip.JOF != "" ||
                    this.state.liveclass_data.live_trip.JOT != "") && (
                    <div>
                      <label>JOT:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.JOT}
                    </div>
                  )}
                </div>
              </th>
            </tr>
          </thead>
        </table>
      ),

      this.state.liveclass_data.next_trip.id && (
        <table
          id='liveclass_livenow'
          className='liveclass_livenow mobile table table-striped table-hover table-responsive contains-border'>
          <thead>
            <tr className='sgl-primary-header'>
              <th>NEXT</th>
            </tr>
          </thead>
          <tbody>
            <tr className='mobile_row'>
              <td>
                {this.state.liveclass_data.next_trip.RCntry &&
                  this.state.liveclass_data.next_trip.RCntry != "" && (
                    <img
                      className='floatLeft rider_flag_mobile'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.next_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
                <div
                  className='floatLeft'
                  style={{ "margin-top": "-4px", "margin-left": "10px" }}>
                  <span className='entry'>
                    {this.state.liveclass_data.next_trip.ENo} -{" "}
                    {this.state.liveclass_data.next_trip.Hor}
                  </span>
                  <br />
                  <span>{this.state.liveclass_data.next_trip.Rid}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ),
    ];
  }

  renderJumperLeaderBoardDesktop() {
    return this.state.liveclass_data.class_trips.map((class_data, index) => {
      return (
        <table className='liveclass_leaderboard table table-striped table-hover table-responsive contains-border'>
          <thead>
            <tr className='sgl-primary-header'>
              <th colSpan='8'>
                CLASS {class_data.number} - {class_data.name}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className='sgl-secondary-header'>
              <th rowSpan='2' className='center-align'>
                Pos
              </th>
              <th rowSpan='2' className='center-align'>
                Entry#
              </th>
              <th rowSpan='2' className='center-align'>
                Horse
              </th>
              <th rowSpan='2' className='center-align'>
                Rider
              </th>
              <th colSpan='2' className='center-align'>
                Round 1
              </th>
              <th colSpan='2' className='center-align'>
                Jump Off
              </th>
            </tr>
            <tr className='sgl-secondary-header'>
              <th className='center-align'>Faults</th>
              <th className='center-align'>Time</th>
              <th className='center-align'>Faults</th>
              <th className='center-align'>Time</th>
            </tr>

            {class_data.leaderboard.map((completed_trip, trip_index) => {
              let rowClass = '';
              if(completed_trip?.JOGone){
                rowClass = 'completed_jo_row';
              }
              else if(completed_trip?.JOQualified){
                rowClass = 'upcoming_jo_row';
              }

              return (
                <tr className={rowClass}>
                  <td
                    className={
                      "center-align place_" +
                      completed_trip.Pos +
                      " lessWidth placings_data"
                    }>
                    <span className='textChanges'>{completed_trip.Pos}</span>
                    {parseInt(completed_trip.delta) > 0 && (
                      <span className='delta_up'> ▲{completed_trip.delta}</span>
                    )}
                    {parseInt(completed_trip.delta) < 0 && (
                      <span className='delta_down'>
                        {" "}
                        ▼{Math.abs(completed_trip.delta)}
                      </span>
                    )}
                  </td>
                  <td className='center-align'>{completed_trip.ENo}</td>
                  <td>
                    {/* {this.state.trip_videos.map((class_videos) => {
                      return class_videos.class_id == class_data.class_id
                        ? class_videos.videos.map((video) => {
                            return video.entryxclasses_id ==
                              completed_trip.id ? (
                              <img
                                className='video_icon'
                                onClick={this.playVideo(video.sgl_id)}
                                src={video_icon}
                              />
                            ) : (
                              ""
                            );
                          })
                        : "";
                    })} */}
                    {completed_trip.Hor}
                  </td>
                  <td>
                    {completed_trip.RCntry && completed_trip.RCntry != "" && (
                      <img
                        className='rider_flag_inline_image with_wrapping_text'
                        // src={flag_img_dir + completed_trip.RCntry + ".png"}
                        src={
                          this.state.flag_img_dir[
                            completed_trip.RCntry + ".png"
                          ]
                        }
                      />
                    )}
                    {completed_trip.Rid}
                  </td>
                  <td className='center-align'>{completed_trip.R1F}</td>
                  <td className='center-align'>{completed_trip.R1T}</td>
                  <td className='center-align'>{completed_trip.JOF}</td>
                  <td className='center-align'>{completed_trip.JOT}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    });
  }

  renderJumperLeaderBoardMobile() {
    return this.state.liveclass_data.class_trips.map((class_data, index) => {
      return (
        <table className='liveclass_leaderboard mobile table table-striped table-hover table-responsive'>
          <thead>
            <tr className='sgl-primary-header'>
              <th colSpan='2'>
                CLASS {class_data.number} - {class_data.name}
              </th>
            </tr>
          </thead>
          <tbody>
            {class_data.leaderboard.map((completed_trip, trip_index) => {
              let rowClass = '';
              if(completed_trip?.JOGone){
                rowClass = 'completed_jo_row';
              }
              else if(completed_trip?.JOQualified){
                rowClass = 'upcoming_jo_row';
              }

              return (
                <tr
                  className={
                    "mobile_row " + rowClass
                  }>
                  <td
                    className={
                      "center-align place_" +
                      completed_trip.Pos +
                      " lessWidth placings_data"
                    }>
                    {completed_trip.JOQualified && (
                      <div className='joq_badge'>&nbsp;</div>
                    )}
                    <span className='textChanges'>{completed_trip.Pos}</span>
                    {parseInt(completed_trip.delta) > 0 && (
                      <span className='delta_up'> ▲{completed_trip.delta}</span>
                    )}
                    {parseInt(completed_trip.delta) < 0 && (
                      <span className='delta_down'>
                        {" "}
                        ▼{Math.abs(completed_trip.delta)}
                      </span>
                    )}
                  </td>
                  <td>
                    {completed_trip.RCntry && completed_trip.RCntry != "" && (
                      <img
                        className='floatRight rider_flag_mobile'
                        src={
                          this.state.flag_img_dir[
                            completed_trip.RCntry + ".png"
                          ]
                        }
                      />
                    )}
                    <div className='floatLeft'>
                      <div className='floatRight'>
                        {/* {this.state.trip_videos.map((class_videos) => {
                          return class_videos.class_id == class_data.class_id
                            ? class_videos.videos.map((video) => {
                                return video.entryxclasses_id ==
                                  completed_trip.id ? (
                                  <img
                                    className='video_icon'
                                    onClick={this.playVideo(video.sgl_id)}
                                    src={play}
                                  />
                                ) : (
                                  ""
                                );
                              })
                            : "";
                        })} */}
                      </div>
                      <span className='entry'>
                        {completed_trip.ENo} - {completed_trip.Hor}
                      </span>
                      <br />
                      <span>{completed_trip.Rid}</span>
                    </div>
                    <div className='jumper_result'>
                      <div>
                        <label>R1F:&nbsp;</label>
                        {parseInt(completed_trip.R1F) || 0}
                      </div>
                      <div>
                        <label>R1T:&nbsp;</label>
                        {completed_trip.R1T}
                      </div>
                      {(completed_trip.JOF != "" ||
                        completed_trip.JOT != "") && (
                        <div>
                          <label>JOF:&nbsp;</label>
                          {completed_trip.JOF}
                        </div>
                      )}
                      {(completed_trip.JOF != "" ||
                        completed_trip.JOT != "") && (
                        <div>
                          <label>JOT:&nbsp;</label>
                          {completed_trip.JOT}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    });
  }

  renderJumperLinedUpDesktop() {
    var lined_up_oog = 0;
    var lined_up_eno = [];
    var scratched_trips = [];

    return (
      <table className='liveclass_linedup table table-striped table-hover table-responsive contains-border'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='4'>LINED UP (In-gate)</th>
          </tr>
        </thead>
        <tbody>
          <tr className='sgl-secondary-header'>
            <th className='center-align'>In</th>
            <th className='center-align'>Entry#</th>
            <th className='center-align'>Horse</th>
            <th className='center-align'>Rider</th>
          </tr>
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_trip.Scr == "1") {
                if (
                  scratched_trips.find((trip) => {
                    return trip.ENo == lined_up_trip.ENo;
                  }, lined_up_trip)
                ) {
                  return "";
                }
                scratched_trips.push(lined_up_trip);
                lined_up_eno.push(lined_up_trip.ENo);
              }
            });
          })}
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            return class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_eno.indexOf(lined_up_trip.ENo) != -1) {
                return "";
              }

              lined_up_eno.push(lined_up_trip.ENo);
              lined_up_oog += 1;
              return (
                <tr className={lined_up_trip.Scr == "1" ? "strike_out" : ""}>
                  <td className={"center-align lessWidth placings_data"}>
                    <span className='textChanges'>{lined_up_oog}</span>
                  </td>
                  <td className='center-align'>{lined_up_trip.ENo}</td>
                  <td>{lined_up_trip.Hor}</td>
                  <td>
                    {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                      <img
                        className='rider_flag_inline_image with_wrapping_text'
                        // src={flag_img_dir + lined_up_trip.RCntry + ".png"}
                        src={
                          this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                        }
                      />
                    )}
                    {lined_up_trip.Rid}
                  </td>
                </tr>
              );
            });
          })}

          {scratched_trips.map((lined_up_trip, trip_index) => {
            lined_up_oog += 1;
            return (
              <tr className={lined_up_trip.Scr == "1" ? "strike_out" : ""}>
                <td className={"center-align lessWidth placings_data"}>
                  <span className='textChanges'>{lined_up_oog}</span>
                </td>
                <td className='center-align'>{lined_up_trip.ENo}</td>
                <td>{lined_up_trip.Hor}</td>
                <td>
                  {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                    <img
                      className='rider_flag_inline_image with_wrapping_text'
                      src={
                        this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                      }
                    />
                  )}
                  {lined_up_trip.Rid}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderJumperLinedUpMobile() {
    var lined_up_oog_mobile = 0;
    var lined_up_eno_mobile = [];
    var scratched_trips = [];

    return (
      <table className='liveclass_linedup mobile table table-striped table-hover table-responsive'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='2'>LINED UP (In-gate)</th>
          </tr>
        </thead>
        <tbody>
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_trip.Scr == "1") {
                if (
                  scratched_trips.find((trip) => {
                    return trip.ENo == lined_up_trip.ENo;
                  }, lined_up_trip)
                ) {
                  return "";
                }
                scratched_trips.push(lined_up_trip);
                lined_up_eno_mobile.push(lined_up_trip.ENo);
              }
            });
          })}
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            return class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_eno_mobile.indexOf(lined_up_trip.ENo) != -1) {
                return "";
              }

              lined_up_eno_mobile.push(lined_up_trip.ENo);
              lined_up_oog_mobile += 1;
              return (
                <tr
                  className={
                    "mobile_row " +
                    (lined_up_trip.Scr == "1" ? "strike_out" : "")
                  }>
                  <td className={"center-align lessWidth placings_data"}>
                    <span className='textChanges'>
                      {this.state.liveclass_data.class_trips.length == 1 &&
                        lined_up_oog_mobile}
                    </span>
                  </td>
                  <td>
                    <div className='floatLeft'>
                      <span className='entry'>
                        {lined_up_trip.ENo} - {lined_up_trip.Hor}
                      </span>
                      <br />
                      <span>{lined_up_trip.Rid}</span>
                    </div>
                    {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                      <img
                        className='floatRight rider_flag_mobile'
                        src={
                          this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                        }
                      />
                    )}
                  </td>
                </tr>
              );
            });
          })}
          {scratched_trips.map((lined_up_trip, trip_index) => {
            lined_up_oog_mobile += 1;
            return (
              <tr
                className={
                  "mobile_row " + (lined_up_trip.Scr == "1" ? "strike_out" : "")
                }>
                <td className={"center-align lessWidth placings_data"}>
                  <span className='textChanges'>
                    {this.state.liveclass_data.class_trips.length == 1 &&
                      lined_up_oog_mobile}
                  </span>
                </td>
                <td>
                  <div className='floatLeft'>
                    <span className='entry'>
                      {lined_up_trip.ENo} - {lined_up_trip.Hor}
                    </span>
                    <br />
                    <span>{lined_up_trip.Rid}</span>
                  </div>
                  {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                    <img
                      className='floatRight rider_flag_mobile'
                      src={
                        this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                      }
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderJumperClass() {
    return (
      <div className='div-responsive jumpers'>
        <div className='jumper-p'>
          <span className='sponsor_text'>Ring:</span>{" "}
          {this.state.liveclass_data.ring_name}
          <br />
          <span className='sponsor_text'>Status:</span> {this.state.status}
          <br />
          <span className='sponsor_text'>Jumper Table:</span>{" "}
          {this.state.liveclass_data.jumper_table}
          <br />
          <span className='sponsor_text'>Time Allowed:</span>{" "}
          {this.state.liveclass_data.timeallowed_tripone}
          <br />
          <span className='sponsor_text'>JO Time Allowed:</span>{" "}
          {parseInt(this.state.liveclass_data.timeallowed_jo)
            ? this.state.liveclass_data.timeallowed_jo
            : "N/A"}
        </div>

        {this.state.liveclass_data.special_event_url && false && (
          <div id='SEventContainer' className='SEventContainer'>
            {this.state.show_se && (
              <div className='close-frame'>
                <button
                  onClick={(e) => {
                    this.setState({ show_se: false });
                  }}
                  className='button sgl-button'>
                  Hide Live Stream
                </button>
              </div>
            )}
            {!this.state.show_se && (
              <div className='show-frame'>
                <button
                  onClick={(e) => {
                    this.setState({ show_se: true });
                  }}
                  className=' button sgl-button'>
                  Show Live Stream
                </button>
              </div>
            )}
            {this.state.show_se && (
              <iframe
                id='live_stream_iframe'
                allowfullscreen='allowfullscreen'
                webkitallowfullscreen='true'
                mozallowfullscreen='true'
                src={
                  this.state.show_se
                    ? this.state.liveclass_data.special_event_url
                    : "about:blank"
                }></iframe>
            )}
          </div>
        )}
        {
          // LIVE NOW DESKTOP
          this.renderJumperLiveNowDesktop()
        }
        {
          // LIVE NOW MOBILE
          this.renderJumperLiveNowMobile()
        }
        {
          // LEADER BOARD DESKTOP
          this.renderJumperLeaderBoardDesktop()
        }
        {
          // LEADER BOARD MOBILE
          this.renderJumperLeaderBoardMobile()
        }
        {
          // LINED UP DESKTOP
          this.renderJumperLinedUpDesktop()
        }
        {
          // LINED UP MOBILE
          this.renderJumperLinedUpMobile()
        }
      </div>
    );
  }

  renderHunterLiveNowDesktop() {
    return (
      <table
        id='liveclass_livenow'
        className='liveclass_livenow table table-striped table-hover table-responsive contains-border'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='11'>LIVE NOW</th>
          </tr>
        </thead>
        <tbody>
          <tr className='sgl-secondary-header'>
            <th className='center-align'>&nbsp;</th>
            <th className='center-align'>Entry#</th>
            <th className='center-align'>Horse</th>
            <th className='center-align'>Rider</th>
            <th className='center-align'>S1</th>
            <th className='center-align'>S2</th>
            <th className='center-align'>S3</th>
            <th className='center-align'>S4</th>
            <th className='center-align'>S5</th>
            <th className='center-align'>S6</th>
            <th className='center-align'>Score</th>
          </tr>
          {this.state.liveclass_data.live_trip.id && (
            <tr className='live_row'>
              <td>NOW</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.ENo}
              </td>
              <td>{this.state.liveclass_data.live_trip.Hor}</td>
              <td>
                {this.state.liveclass_data.live_trip.RCntry &&
                  this.state.liveclass_data.live_trip.RCntry != "" && (
                    <img
                      className='rider_flag_inline_image with_wrapping_text'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.live_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
                {this.state.liveclass_data.live_trip.Rid}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.J1}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.J2}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.J3}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.J4}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.J5}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.J6}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.Score}
              </td>
            </tr>
          )}
          {this.state.liveclass_data.next_trip.id ? (
            <tr className='next_row'>
              <td>NEXT</td>
              <td className='center-align'>
                {this.state.liveclass_data.next_trip.ENo}
              </td>
              <td>
                {this.state.liveclass_data.next_trip.id
                  ? this.state.liveclass_data.next_trip.Hor
                  : "NOT AVAILABLE"}
              </td>
              <td>
                {this.state.liveclass_data.next_trip.RCntry &&
                  this.state.liveclass_data.next_trip.RCntry != "" && (
                    <img
                      className='rider_flag_inline_image with_wrapping_text'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.next_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
                {this.state.liveclass_data.next_trip.Rid}
              </td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.J1}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.J2}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.J3}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.J4}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.J5}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.J6}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.Score}</td>
            </tr>
          ) : (
            <tr className='next_row'>
              <td>NEXT</td>
              <td colSpan='10'>NOT AVAILABLE</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  renderHunterLiveNowMobile() {
    return [
      this.state.liveclass_data.live_trip.id && (
        <table
          id='liveclass_livenow'
          className='liveclass_livenow mobile table table-hover table-responsive'>
          <thead>
            <tr className='sgl-primary-header'>
              <th className='center-align' style={{ "vertical-align": "top" }}>
                <div>LIVE</div>
                {this.state.liveclass_data.live_trip.RCntry &&
                  this.state.liveclass_data.live_trip.RCntry != "" && (
                    <img
                      className='rider_flag_mobile'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.live_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
              </th>
              <th style={{ width: "80%" }}>
                <div className=''>
                  <span className='entry'>
                    {this.state.liveclass_data.live_trip.ENo} -{" "}
                    {this.state.liveclass_data.live_trip.Hor}
                  </span>
                  <br />
                  <span>{this.state.liveclass_data.live_trip.Rid}</span>
                </div>
                <div className='hunter_result'>
                  {this.state.liveclass_data.live_trip.J1 && (
                    <div>
                      <label>S1:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.J1}
                    </div>
                  )}
                  {this.state.liveclass_data.live_trip.J2 && (
                    <div>
                      <label>S2:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.J2}
                    </div>
                  )}
                  {this.state.liveclass_data.live_trip.J3 && (
                    <div>
                      <label>S3:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.J3}
                    </div>
                  )}
                  {this.state.liveclass_data.live_trip.J4 && (
                    <div>
                      <label>S4:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.J4}
                    </div>
                  )}
                  {this.state.liveclass_data.live_trip.J5 && (
                    <div>
                      <label>S5:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.J5}
                    </div>
                  )}
                  {this.state.liveclass_data.live_trip.J6 && (
                    <div>
                      <label>S6:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.J6}
                    </div>
                  )}
                  {this.state.liveclass_data.live_trip.Score && (
                    <div>
                      <label>Score:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.Score}
                    </div>
                  )}
                </div>
              </th>
            </tr>
          </thead>
        </table>
      ),

      this.state.liveclass_data.next_trip.id && (
        <table
          id='liveclass_livenow'
          className='liveclass_livenow mobile table table-striped table-hover table-responsive contains-border'>
          <thead>
            <tr className='sgl-primary-header'>
              <th>NEXT</th>
            </tr>
          </thead>
          <tbody>
            <tr className='mobile_row'>
              <td>
                {this.state.liveclass_data.next_trip.RCntry &&
                  this.state.liveclass_data.next_trip.RCntry != "" && (
                    <img
                      className='floatLeft rider_flag_mobile'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.next_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
                <div
                  className='floatLeft'
                  style={{ "margin-top": "-4px", "margin-left": "10px" }}>
                  <span className='entry'>
                    {this.state.liveclass_data.next_trip.ENo} -{" "}
                    {this.state.liveclass_data.next_trip.Hor}
                  </span>
                  <br />
                  <span>{this.state.liveclass_data.next_trip.Rid}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ),
    ];
  }

  renderHunterLeaderBoardDesktop() {
    return this.state.liveclass_data.class_trips.map((class_data, index) => {
      return (
        <table className='liveclass_leaderboard table table-striped table-hover table-responsive contains-border'>
          <thead>
            <tr className='sgl-primary-header'>
              <th colSpan='11'>
                CLASS {class_data.number} - {class_data.name}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className='sgl-secondary-header'>
              <th className='center-align'>Pos</th>
              <th className='center-align'>Entry#</th>
              <th className='center-align'>Horse</th>
              <th className='center-align'>Rider</th>
              <th className='center-align'>S1</th>
              <th className='center-align'>S2</th>
              <th className='center-align'>S3</th>
              <th className='center-align'>S4</th>
              <th className='center-align'>S5</th>
              <th className='center-align'>S6</th>
              <th className='center-align'>Score</th>
            </tr>
            {class_data.leaderboard.map((completed_trip, trip_index) => {
              return (
                <tr className={completed_trip.JOGRow ? "jog_row" : ""}>
                  <td
                    className={
                      "center-align place_" +
                      completed_trip.Pos +
                      " lessWidth placings_data"
                    }>
                    <span className='textChanges'>{completed_trip.Pos}</span>
                    {parseInt(completed_trip.delta) > 0 && (
                      <span className='delta_up'> ▲{completed_trip.delta}</span>
                    )}
                    {parseInt(completed_trip.delta) < 0 && (
                      <span className='delta_down'>
                        {" "}
                        ▼{Math.abs(completed_trip.delta)}
                      </span>
                    )}
                  </td>
                  <td className='center-align'>{completed_trip.ENo}</td>
                  <td>
                    {/* {this.state.trip_videos.map((class_videos) => {
                      return class_videos.class_id == class_data.class_id
                        ? class_videos.videos.map((video) => {
                            return video.entryxclasses_id ==
                              completed_trip.id ? (
                              <img
                                className='video_icon'
                                onClick={this.playVideo(video.sgl_id)}
                                src={video_icon}
                              />
                            ) : (
                              ""
                            );
                          })
                        : "";
                    })} */}
                    {completed_trip.Hor}
                  </td>
                  <td>
                    {completed_trip.RCntry && completed_trip.RCntry != "" && (
                      <img
                        className='rider_flag_inline_image with_wrapping_text'
                        src={
                          this.state.flag_img_dir[
                            completed_trip.RCntry + ".png"
                          ]
                        }
                      />
                    )}
                    {completed_trip.Rid}
                  </td>
                  <td className='center-align'>{completed_trip.J1}</td>
                  <td className='center-align'>{completed_trip.J2}</td>
                  <td className='center-align'>{completed_trip.J3}</td>
                  <td className='center-align'>{completed_trip.J4}</td>
                  <td className='center-align'>{completed_trip.J5}</td>
                  <td className='center-align'>{completed_trip.J6}</td>
                  <td className='center-align'>{completed_trip.Score}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    });
  }

  renderHunterLeaderBoardMobile() {
    return this.state.liveclass_data.class_trips.map((class_data, index) => {
      return (
        <table className='liveclass_leaderboard mobile table table-striped table-hover table-responsive'>
          <thead>
            <tr className='sgl-primary-header'>
              <th colSpan='2'>
                CLASS {class_data.number} - {class_data.name}
              </th>
            </tr>
          </thead>
          <tbody>
            {class_data.leaderboard.map((completed_trip, trip_index) => {
              return (
                <tr
                  className={
                    "mobile_row" + (completed_trip.JOGRow ? " jog_row" : "")
                  }>
                  <td
                    className={
                      "center-align place_" +
                      completed_trip.Pos +
                      " lessWidth placings_data"
                    }>
                    {completed_trip.JOGRow && (
                      <div className='jog_badge'>&nbsp;</div>
                    )}
                    <span className='textChanges'>{completed_trip.Pos}</span>
                    {parseInt(completed_trip.delta) > 0 && (
                      <span className='delta_up'> ▲{completed_trip.delta}</span>
                    )}
                    {parseInt(completed_trip.delta) < 0 && (
                      <span className='delta_down'>
                        {" "}
                        ▼{Math.abs(completed_trip.delta)}
                      </span>
                    )}
                  </td>
                  <td>
                    {completed_trip.RCntry && completed_trip.RCntry != "" && (
                      <img
                        className='floatRight rider_flag_mobile'
                        src={
                          this.state.flag_img_dir[
                            completed_trip.RCntry + ".png"
                          ]
                        }
                      />
                    )}
                    <div className='floatLeft'>
                      <div className='floatRight'>
                        {/* {this.state.trip_videos.map((class_videos) => {
                          return class_videos.class_id == class_data.class_id
                            ? class_videos.videos.map((video) => {
                                return video.entryxclasses_id ==
                                  completed_trip.id ? (
                                  <img
                                    className='video_icon'
                                    onClick={this.playVideo(video.sgl_id)}
                                    src={play}
                                  />
                                ) : (
                                  ""
                                );
                              })
                            : "";
                        })} */}
                      </div>
                      <span className='entry'>
                        {completed_trip.ENo} - {completed_trip.Hor}
                      </span>
                      <br />
                      <span>{completed_trip.Rid}</span>
                    </div>
                    <div className='hunter_result'>
                      {completed_trip.J1 && (
                        <div>
                          <label>S1:&nbsp;</label>
                          {completed_trip.J1}
                        </div>
                      )}
                      {completed_trip.J2 && (
                        <div>
                          <label>S2:&nbsp;</label>
                          {completed_trip.J2}
                        </div>
                      )}
                      {completed_trip.J3 && (
                        <div>
                          <label>S3:&nbsp;</label>
                          {completed_trip.J3}
                        </div>
                      )}
                      {completed_trip.J4 && (
                        <div>
                          <label>S4:&nbsp;</label>
                          {completed_trip.J4}
                        </div>
                      )}
                      {completed_trip.J5 && (
                        <div>
                          <label>S5:&nbsp;</label>
                          {completed_trip.J5}
                        </div>
                      )}
                      {completed_trip.J6 && (
                        <div>
                          <label>S6:&nbsp;</label>
                          {completed_trip.J6}
                        </div>
                      )}
                      {completed_trip.Score && (
                        <div>
                          <label>Score:&nbsp;</label>
                          {completed_trip.Score}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    });
  }

  renderHunterLinedUpDesktop() {
    var lined_up_oog = 0;
    var lined_up_eno = [];
    var scratched_trips = [];

    return (
      <table className='liveclass_linedup table table-striped table-hover table-responsive contains-border'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='4'>LINED UP (In-gate)</th>
          </tr>
        </thead>
        <tbody>
          <tr className='sgl-secondary-header'>
            <th className='center-align'>In</th>
            <th className='center-align'>Entry#</th>
            <th className='center-align'>Horse</th>
            <th className='center-align'>Rider</th>
          </tr>
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_trip.Scr == "1") {
                if (
                  scratched_trips.find((trip) => {
                    return trip.ENo == lined_up_trip.ENo;
                  }, lined_up_trip)
                ) {
                  return "";
                }
                scratched_trips.push(lined_up_trip);
                lined_up_eno.push(lined_up_trip.ENo);
              }
            });
          })}

          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            return class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_eno.indexOf(lined_up_trip.ENo) != -1) {
                return "";
              }
              lined_up_eno.push(lined_up_trip.ENo);
              lined_up_oog += 1;
              return (
                <tr className={lined_up_trip.Scr == "1" ? "strike_out" : ""}>
                  <td className={"center-align lessWidth placings_data"}>
                    <span className='textChanges'>
                      {this.state.liveclass_data.class_trips.length == 1 &&
                        lined_up_oog}
                    </span>
                  </td>
                  <td className='center-align'>{lined_up_trip.ENo}</td>
                  <td>{lined_up_trip.Hor}</td>
                  <td>
                    {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                      <img
                        className='rider_flag_inline_image with_wrapping_text'
                        src={
                          this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                        }
                      />
                    )}
                    {lined_up_trip.Rid}
                  </td>
                </tr>
              );
            });
          })}
          {scratched_trips.map((lined_up_trip, trip_index) => {
            lined_up_oog += 1;
            return (
              <tr className={lined_up_trip.Scr == "1" ? "strike_out" : ""}>
                <td className={"center-align lessWidth placings_data"}>
                  <span className='textChanges'>
                    {this.state.liveclass_data.class_trips.length == 1 &&
                      lined_up_oog}
                  </span>
                </td>
                <td className='center-align'>{lined_up_trip.ENo}</td>
                <td>{lined_up_trip.Hor}</td>
                <td>
                  {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                    <img
                      className='rider_flag_inline_image with_wrapping_text'
                      src={
                        this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                      }
                    />
                  )}
                  {lined_up_trip.Rid}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderHunterLinedUpMobile() {
    var lined_up_oog_mobile = 0;
    var lined_up_eno_mobile = [];
    var scratched_trips = [];

    return (
      <table className='liveclass_linedup mobile table table-striped table-hover table-responsive'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='2'>LINED UP (In-gate)</th>
          </tr>
        </thead>
        <tbody>
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_trip.Scr == "1") {
                if (
                  scratched_trips.find((trip) => {
                    return trip.ENo == lined_up_trip.ENo;
                  }, lined_up_trip)
                ) {
                  return "";
                }
                scratched_trips.push(lined_up_trip);
                lined_up_eno_mobile.push(lined_up_trip.ENo);
              }
            });
          })}

          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            return class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_eno_mobile.indexOf(lined_up_trip.ENo) != -1) {
                return "";
              }
              lined_up_eno_mobile.push(lined_up_trip.ENo);
              lined_up_oog_mobile += 1;
              return (
                <tr
                  className={
                    "mobile_row " +
                    (lined_up_trip.Scr == "1" ? "strike_out" : "")
                  }>
                  <td className={"center-align lessWidth placings_data"}>
                    <span className='textChanges'>
                      {this.state.liveclass_data.class_trips.length == 1 &&
                        lined_up_oog_mobile}
                    </span>
                  </td>
                  <td>
                    <div className='floatLeft'>
                      <span className='entry'>
                        {lined_up_trip.ENo} - {lined_up_trip.Hor}
                      </span>
                      <br />
                      <span>{lined_up_trip.Rid}</span>
                    </div>
                    {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                      <img
                        className='floatRight rider_flag_mobile'
                        src={
                          this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                        }
                      />
                    )}
                  </td>
                </tr>
              );
            });
          })}
          {scratched_trips.map((lined_up_trip, trip_index) => {
            lined_up_oog_mobile += 1;
            return (
              <tr
                className={
                  "mobile_row " + (lined_up_trip.Scr == "1" ? "strike_out" : "")
                }>
                <td className={"center-align lessWidth placings_data"}>
                  <span className='textChanges'>
                    {this.state.liveclass_data.class_trips.length == 1 &&
                      lined_up_oog_mobile}
                  </span>
                </td>
                <td>
                  <div className='floatLeft'>
                    <span className='entry'>
                      {lined_up_trip.ENo} - {lined_up_trip.Hor}
                    </span>
                    <br />
                    <span>{lined_up_trip.Rid}</span>
                  </div>
                  {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                    <img
                      className='floatRight rider_flag_mobile'
                      src={
                        this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                      }
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderHunterClass() {
    return (
      <div className='div-responsive jumpers'>
        <div className='jumper-p'>
          <span className='sponsor_text'>Ring:</span>{" "}
          {this.state.liveclass_data.ring_name}
          <br />
          <span className='sponsor_text'>Status:</span> {this.state.status}
          <br />
          <span className='sponsor_text'>Hunter Score Type:</span>{" "}
          {this.state.liveclass_data.hunter_scoring_type}
          {this.state.liveclass_data.hunter_scoreby != "" && [
            <br />,
            <span className='sponsor_text'>Hunter Score By:</span>,
            this.state.liveclass_data.hunter_scoreby,
          ]}
        </div>

        {this.state.liveclass_data.special_event_url && false && (
          <div id='SEventContainer' className='SEventContainer'>
            {this.state.show_se && (
              <div className='close-frame'>
                <button
                  onClick={(e) => {
                    this.setState({ show_se: false });
                  }}
                  className='button sgl-button'>
                  Hide Live Stream
                </button>
              </div>
            )}
            {!this.state.show_se && (
              <div className='show-frame'>
                <button
                  onClick={(e) => {
                    this.setState({ show_se: true });
                  }}
                  className=' button sgl-button'>
                  Show Live Stream
                </button>
              </div>
            )}
            {this.state.show_se && (
              <iframe
                id='live_stream_iframe'
                allowfullscreen='allowfullscreen'
                webkitallowfullscreen='true'
                mozallowfullscreen='true'
                src={
                  this.state.show_se
                    ? this.state.liveclass_data.special_event_url
                    : "about:blank"
                }></iframe>
            )}
          </div>
        )}
        {
          // LIVE NOW DESKTOP
          this.state.liveclass_data.hunter_scoring_type != "No Score, No Jog" &&
            this.renderHunterLiveNowDesktop()
        }
        {
          // LIVE NOW MOBILE
          this.state.liveclass_data.hunter_scoring_type != "No Score, No Jog" &&
            this.renderHunterLiveNowMobile()
        }
        {this.state.liveclass_data.hunter_scoring_type ==
          "No Score, No Jog" && (
          <div>
            <span className='not_live_msg link_color'>
              This Class is not running Live
            </span>
          </div>
        )}
        {
          // LEADER BOARD DESKTOP
          this.renderHunterLeaderBoardDesktop()
        }
        {
          // LEADER BOARD MOBILE
          this.renderHunterLeaderBoardMobile()
        }
        {
          // LINED UP DESKTOP
          this.renderHunterLinedUpDesktop()
        }
        {
          // LINED UP MOBILE
          this.renderHunterLinedUpMobile()
        }
      </div>
    );
  }

  renderJumperTeamView() {
    return (
      <div className='div-responsive jumpers'>
        <div className='jumper-p'>
          <span className='sponsor_text'>Ring:</span>{" "}
          {this.state.liveclass_data.ring_name}
          <br />
          <span className='sponsor_text'>Status:</span> {this.state.status}
          <br />
          <span className='sponsor_text'>Jumper Table:</span>{" "}
          {this.state.liveclass_data.jumper_table}
          <br />
          <span className='sponsor_text'>Time Allowed:</span>{" "}
          {this.state.liveclass_data.timeallowed_tripone}
          <br />
          <span className='sponsor_text'>JO Time Allowed:</span>{" "}
          {parseInt(this.state.liveclass_data.timeallowed_jo)
            ? this.state.liveclass_data.timeallowed_jo
            : "N/A"}
        </div>

        {this.state.liveclass_data.special_event_url && false && (
          <div id='SEventContainer' className='SEventContainer'>
            {this.state.show_se && (
              <div className='close-frame'>
                <button
                  onClick={(e) => {
                    this.setState({ show_se: false });
                  }}
                  className='button sgl-button'>
                  Hide Live Stream
                </button>
              </div>
            )}
            {!this.state.show_se && (
              <div className='show-frame'>
                <button
                  onClick={(e) => {
                    this.setState({ show_se: true });
                  }}
                  className=' button sgl-button'>
                  Show Live Stream
                </button>
              </div>
            )}
            {this.state.show_se && (
              <iframe
                id='live_stream_iframe'
                allowfullscreen='allowfullscreen'
                webkitallowfullscreen='true'
                mozallowfullscreen='true'
                src={
                  this.state.show_se
                    ? this.state.liveclass_data.special_event_url
                    : "about:blank"
                }></iframe>
            )}
          </div>
        )}
        {
          // LIVE NOW DESKTOP
          this.renderJumperTeamLiveNowDesktop()
        }
        {
          // LIVE NOW MOBILE
          this.renderJumperTeamLiveNowMobile()
        }
        {
          // LEADER BOARD DESKTOP
          this.renderJumperTeamLeaderBoardDesktop()
        }
        {
          // LEADER BOARD MOBILE
          this.renderJumperTeamLeaderBoardMobile()
        }
        {
          // LINED UP DESKTOP
          this.renderJumperTeamLinedUpDesktop()
        }
        {
          // LINED UP MOBILE
          this.renderJumperTeamLinedUpMobile()
        }
      </div>
    );
  }

  renderJumperTeamLiveNowDesktop() {
    return (
      <table
        id='liveclass_livenow'
        className='liveclass_livenow table table-striped table-hover table-responsive contains-border'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='9'>LIVE NOW</th>
          </tr>
        </thead>
        <tbody>
          <tr className='sgl-secondary-header'>
            <th className='center-align'>&nbsp;</th>
            <th className='center-align'>Team</th>
            <th className='center-align'>Entry#</th>
            <th className='center-align'>Horse</th>
            <th className='center-align'>Rider</th>
            <th className='center-align'>Faults</th>
            <th className='center-align'>Time</th>
            <th className='center-align'>Faults</th>
            <th className='center-align'>Time</th>
          </tr>
          {this.state.liveclass_data.live_trip.id && (
            <tr className='live_row'>
              <td>NOW</td>
              <td>
                    <img
                  src={this.getTeamImagePath(
                    this.state.liveclass_data.live_trip.TAbbr,
                    this.state.liveclass_data.live_trip.TType,
                    false
                  )}
                  alt=''
                  onerror="this.style.display='none'"
                      className='rider_flag_inline_image with_wrapping_text'
                    />
                {this.state.liveclass_data.live_trip.TName}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.ENo}
              </td>
              <td>{this.state.liveclass_data.live_trip.Hor}</td>
              <td>{this.state.liveclass_data.live_trip.Rid}</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1F}
              </td>
              <td
                className={
                  "center-align" +
                  (this.state.liveclass_data.live_trip.R1T == "" &&
                  this.state.timer &&
                  this.state.timer.countdown &&
                  this.state.timer.round == "R1"
                    ? " cd_timer"
                    : "")
                }>
                {this.state.liveclass_data.live_trip.R1T == "" &&
                this.state.timer &&
                this.state.timer.round == "R1"
                  ? this.state.current_time
                  : this.state.liveclass_data.live_trip.R1T}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.JOF}
              </td>
              <td
                className={
                  "center-align" +
                  (this.state.liveclass_data.live_trip.JOT == "" &&
                  this.state.timer &&
                  this.state.timer.countdown &&
                  this.state.timer.round == "JO"
                    ? " cd_timer"
                    : "")
                }>
                {this.state.liveclass_data.live_trip.JOT == "" &&
                this.state.timer &&
                this.state.timer.round == "JO"
                  ? this.state.current_time
                  : this.state.liveclass_data.live_trip.JOT}
              </td>
            </tr>
          )}
          {this.state.liveclass_data.next_trip.id ? (
            <tr className='next_row'>
              <td>NEXT</td>
              <td>
                <img
                  src={this.getTeamImagePath(
                    this.state.liveclass_data.next_trip.TAbbr,
                    this.state.liveclass_data.next_trip.TType,
                    false
                  )}
                  alt=''
                  onerror="this.style.display='none'"
                  className='rider_flag_inline_image with_wrapping_text'
                />
                {this.state.liveclass_data.next_trip.TName}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.next_trip.ENo}
              </td>
              <td>
                {this.state.liveclass_data.next_trip.id
                  ? this.state.liveclass_data.next_trip.Hor
                  : "NOT AVAILABLE"}
              </td>
              <td>{this.state.liveclass_data.next_trip.Rid}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.R1F}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.R1T}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.JOF}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.JOT}</td>
            </tr>
          ) : (
            <tr className='next_row'>
              <td>NEXT</td>
              <td colSpan='8'>NOT AVAILABLE</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
  renderJumperTeamLiveNowMobile() {
    return [
      this.state.liveclass_data.live_trip.id && (
        <table
          id='liveclass_livenow'
          className='liveclass_livenow mobile table table-hover table-responsive'>
          <thead>
            <tr className='sgl-primary-header'>
              <th className='center-align' style={{ "vertical-align": "top" }}>
                <div>LIVE</div>
                    <img
                  src={this.getTeamImagePath(
                    this.state.liveclass_data.live_trip.TAbbr,
                    this.state.liveclass_data.live_trip.TType,
                    false
                  )}
                  alt=''
                  onerror="this.style.display='none'"
                  className='floatLeft team_logo_flag_mobile'
                    />
              </th>
              <th style={{ width: "80%" }}>
                {this.state.timer && (
                  <div
                    className={
                      "floatRight live_timer" +
                      (this.state.timer && this.state.timer.countdown
                        ? " cd_timer"
                        : "")
                    }>
                    {this.state.timer.round == "R1"
                      ? this.state.liveclass_data.live_trip.R1T == ""
                        ? this.state.current_time
                        : this.state.liveclass_data.live_trip.R1T
                      : this.state.liveclass_data.live_trip.JOT == ""
                      ? this.state.current_time
                      : this.state.liveclass_data.live_trip.JOT}
                  </div>
                )}
                <div className='floatLeft'>
                  <span className='entry'>
                    {this.state.liveclass_data.live_trip.ENo} -{" "}
                    {this.state.liveclass_data.live_trip.Hor}
                  </span>
                  <br />
                  <span>{this.state.liveclass_data.live_trip.Rid}</span>
                  <br />
                  <span>{this.state.liveclass_data.live_trip.TName}</span>
                </div>
                <div className='jumper_result'>
                  <div>
                    <label>R1F:&nbsp;</label>
                    {parseInt(this.state.liveclass_data.live_trip.R1F) || 0}
                  </div>
                  <div>
                    <label>R1T:&nbsp;</label>
                    {this.state.liveclass_data.live_trip.R1T}
                  </div>
                  {(this.state.liveclass_data.live_trip.JOF != "" ||
                    this.state.liveclass_data.live_trip.JOT != "") && (
                    <div>
                      <label>JOF:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.JOF}
                    </div>
                  )}
                  {(this.state.liveclass_data.live_trip.JOF != "" ||
                    this.state.liveclass_data.live_trip.JOT != "") && (
                    <div>
                      <label>JOT:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.JOT}
                    </div>
                  )}
                </div>
              </th>
            </tr>
          </thead>
        </table>
      ),

      this.state.liveclass_data.next_trip.id && (
        <table
          id='liveclass_livenow'
          className='liveclass_livenow mobile table table-striped table-hover table-responsive contains-border'>
          <thead>
            <tr className='sgl-primary-header'>
              <th>NEXT</th>
            </tr>
          </thead>
          <tbody>
            <tr className='mobile_row'>
              <td>
                    <img
                  src={this.getTeamImagePath(
                    this.state.liveclass_data.next_trip.TAbbr,
                    this.state.liveclass_data.next_trip.TType,
                    false
                  )}
                  alt=''
                  onerror="this.style.display='none'"
                  className='floatLeft team_logo_flag_mobile'
                    />
                <div
                  className='floatLeft'
                  style={{ "margin-top": "-4px", "margin-left": "10px" }}>
                  <span className='entry'>
                    {this.state.liveclass_data.next_trip.ENo} -{" "}
                    {this.state.liveclass_data.next_trip.Hor}
                  </span>
                  <br />
                  <span>{this.state.liveclass_data.next_trip.Rid}</span>
                  <br />
                  <span>{this.state.liveclass_data.next_trip.TName}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ),
    ];
  }

  renderJumperTeamLeaderBoardDesktop() {
    return this.state.liveclass_data.class_trips.map((class_data, index) => {
      let team_leaderboard_data = this.getTeamLeaderboardData(
        class_data.leaderboard
      );
      return (
        <table className='liveclass_leaderboard table table-striped table-hover table-responsive contains-border'>
          <thead>
            <tr className='sgl-primary-header'>
              <th colSpan='9'>
                CLASS {class_data.number} - {class_data.name}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className='sgl-secondary-header'>
              <th rowSpan='2' className='center-align'>
                Pos
              </th>
              <th rowSpan='2' className='center-align'>
                Team
              </th>
              <th rowSpan='2' className='center-align'>
                Entry#
              </th>
              <th rowSpan='2' className='center-align'>
                Horse
              </th>
              <th rowSpan='2' className='center-align'>
                Rider
              </th>
              <th colSpan='2' className='center-align'>
                Round 1
              </th>
              <th colSpan='2' className='center-align'>
                Jump Off
              </th>
            </tr>
            <tr className='sgl-secondary-header'>
              <th className='center-align'>Faults</th>
              <th className='center-align'>Time</th>
              <th className='center-align'>Faults</th>
              <th className='center-align'>Time</th>
            </tr>

            {team_leaderboard_data.map((completed_trip, trip_index) => {
              let rowClass = '';
              if(completed_trip?.UpcomingJO){
                rowClass = 'upcoming_jo_row';
              }
              else if(completed_trip?.JOQualified){
                rowClass = 'joq_row';
              }

              return [
                <tr
                  className={rowClass}>
                  <td
                    rowSpan={completed_trip.trips.length}
                    className={
                      "center-align verticle-align-center place_" +
                      completed_trip.Pos +
                      " lessWidth placings_data white_bg"
                    }>
                    <span className='textChanges'>
                      {completed_trip.Pos}
                    </span>
                    {parseInt(completed_trip.delta) > 0 && (
                      <span className='delta_up'> ▲{completed_trip.delta}</span>
                    )}
                    {parseInt(completed_trip.delta) < 0 && (
                      <span className='delta_down'>
                        {" "}
                        ▼{Math.abs(completed_trip.delta)}
                      </span>
                    )}
                  </td>
                  <td
                    rowSpan={completed_trip.trips.length}
                    className={"center-align verticle-align-center white_bg"}>
                    {(!completed_trip.TID || completed_trip.TID == '') ?
                    null
                    :
                    <>
                      <div>
                        {/* <img src='http://cdn.showgroundslive.com/Team_Scoring_Logos/Countries/Large/ISR.png' alt="" onerror="this.style.display='none'" className='team_logo_flag' /> */}
                    <img
                      src={this.getTeamImagePath(
                        completed_trip.TAbbr,
                        completed_trip.TType,
                        false
                      )}
                      alt=''
                      onerror="this.style.display='none'"
                      className='team_logo_flag'
                    />
                      </div>

                    <span className='team_name'>{completed_trip.TName}</span>
                    {/* {completed_trip.TName} */}
                    <br />
                    {parseFloat(completed_trip.TScr) < 200 && (
                      <span className='team_score'>
                        Score: {completed_trip.TScr}
                      </span>
                    )}
                    </>
                    }
                  </td>
                  <td className='center-align'>{completed_trip.ENo}</td>
                  <td>
                    {/* {this.state.trip_videos.map((class_videos) => {
                      return class_videos.class_id == class_data.class_id
                        ? class_videos.videos.map((video) => {
                            return video.entryxclasses_id ==
                              completed_trip.id ? (
                              <img
                                className='video_icon'
                                onClick={this.playVideo(video.sgl_id)}
                                src={video_icon}
                              />
                            ) : (
                              ""
                            );
                          })
                        : "";
                    })} */}
                    {completed_trip.Hor}
                  </td>
                  <td>
                    {/* {
                          (completed_trip.RCntry != '') && 
                          <img className='rider_flag_inline_image with_wrapping_text' src={flag_img_dir+completed_trip.RCntry+".png"}/>
                        } */}
                    {completed_trip.Rid}
                  </td>
                  <td className='center-align'>{completed_trip.R1F}</td>
                  <td className='center-align'>{completed_trip.R1T}</td>
                  <td className='center-align'>{completed_trip.JOF}</td>
                  <td className='center-align'>{completed_trip.JOT}</td>
                </tr>,
                <>
                  {
                    // for(i=1; i<completed_trip.trips.length; i++) {
                    completed_trip.trips.slice(1).map((trip, trip_index) => {
                      let rowClass = '';
                      if(trip?.UpcomingJO){
                        rowClass = 'upcoming_jo_row';
                      }
                      else if(trip?.JOQualified){
                        rowClass = 'joq_row';
                      }

                      return (
                        <tr
                          className={rowClass}>
                          <td className='center-align'>
                            <span className='new-line'>{trip.ENo}</span>
                          </td>
                          <td>
                            {/* {this.state.trip_videos.map((class_videos) => {
                              return class_videos.class_id ==
                                class_data.class_id
                                ? class_videos.videos.map((video) => {
                                    return video.entryxclasses_id == trip.id ? (
                                      <img
                                        className='video_icon'
                                        onClick={this.playVideo(video.sgl_id)}
                                        src={video_icon}
                                      />
                                    ) : (
                                      ""
                                    );
                                  })
                                : "";
                            })} */}
                            {trip.Hor}
                          </td>
                          <td>
                            <span className='new-line'>
                              {trip.Rid}
                            </span>
                          </td>
                          <td className='center-align'>
                            <span className='new-line'>{trip.R1F}</span>
                          </td>
                          <td className='center-align'>
                            <span className='new-line'>{trip.R1T}</span>
                          </td>
                          <td className='center-align'>
                            <span className='new-line'>{trip.JOF}</span>
                          </td>
                          <td className='center-align'>
                            <span className='new-line'>{trip.JOT}</span>
                          </td>
                        </tr>
                      );
                    })
                  }
                </>,
              ];
            })}
          </tbody>
        </table>
      );
    });
  }
  renderJumperTeamLeaderBoardMobile() {
    return this.state.liveclass_data.class_trips.map((class_data, index) => {
      let team_leaderboard_data = this.getTeamLeaderboardData(
        class_data.leaderboard
      );
      return (
        <table className='liveclass_leaderboard mobile table table-striped table-hover table-responsive'>
          <thead>
            <tr className='sgl-primary-header'>
              <th colSpan='2'>
                CLASS {class_data.number} - {class_data.name}
              </th>
            </tr>
          </thead>
          <tbody>
            {team_leaderboard_data.map((completed_trip, trip_index) => {
              let rowClass = '';
              if(completed_trip?.JOQualified){
                rowClass = 'joq_row';
              }

              return [
                <tr
                  className={"mobile_row " + rowClass}>
                  <td
                    className={
                      "center-align  verticle-align-center place_" +
                      completed_trip.Pos +
                      " lessWidth placings_data"
                    }>
                    {completed_trip?.JOQualified && (
                      <div className='joq_badge'>&nbsp;</div>
                    )}
                    <span className='textChanges'>
                      {completed_trip.Pos}
                    </span>
                    {parseInt(completed_trip.delta) > 0 && (
                      <span className='delta_up'> ▲{completed_trip.delta}</span>
                    )}
                    {parseInt(completed_trip.delta) < 0 && (
                      <span className='delta_down'>
                        {" "}
                        ▼{Math.abs(completed_trip.delta)}
                      </span>
                    )}

                    {(!completed_trip.TID || completed_trip.TID == '') ?
                    null
                    :
                    <>
                      <br />

                      <div>
                    {/* <img src='http://cdn.showgroundslive.com/Team_Scoring_Logos/Countries/Large/USA.png' alt="" onerror="this.style.display='none'"  className='team_logo_flag_mobile'/> */}
                    <img
                      src={this.getTeamImagePath(
                        completed_trip.TAbbr,
                        completed_trip.TType,
                        false
                      )}
                      alt=''
                      onerror="this.style.display='none'"
                      className='team_logo_flag_mobile'
                    />
                      </div>

                    <span className='team_name'>{completed_trip.TName}</span>
                    {/* {completed_trip.TName} */}
                    <br />
                    {parseFloat(completed_trip.TScr) < 200 && (
                      <span className='team_score'>
                        Score: {completed_trip.TScr}
                      </span>
                    )}
                    </>
                    }
                  </td>
                  <td>
                    <table className='table-striped fullWidthContainer'>
                      {completed_trip.trips.map((trip, trip_index) => {
                        return (
                          <tr>
                            <td>
                              {/* {
                              (trip.RCntry != '') && 
                              <img className='floatRight rider_flag_mobile' src={flag_img_dir+trip.RCntry+".png"}/>
                            } */}
                              <div className=''>
                                <div className='floatRight'>
                                  {/* {this.state.trip_videos.map(
                                    (class_videos) => {
                                      return class_videos.class_id ==
                                        class_data.class_id
                                        ? class_videos.videos.map((video) => {
                                            return video.entryxclasses_id ==
                                              trip.id ? (
                                              <img
                                                className='video_icon'
                                                onClick={this.playVideo(
                                                  video.sgl_id
                                                )}
                                                src={play}
                                              />
                                            ) : (
                                              ""
                                            );
                                          })
                                        : "";
                                    }
                                  )} */}
                                </div>
                                <span className='entry'>
                                  {trip.ENo} - {trip.Hor}
                                </span>
                                <br />
                                <span>{trip.Rid}</span>
                              </div>
                              <div className='jumper_result'>
                                <div>
                                  <label>R1F:&nbsp;</label>
                                  {parseInt(trip.R1F) || 0}
                                </div>
                                <div>
                                  <label>R1T:&nbsp;</label>
                                  {trip.R1T}
                                </div>
                                {(trip.JOF != "" || trip.JOT != "") && (
                                  <div>
                                    <label>JOF:&nbsp;</label>
                                    {trip.JOF}
                                  </div>
                                )}
                                {(trip.JOF != "" || trip.JOT != "") && (
                                  <div>
                                    <label>JOT:&nbsp;</label>
                                    {trip.JOT}
                                  </div>
                                )}
                              </div>
                              {trip_index < completed_trip.trips.length - 1 && (
                                <hr className='mobile-hr' />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </td>
                </tr>,
              ];
            })}
          </tbody>
        </table>
      );
    });
  }

  renderJumperTeamLinedUpDesktop() {
    var lined_up_oog = 0;
    var lined_up_eno = [];
    var scratched_trips = [];

    return (
      <table className='liveclass_linedup table table-striped table-hover table-responsive contains-border'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='5'>LINED UP (In-gate)</th>
          </tr>
        </thead>
        <tbody>
          <tr className='sgl-secondary-header'>
            <th className='center-align'>In</th>
            <th className='center-align'>Entry#</th>
            <th className='center-align'>Horse</th>
            <th className='center-align'>Rider</th>
            <th className='center-align'>Team</th>
          </tr>
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_trip.Scr == "1") {
                if (
                  scratched_trips.find((trip) => {
                    return trip.ENo == lined_up_trip.ENo;
                  }, lined_up_trip)
                ) {
                  return "";
                }
                scratched_trips.push(lined_up_trip);
                lined_up_eno.push(lined_up_trip.ENo);
              }
            });
          })}
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            return class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_eno.indexOf(lined_up_trip.ENo) != -1) {
                return "";
              }

              lined_up_eno.push(lined_up_trip.ENo);
              lined_up_oog += 1;
              return (
                <tr className={lined_up_trip.Scr == "1" ? "strike_out" : ""}>
                  <td className={"center-align lessWidth placings_data"}>
                    <span className='textChanges'>{lined_up_oog}</span>
                  </td>
                  <td className='center-align'>{lined_up_trip.ENo}</td>
                  <td>{lined_up_trip.Hor}</td>
                  <td>{lined_up_trip.Rid}</td>
                  <td>
                      <img
                      src={this.getTeamImagePath(
                        lined_up_trip.TAbbr,
                        lined_up_trip.TType,
                        false
                      )}
                      alt=''
                      onerror="this.style.display='none'"
                        className='rider_flag_inline_image with_wrapping_text'
                      />
                    {lined_up_trip.TName}
                  </td>
                </tr>
              );
            });
          })}

          {scratched_trips.map((lined_up_trip, trip_index) => {
            lined_up_oog += 1;
            return (
              <tr className={lined_up_trip.Scr == "1" ? "strike_out" : ""}>
                <td className={"center-align lessWidth placings_data"}>
                  <span className='textChanges'>{lined_up_oog}</span>
                </td>
                <td className='center-align'>{lined_up_trip.ENo}</td>
                <td>{lined_up_trip.Hor}</td>
                <td>{lined_up_trip.Rid}</td>
                <td>
                    <img
                    src={this.getTeamImagePath(
                      lined_up_trip.TAbbr,
                      lined_up_trip.TType,
                      false
                    )}
                    alt=''
                    onerror="this.style.display='none'"
                      className='rider_flag_inline_image with_wrapping_text'
                    />
                  {lined_up_trip.TName}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  renderJumperTeamLinedUpMobile() {
    var lined_up_oog_mobile = 0;
    var lined_up_eno_mobile = [];
    var scratched_trips = [];

    return (
      <table className='liveclass_linedup mobile table table-striped table-hover table-responsive'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='2'>LINED UP (In-gate)</th>
          </tr>
        </thead>
        <tbody>
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_trip.Scr == "1") {
                if (
                  scratched_trips.find((trip) => {
                    return trip.ENo == lined_up_trip.ENo;
                  }, lined_up_trip)
                ) {
                  return "";
                }
                scratched_trips.push(lined_up_trip);
                lined_up_eno_mobile.push(lined_up_trip.ENo);
              }
            });
          })}
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            return class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_eno_mobile.indexOf(lined_up_trip.ENo) != -1) {
                return "";
              }

              lined_up_eno_mobile.push(lined_up_trip.ENo);
              lined_up_oog_mobile += 1;
              return (
                <tr
                  className={
                    "mobile_row " +
                    (lined_up_trip.Scr == "1" ? "strike_out" : "")
                  }>
                  <td className={"center-align lessWidth placings_data"}>
                    <span className='textChanges'>
                      {this.state.liveclass_data.class_trips.length == 1 &&
                        lined_up_oog_mobile}
                    </span>
                  </td>
                  <td>
                    <div className='floatLeft'>
                      <span className='entry'>
                        {lined_up_trip.ENo} - {lined_up_trip.Hor}
                      </span>
                      <br />
                      <span>{lined_up_trip.Rid}</span>
                      <br />
                      <span>{lined_up_trip.TName}</span>
                    </div>
                      <img
                      src={this.getTeamImagePath(
                        lined_up_trip.TAbbr,
                        lined_up_trip.TType,
                        false
                      )}
                      alt=''
                      onerror="this.style.display='none'"
                      className='floatRight team_logo_flag_mobile'
                      />
                  </td>
                </tr>
              );
            });
          })}
          {scratched_trips.map((lined_up_trip, trip_index) => {
            lined_up_oog_mobile += 1;
            return (
              <tr
                className={
                  "mobile_row " + (lined_up_trip.Scr == "1" ? "strike_out" : "")
                }>
                <td className={"center-align lessWidth placings_data"}>
                  <span className='textChanges'>
                    {this.state.liveclass_data.class_trips.length == 1 &&
                      lined_up_oog_mobile}
                  </span>
                </td>
                <td>
                  <div className='floatLeft'>
                    <span
                      className={
                        "entry " +
                        (lined_up_trip.Scr == "1" ? "strike_out" : "")
                      }>
                      {lined_up_trip.ENo} - {lined_up_trip.Hor}
                    </span>
                    <br />
                    <span
                      className={lined_up_trip.Scr == "1" ? "strike_out" : ""}>
                      {lined_up_trip.Rid}
                    </span>
                    <br />
                    <span
                      className={lined_up_trip.Scr == "1" ? "strike_out" : ""}>
                      {lined_up_trip.TName}
                    </span>
                  </div>
                    <img
                    src={this.getTeamImagePath(
                      lined_up_trip.TAbbr,
                      lined_up_trip.TType,
                      false
                    )}
                    alt=''
                    onerror="this.style.display='none'"
                    className='floatRight team_logo_flag_mobile'
                    />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderHunterDerbyLiveNowDesktop() {
    return (
      <table
        id='liveclass_livenow'
        className='liveclass_livenow table table-hover table-responsive contains-border'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='11'>LIVE NOW</th>
          </tr>
        </thead>
        <tbody>
          <tr className='sgl-secondary-header'>
            <th className='center-align'>&nbsp;</th>
            <th className='center-align'>Entry#</th>
            <th className='center-align'>Horse</th>
            <th className='center-align'>Rider</th>
            <th className='center-align'>Round 1</th>
            <th className='center-align'>Round 2</th>
            <th className='center-align'>Score</th>
          </tr>
          {this.state.liveclass_data.live_trip.id && (
            <tr className='live_row'>
              <td>NOW</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.ENo}
              </td>
              <td>{this.state.liveclass_data.live_trip.Hor}</td>
              <td>
                {this.state.liveclass_data.live_trip.RCntry &&
                  this.state.liveclass_data.live_trip.RCntry != "" && (
                    <img
                      className='rider_flag_inline_image with_wrapping_text'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.live_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
                {this.state.liveclass_data.live_trip.Rid}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.J1}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.J2}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.Score}
              </td>
            </tr>
          )}

          {this.state.liveclass_data.live_trip.id && (
            <tr className=''>
              <td className='derby_next_row'></td>
              <td id='derby-live-cell' colSpan='6'>
                <table className='contains-border hunter-herby-live-score narrow-table table-striped'>
                  <tbody>
                    <tr className='sgl-secondary-header'>
                      <td></td>
                      <td colSpan='3' className='center-align'>
                        Round 1 Score: {this.state.liveclass_data.live_trip.J1}
                      </td>
                      <td colSpan='4' className='center-align'>
                        Round 2 Score: {this.state.liveclass_data.live_trip.J2}
                      </td>
                    </tr>
                    <tr className='sgl-secondary-header'>
                      <td></td>
                      <td className='center-align'>Option Bonus</td>
                      <td className='center-align'>Base Score</td>
                      <td className='center-align'>Score</td>
                      <td className='center-align'>Option Bonus</td>
                      <td className='center-align'>Handy Bonus</td>
                      <td className='center-align'>Base Score</td>
                      <td className='center-align'>Score</td>
                    </tr>
                    <tr>
                      <td>Panel 1</td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R1P1OB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R1P1BS}
                      </td>
                      <td className='center-align'>
                        <strong>{this.state.liveclass_data.live_trip.R1P1S}</strong>
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P1OB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P1HB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P1BS}
                      </td>
                      <td className='center-align'>
                        <strong>{this.state.liveclass_data.live_trip.R2P1S}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Panel 2</td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R1P2OB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R1P2BS}
                      </td>
                      <td className='center-align'>
                        <strong>{this.state.liveclass_data.live_trip.R1P2S}</strong>
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P2OB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P2HB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P2BS}
                      </td>
                      <td className='center-align'>
                        <strong>{this.state.liveclass_data.live_trip.R2P2S}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Panel 3</td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R1P3OB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R1P3BS}
                      </td>
                      <td className='center-align'>
                        <strong>{this.state.liveclass_data.live_trip.R1P3S}</strong>
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P3OB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P3HB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P3BS}
                      </td>
                      <td className='center-align'>
                        <strong>{this.state.liveclass_data.live_trip.R2P3S}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Panel 4</td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R1P4OB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R1P4BS}
                      </td>
                      <td className='center-align'>
                        <strong>{this.state.liveclass_data.live_trip.R1P4S}</strong>
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P4OB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P4HB}
                      </td>
                      <td className='center-align'>
                        {this.state.liveclass_data.live_trip.R2P4BS}
                      </td>
                      <td className='center-align'>
                        <strong>{this.state.liveclass_data.live_trip.R2P4S}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          )}
          {this.state.liveclass_data.next_trip.id ? (
            <tr className='next_row derby_next_row'>
              <td>NEXT</td>
              <td className='center-align'>
                {this.state.liveclass_data.next_trip.ENo}
              </td>
              <td>
                {this.state.liveclass_data.next_trip.id
                  ? this.state.liveclass_data.next_trip.Hor
                  : "NOT AVAILABLE"}
              </td>
              <td>
                {this.state.liveclass_data.next_trip.RCntry &&
                  this.state.liveclass_data.next_trip.RCntry != "" && (
                    <img
                      className='rider_flag_inline_image with_wrapping_text'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.next_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
                {this.state.liveclass_data.next_trip.Rid}
              </td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.J1}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.J2}</td>
              <td className='center-align'>{this.state.liveclass_data.next_trip.Score}</td>
            </tr>
          ) : (
            <tr className='next_row derby_next_row'>
              <td>NEXT</td>
              <td colSpan='10'>NOT AVAILABLE</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  renderHunterDerbyLiveNowMobile() {
    return [
      this.state.liveclass_data.live_trip.id && (
        <table
          id='liveclass_livenow'
          className='liveclass_livenow mobile table table-hover table-responsive'>
          <thead>
            <tr className='sgl-primary-header'>
              <th className='center-align' style={{ "vertical-align": "top" }}>
                <div>LIVE</div>
                {this.state.liveclass_data.live_trip.RCntry &&
                  this.state.liveclass_data.live_trip.RCntry != "" && (
                    <img
                      className='rider_flag_mobile'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.live_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
              </th>
              <th style={{ width: "80%" }}>
                <div className=''>
                  <span className='entry'>
                    {this.state.liveclass_data.live_trip.ENo} -{" "}
                    {this.state.liveclass_data.live_trip.Hor}
                  </span>
                  <br />
                  <span>{this.state.liveclass_data.live_trip.Rid}</span>
                </div>
                <div className='hunter_result'>
                  {this.state.liveclass_data.live_trip.J1 && (
                    <div>
                      <label>Round 1:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.J1}
                    </div>
                  )}
                  {this.state.liveclass_data.live_trip.J2 && (
                    <div>
                      <label>Round 2:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.J2}
                    </div>
                  )}
                  {this.state.liveclass_data.live_trip.Score && (
                    <div>
                      <label>Score:&nbsp;</label>
                      {this.state.liveclass_data.live_trip.Score}
                    </div>
                  )}
                </div>
              </th>
            </tr>
          </thead>
        </table>
      ),
      this.state.liveclass_data.live_trip.id && (
        <table className='contains-border liveclass_livenow mobile hunter-herby-live-score-mobile narrow-table table-striped'>
          <tbody>
            <tr className='sgl-secondary-header'>
              <td nowrap='nowrap'></td>
              <td colSpan='3' className='center-align'>
                Round 1 Score: {this.state.liveclass_data.live_trip.J1}
              </td>
            </tr>
            <tr className='sgl-secondary-header'>
              <td></td>
              <td className='center-align'>Option Bonus</td>
              <td className='center-align'>Base Score</td>
              <td className='center-align'>Score</td>
            </tr>
            <tr>
              <td>Panel 1</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P1OB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P1BS}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P1S}
              </td>
            </tr>
            <tr>
              <td>Panel 2</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P2OB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P2BS}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P2S}
              </td>
            </tr>
            <tr>
              <td>Panel 3</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P3OB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P3BS}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P3S}
              </td>
            </tr>
            <tr>
              <td>Panel 4</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P4OB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P4BS}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R1P4S}
              </td>
            </tr>
          </tbody>
        </table>
      ),
      this.state.liveclass_data.live_trip.id && (
        <table className='contains-border liveclass_livenow mobile hunter-herby-live-score-mobile narrow-table table-striped'>
          <tbody>
            <tr className='sgl-secondary-header'>
              <td nowrap='nowrap'></td>
              <td colSpan='4' className='center-align'>
                Round 2 Score: {this.state.liveclass_data.live_trip.J2}
              </td>
            </tr>
            <tr className='sgl-secondary-header'>
              <td></td>
              <td className='center-align'>Option Bonus</td>
              <td className='center-align'>Handy Bonus</td>
              <td className='center-align'>Base Score</td>
              <td className='center-align'>Score</td>
            </tr>
            <tr>
              <td>Panel 1</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P1OB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P1HB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P1BS}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P1S}
              </td>
            </tr>
            <tr>
              <td>Panel 2</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P2OB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P2HB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P2BS}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P2S}
              </td>
            </tr>
            <tr>
              <td>Panel 3</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P3OB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P3HB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P3BS}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P3S}
              </td>
            </tr>
            <tr>
              <td>Panel 4</td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P4OB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P4HB}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P4BS}
              </td>
              <td className='center-align'>
                {this.state.liveclass_data.live_trip.R2P4S}
              </td>
            </tr>
          </tbody>
        </table>
      ),

      this.state.liveclass_data.next_trip.id && (
        <table
          id='liveclass_livenow'
          className='liveclass_livenow mobile table table-striped table-hover table-responsive contains-border'>
          <thead>
            <tr className='sgl-primary-header'>
              <th>NEXT</th>
            </tr>
          </thead>
          <tbody>
            <tr className='mobile_row'>
              <td>
                {this.state.liveclass_data.next_trip.RCntry &&
                  this.state.liveclass_data.next_trip.RCntry != "" && (
                    <img
                      className='floatLeft rider_flag_mobile'
                      src={
                        this.state.flag_img_dir[
                          this.state.liveclass_data.next_trip.RCntry + ".png"
                        ]
                      }
                    />
                  )}
                <div
                  className='floatLeft'
                  style={{ "margin-top": "-4px", "margin-left": "10px" }}>
                  <span className='entry'>
                    {this.state.liveclass_data.next_trip.ENo} -{" "}
                    {this.state.liveclass_data.next_trip.Hor}
                  </span>
                  <br />
                  <span>{this.state.liveclass_data.next_trip.Rid}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ),
    ];
  }

  renderHunterDerbyLeaderBoardDesktop() {
    return this.state.liveclass_data.class_trips.map((class_data, index) => {
      return (
        <table className='liveclass_leaderboard table table-striped table-hover table-responsive contains-border'>
          <thead>
            <tr className='sgl-primary-header'>
              <th colSpan='11'>
                CLASS {class_data.number} - {class_data.name}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className='sgl-secondary-header'>
              <th className='center-align'>Pos</th>
              <th className='center-align'>Entry#</th>
              <th className='center-align'>Horse</th>
              <th className='center-align'>Rider</th>
              <th className='center-align'>Round 1</th>
              <th className='center-align'>Round 2</th>
              <th className='center-align'>Score</th>
            </tr>
            {class_data.leaderboard.map((completed_trip, trip_index) => {
              let rowClass = '';
              if(completed_trip?.UpcomingR2Row){
                rowClass = 'upcoming_jo_row';
              }
              else if(completed_trip?.CompletedR2Row){
                rowClass = 'completed_jo_row';
              }
              else if(completed_trip?.JOGRow){
                rowClass = 'jog_row';
              }

              return (
                <tr className={rowClass}>
                  <td
                    className={
                      "center-align place_" +
                      completed_trip.Pos +
                      " lessWidth placings_data"
                    }>
                    <span className='textChanges'>{completed_trip.Pos}</span>
                    {parseInt(completed_trip.delta) > 0 && (
                      <span className='delta_up'> ▲{completed_trip.delta}</span>
                    )}
                    {parseInt(completed_trip.delta) < 0 && (
                      <span className='delta_down'>
                        {" "}
                        ▼{Math.abs(completed_trip.delta)}
                      </span>
                    )}
                  </td>
                  <td className='center-align'>{completed_trip.ENo}</td>
                  <td>
                    {/* {this.state.trip_videos.map((class_videos) => {
                      return class_videos.class_id == class_data.class_id
                        ? class_videos.videos.map((video) => {
                            return video.entryxclasses_id ==
                              completed_trip.id ? (
                              <img
                                className='video_icon'
                                onClick={this.playVideo(video.sgl_id)}
                                src={video_icon}
                              />
                            ) : (
                              ""
                            );
                          })
                        : "";
                    })} */}
                    {completed_trip.Hor}
                  </td>
                  <td>
                    {completed_trip.RCntry && completed_trip.RCntry != "" && (
                      <img
                        className='rider_flag_inline_image with_wrapping_text'
                        src={
                          this.state.flag_img_dir[
                            completed_trip.RCntry + ".png"
                          ]
                        }
                      />
                    )}
                    {completed_trip.Rid}
                  </td>
                  <td className='center-align'>{completed_trip.J1}</td>
                  <td className='center-align'>{completed_trip.J2}</td>
                  <td className='center-align'>{completed_trip.Score}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    });
  }

  renderHunterDerbyLeaderBoardMobile() {
    return this.state.liveclass_data.class_trips.map((class_data, index) => {
      return (
        <table className='liveclass_leaderboard mobile table table-striped table-hover table-responsive'>
          <thead>
            <tr className='sgl-primary-header'>
              <th colSpan='2'>
                CLASS {class_data.number} - {class_data.name}
              </th>
            </tr>
          </thead>
          <tbody>
            {class_data.leaderboard.map((completed_trip, trip_index) => {
              let rowClass = '';
              if(completed_trip?.UpcomingR2Row){
                rowClass = 'upcoming_jo_row';
              }
              else if(completed_trip?.CompletedR2Row){
                rowClass = 'completed_jo_row';
              }
              else if(completed_trip?.JOGRow){
                rowClass = 'jog_row';
              }

              return (
                <tr
                  className={
                    "mobile_row " + (rowClass)
                  }>
                  <td
                    className={
                      "center-align place_" +
                      completed_trip.Pos +
                      " lessWidth placings_data"
                    }>
                    {completed_trip.JOGRow && (
                      <div className='jog_badge'>&nbsp;</div>
                    )}
                    <span className='textChanges'>{completed_trip.Pos}</span>
                    {parseInt(completed_trip.delta) > 0 && (
                      <span className='delta_up'> ▲{completed_trip.delta}</span>
                    )}
                    {parseInt(completed_trip.delta) < 0 && (
                      <span className='delta_down'>
                        {" "}
                        ▼{Math.abs(completed_trip.delta)}
                      </span>
                    )}
                  </td>
                  <td>
                    {completed_trip.RCntry && completed_trip.RCntry != "" && (
                      <img
                        className='floatRight rider_flag_mobile'
                        src={
                          this.state.flag_img_dir[
                            completed_trip.RCntry + ".png"
                          ]
                        }
                      />
                    )}
                    <div className='floatLeft'>
                      <div className='floatRight'>
                        {/* {this.state.trip_videos.map((class_videos) => {
                          return class_videos.class_id == class_data.class_id
                            ? class_videos.videos.map((video) => {
                                return video.entryxclasses_id ==
                                  completed_trip.id ? (
                                  <img
                                    className='video_icon'
                                    onClick={this.playVideo(video.sgl_id)}
                                    src={play}
                                  />
                                ) : (
                                  ""
                                );
                              })
                            : "";
                        })} */}
                      </div>
                      <span className='entry'>
                        {completed_trip.ENo} - {completed_trip.Hor}
                      </span>
                      <br />
                      <span>{completed_trip.Rid}</span>
                    </div>
                    <div className='hunter_result'>
                      {completed_trip.J1 && (
                        <div>
                          <label>Round 1:&nbsp;</label>
                          {completed_trip.J1}
                        </div>
                      )}
                      {completed_trip.J2 && (
                        <div>
                          <label>Round 2:&nbsp;</label>
                          {completed_trip.J2}
                        </div>
                      )}
                      {completed_trip.Score && (
                        <div>
                          <label>Score:&nbsp;</label>
                          {completed_trip.Score}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    });
  }

  renderHunterDerbyLinedUpDesktop() {
    var lined_up_oog = 0;
    var lined_up_eno = [];
    var scratched_trips = [];

    return (
      <table className='liveclass_linedup table table-striped table-hover table-responsive contains-border'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='4'>LINED UP (In-gate)</th>
          </tr>
        </thead>
        <tbody>
          <tr className='sgl-secondary-header'>
            <th className='center-align'>In</th>
            <th className='center-align'>Entry#</th>
            <th className='center-align'>Horse</th>
            <th className='center-align'>Rider</th>
          </tr>
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_trip.Scr == "1") {
                if (
                  scratched_trips.find((trip) => {
                    return trip.ENo == lined_up_trip.ENo;
                  }, lined_up_trip)
                ) {
                  return "";
                }
                scratched_trips.push(lined_up_trip);
                lined_up_eno.push(lined_up_trip.ENo);
              }
            });
          })}

          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            return class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_eno.indexOf(lined_up_trip.ENo) != -1) {
                return "";
              }
              lined_up_eno.push(lined_up_trip.ENo);
              lined_up_oog += 1;
              return (
                <tr className={lined_up_trip.Scr == "1" ? "strike_out" : ""}>
                  <td className={"center-align lessWidth placings_data"}>
                    <span className='textChanges'>
                      {this.state.liveclass_data.class_trips.length == 1 &&
                        lined_up_oog}
                    </span>
                  </td>
                  <td className='center-align'>{lined_up_trip.ENo}</td>
                  <td>{lined_up_trip.Hor}</td>
                  <td>
                    {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                      <img
                        className='rider_flag_inline_image with_wrapping_text'
                        src={
                          this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                        }
                      />
                    )}
                    {lined_up_trip.Rid}
                  </td>
                </tr>
              );
            });
          })}
          {scratched_trips.map((lined_up_trip, trip_index) => {
            lined_up_oog += 1;
            return (
              <tr className={lined_up_trip.Scr == "1" ? "strike_out" : ""}>
                <td className={"center-align lessWidth placings_data"}>
                  <span className='textChanges'>
                    {this.state.liveclass_data.class_trips.length == 1 &&
                      lined_up_oog}
                  </span>
                </td>
                <td className='center-align'>{lined_up_trip.ENo}</td>
                <td>{lined_up_trip.Hor}</td>
                <td>
                  {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                    <img
                      className='rider_flag_inline_image with_wrapping_text'
                      src={
                        this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                      }
                    />
                  )}
                  {lined_up_trip.Rid}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderHunterDerbyLinedUpMobile() {
    var lined_up_oog_mobile = 0;
    var lined_up_eno_mobile = [];
    var scratched_trips = [];

    return (
      <table className='liveclass_linedup mobile table table-striped table-hover table-responsive'>
        <thead>
          <tr className='sgl-primary-header'>
            <th colSpan='2'>LINED UP (In-gate)</th>
          </tr>
        </thead>
        <tbody>
          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_trip.Scr == "1") {
                if (
                  scratched_trips.find((trip) => {
                    return trip.ENo == lined_up_trip.ENo;
                  }, lined_up_trip)
                ) {
                  return "";
                }
                scratched_trips.push(lined_up_trip);
                lined_up_eno_mobile.push(lined_up_trip.ENo);
              }
            });
          })}

          {this.state.liveclass_data.class_trips.map((class_data, index) => {
            return class_data.lined_up.map((lined_up_trip, trip_index) => {
              if (lined_up_eno_mobile.indexOf(lined_up_trip.ENo) != -1) {
                return "";
              }
              lined_up_eno_mobile.push(lined_up_trip.ENo);
              lined_up_oog_mobile += 1;
              return (
                <tr
                  className={
                    "mobile_row " +
                    (lined_up_trip.Scr == "1" ? "strike_out" : "")
                  }>
                  <td className={"center-align lessWidth placings_data"}>
                    <span className='textChanges'>
                      {this.state.liveclass_data.class_trips.length == 1 &&
                        lined_up_oog_mobile}
                    </span>
                  </td>
                  <td>
                    <div className='floatLeft'>
                      <span className='entry'>
                        {lined_up_trip.ENo} - {lined_up_trip.Hor}
                      </span>
                      <br />
                      <span>{lined_up_trip.Rid}</span>
                    </div>
                    {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                      <img
                        className='floatRight rider_flag_mobile'
                        src={
                          this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                        }
                      />
                    )}
                  </td>
                </tr>
              );
            });
          })}
          {scratched_trips.map((lined_up_trip, trip_index) => {
            lined_up_oog_mobile += 1;
            return (
              <tr
                className={
                  "mobile_row " + (lined_up_trip.Scr == "1" ? "strike_out" : "")
                }>
                <td className={"center-align lessWidth placings_data"}>
                  <span className='textChanges'>
                    {this.state.liveclass_data.class_trips.length == 1 &&
                      lined_up_oog_mobile}
                  </span>
                </td>
                <td>
                  <div className='floatLeft'>
                    <span className='entry'>
                      {lined_up_trip.ENo} - {lined_up_trip.Hor}
                    </span>
                    <br />
                    <span>{lined_up_trip.Rid}</span>
                  </div>
                  {lined_up_trip.RCntry && lined_up_trip.RCntry != "" && (
                    <img
                      className='floatRight rider_flag_mobile'
                      src={
                        this.state.flag_img_dir[lined_up_trip.RCntry + ".png"]
                      }
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderHunterDerbyClass() {
    return (
      <div className='div-responsive jumpers'>
        <div className='jumper-p'>
          <span className='sponsor_text'>Ring:</span>{" "}
          {this.state.liveclass_data.ring_name}
          <br />
          <span className='sponsor_text'>Status:</span> {this.state.status}
          <br />
          <span className='sponsor_text'>Hunter Score Type:</span>{" "}
          {this.state.liveclass_data.hunter_scoring_type != ""
            ? this.state.liveclass_data.hunter_scoring_type
            : "N/A"}
          <br />
          <span className='sponsor_text'>Hunter Score By:</span>{" "}
          {this.state.liveclass_data.hunter_scoreby != ""
            ? this.state.liveclass_data.hunter_scoreby
            : "Total Score"}
        </div>

        {this.state.liveclass_data.special_event_url && false && (
          <div id='SEventContainer' className='SEventContainer'>
            {this.state.show_se && (
              <div className='close-frame'>
                <button
                  onClick={(e) => {
                    this.setState({ show_se: false });
                  }}
                  className='button sgl-button'>
                  Hide Live Stream
                </button>
              </div>
            )}
            {!this.state.show_se && (
              <div className='show-frame'>
                <button
                  onClick={(e) => {
                    this.setState({ show_se: true });
                  }}
                  className=' button sgl-button'>
                  Show Live Stream
                </button>
              </div>
            )}
            {this.state.show_se && (
              <iframe
                id='live_stream_iframe'
                allowfullscreen='allowfullscreen'
                webkitallowfullscreen='true'
                mozallowfullscreen='true'
                src={
                  this.state.show_se
                    ? this.state.liveclass_data.special_event_url
                    : "about:blank"
                }></iframe>
            )}
          </div>
        )}
        {
          // LIVE NOW DESKTOP
          this.state.liveclass_data.hunter_scoring_mode != 3 &&
            this.renderHunterDerbyLiveNowDesktop()
        }
        {
          // LIVE NOW MOBILE
          this.state.liveclass_data.hunter_scoring_mode != 3 &&
            this.renderHunterDerbyLiveNowMobile()
        }
        {this.state.liveclass_data.hunter_scoring_mode == 3 && (
          <div>
            <span className='not_live_msg link_color'>
              This Class is not running Live
            </span>
          </div>
        )}
        {
          // LEADER BOARD DESKTOP
          this.renderHunterDerbyLeaderBoardDesktop()
        }
        {
          // LEADER BOARD MOBILE
          this.renderHunterDerbyLeaderBoardMobile()
        }
        {
          // LINED UP DESKTOP
          this.renderHunterDerbyLinedUpDesktop()
        }
        {
          // LINED UP MOBILE
          this.renderHunterDerbyLinedUpMobile()
        }
      </div>
    );
  }

  render() {
    return (
      <>
      {  this.state.status == "Live" || true? 
      // IFRAME FOR LIVE CLASS DETAIL
      <div className="sgl-plugin-page" style={{paddingTop: '20px', paddingBottom: '20px', paddingLeft:"250px", paddingRight: "250px"}}>
      <div className='sgl-plugin liveclasscss'>
        {this.renderLiveClassFrame()}
        {this.renderLoading()}

        {
          // JUMPERS CLASS INFO
          this.state.liveclass_data.class_type == "Jumpers" && (
            <>
              {this.state.liveclass_data.jumper_table == "Team Scoring" ||
              this.state.liveclass_data.jumper_table == "FEI Nations Cup"
                ? this.renderJumperTeamView()
                : this.renderJumperClass()}
            </>
          )
        }

        {
          // HUNTERS CLASS INFO
          this.state.liveclass_data.class_type &&
            this.state.liveclass_data.class_type != "Jumpers" && (
              <>
                {this.state.liveclass_data.hunter_scoring_type.indexOf("Hunter Derby") >= 0
                  ? this.renderHunterDerbyClass()
                  : this.renderHunterClass()}
              </>
            )
        }
        {/* <div>
          {this.state.playing_video && (
            <VideoPlayer
              is_open_play_video_modal={this.state.is_open_play_video_modal}
              is_open_do_subscription_modal={
                this.state.is_open_do_subscription_modal
              }
              is_download_subscription={false}
              video_to_play={this.state.video_to_play}
              is_admin={this.state.is_admin}
              closeModal={this.closeModal}
              closeSubscriptionModal={this.closeSubscriptionModal}
              playing_preview={false}
              play_video_path={this.state.video_to_play.video_url}
              playVideoAfterSubscribe={this.playVideoAfterSubscribe}
              VideoSubscriptionElement={this.VideoSubscriptionElement}
              ref={this.VideoPlayerElement}
            />
          )}
        </div> */}
        <div className='copyright-text'>
          {" "}
          {process.env.REACT_APP_COPYRIGHT_TEXT}
        </div>
      </div>
      </div> : ""
      }
      </>
    );
  }
}
LiveClassDetail.contextType = SglContext;
LiveClassDetail.navigate = useNavigate;
