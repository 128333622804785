import React from "react";
import axios from "axios";
import { Component } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import SglContext from "../common/SglContext";
import "../styles/subscribeDialog.css";
import ManageCards from "../common/ManageCards";
import $ from "jquery";

import horse_logo from "../images/horse-logo.png";
import indicator_icon from "../images/Indicator.gif";
import close_icon from "../images/close.png";
import report_icon from "../images/report.png";
import { env } from "../env";
window.jQuery = $;

  var AddModal = ReactModal;
  const sgl_webservice_videos = env.REACT_APP_SGL_API + "/iphonev2/videosv2/";
  const sgl_webservice_videos_list = env.REACT_APP_API_URL + "/videos";
  const sgl_webservice_iphone = env.REACT_APP_SGL_API + "/iphonev2/addentryv2/";
  const sgl_webservice_iphone_sub = env.REACT_APP_SGL_API + "/iphone.php/webservice/";

  const TermsModalStyles = { content: { border: "1px solid #ccc", borderRadius: "4px", left: "50%", maxHeight: "90%", outline: "none", overflow: "auto", padding: "5px 20px 0", position: "fixed", top: "50%", transform: "translate(-50%,-50%)", maxWidth: "400px", width: "60%", }, overlay: { zIndex: 1000 }, };
  const ConfirmModalStyles = { content: { border: "1px solid #ccc", borderRadius: "4px", left: "50%", maxHeight: "175px", outline: "none", overflow: "auto", padding: "5px 20px 0", position: "fixed", top: "50%", transform: "translate(-50%,-50%)", maxWidth: "450px", width: "60%", }, overlay: { zIndex: 1000 }, };

/* VideoSubscription Component 
-------------------------------------------------*/
export default class VideoSubscription extends React.Component {
    constructor(props) {
        super(props);

        this.closeDoSubscriptionModal = this.closeDoSubscriptionModal.bind(this);
        this.openPlayVideoModal = this.openPlayVideoModal.bind(this);
        this.closePlayVideoModal = this.closePlayVideoModal.bind(this);

        this.afterOpenModal = this.afterOpenModal.bind(this);

        this.closeTermsModal = this.closeTermsModal.bind(this);
        this.paymentAgreed = this.paymentAgreed.bind(this);
    
        this.closeManageCardModal = this.closeManageCardModal.bind(this);
    
        this.closeConfirmSubscribeModal = this.closeConfirmSubscribeModal.bind(this);
        this.openConfirmSubscribeModal = this.openConfirmSubscribeModal.bind(this);
        this.SubscribeVideo = this.SubscribeVideo.bind(this);

        this.openReportProblemModal = this.openReportProblemModal.bind(this);
        this.closeReportProblemModal = this.closeReportProblemModal.bind(this);
    
        this.getVideoUrl = this.getVideoUrl.bind(this);
        this.getPaymentMethods = this.getPaymentMethods.bind(this);

        this.handleChangeSearchMyEntryTerm = this.handleChangeSearchMyEntryTerm.bind(this);
        this.handleChangeSearchEntryTerm = this.handleChangeSearchEntryTerm.bind(this);
        
        this.state = {
            is_open_get_video_url: false,
            is_open_report_problem_modal: false,
            is_open_add_to_playlist_modal: false,

            report_problem_error_msg: '',
            video_reported: false,

            video_playlists: [],
            create_playlist_error_msg: '',
            create_playlist_success_msg: '',
            fetching_playlist: true,
            is_open_play_video_modal: false,

            subscription_data: {},

            getting_subscription_options: true,
            subscription_options: [],
            selected_subscription: '',
            selected_subscription_obj: {},
            selected_show_sub: {},
            selected_payment_method: '-1',
            payment_agree: false,
            selected_show: 0,
            selected_circuit: '-1',
            selected_circuit_obj: {},
            payment_methods: [],
            check_card_email_phone_info: false,
            check_address_info: false,
            is_open_manage_card_modal: false,
            is_open_terms_modal: false,
            subscribe_error_msg: '',
            is_open_confirm_subscription_modal: false,
            videos_playlist: [],
                        
            search_entry: '',
            search_my_entry: '',
            my_entries_list: [],
            single_clip_entries_list: [],
            timeout: null,
            my_entry_timeout: null,
            selected_clip_entry: -1,
            selected_clip_entry_obj: {},
            selected_my_entry: -1,
            selected_my_entry_obj: {},
            block_video_purchase: false,
            video_disabled: false
        };
    }

    componentWillMount() {
        // console.log("this.props", this.props);
        this.getSubscriptionDetails(this.props.video_to_play.videos_sgl_id);
    }

    componentDidUpdate(prevProps) {
      let popup_card_header = document.getElementById("popup-card-header");
      if (!prevProps.isMobile) {
        popup_card_header.style.display = "block";
      } else {
        popup_card_header.style.display = "none";
      }
    }

    // openViewRefundPolicyModal() {
    //     this.setState({
    //       this.props.is_open_play_video_modal: true,
    //     });
    // }
    getBrowserInfo(){
        var sBrowser;
        var sUsrAg = window.navigator.userAgent;
    
        // The order matters here, and this may report false positives for unlisted browsers.
    
        if (sUsrAg.indexOf("Firefox") > -1) {
          sBrowser = "Mozilla Firefox";
          // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
        } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
          sBrowser = "Samsung Internet";
          // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
        } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
          sBrowser = "Opera";
          // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
        } else if (sUsrAg.indexOf("Trident") > -1) {
          sBrowser = "Microsoft Internet Explorer";
          // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
        } else if (sUsrAg.indexOf("Edge") > -1) {
          sBrowser = "Microsoft Edge";
          // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
        } else if (sUsrAg.indexOf("Chrome") > -1) {
          sBrowser = "Google Chrome or Chromium";
          // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
        } else if (sUsrAg.indexOf("Safari") > -1) {
          sBrowser = "Apple Safari";
          // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
        } else {
          sBrowser = "unknown";
        }
        return sBrowser;
      }
    format_currancy(amount) {
        return (
            "$" + (amount && amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        );
    }
    format_date(unformatted_date) {
        var month = new Array();
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sep";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";
        var unformatted_date_split = (unformatted_date && unformatted_date.split)?unformatted_date.split('T')[0]:unformatted_date;
        var formatted_date = new Date(unformatted_date_split);
        formatted_date = new Date(formatted_date.toISOString().slice(0, -1));
        formatted_date.getUTCMonth();
        return (
          month[formatted_date.getMonth()] +
          " " +
          formatted_date.getDate() +
          "," +
          formatted_date.getFullYear()
        );
    }
    
  
    closePlayVideoModal() {
        this.setState({
          is_open_play_video_modal: false,
        });
        
    }
    closeDoSubscriptionModal(){
        this.props.closeSubscriptionModal()
    }

    afterOpenModal() {
        // if (!is_mobile) {
        
        window.jwplayer("myElement").setup({ 
            "playlist": this.state.videos_playlist,
            "playlistposition": 'right',
            "playlistsize": 140,
            "title": 'abc',
            "autostart": true,
            "width": '100%',
            "height": '100%'
          });
        // }
    }

    ///////// video player functions ///////////
    getVideoUrl(video) {
        var video_url = '';
        if(video && video.videos_filepath && video.videos_filename){
            video_url = video.videos_other_url +
            "/" +
            video.videos_filepath.substring(
                video.videos_filepath.indexOf("/") + 1
            ) +
            "/" +
            video.videos_filename;
        }

        return video_url;
    }
    getVideoThumbUrl(video) {
        if (video["videos_thumbnail"] && video["videos_thumbnail"] != "false") {
          // var current_cdn = ((video['videos_other_url'])?video['videos_other_url']:sfConfig::get('app_thumb_url'));
          var video_url =
            video.videos_other_url +
            "/" +
            video.videos_filepath.substring(
              video.videos_filepath.indexOf("/") + 1
            ) +
            "/" +
            video.videos_thumbnail;
    
          return video_url;
        } else {
          return horse_logo;
        }
        // video.videos_other_url + '/' + video.videos_filepath.substring(video.videos_filepath.indexOf('/') + 1) + '/' + video.videos_thumbnail
    }
    getPreviewUrl(video) {
        var video_url =
          video.videos_other_url +
          "/" +
          video.videos_filepath.substring(
            video.videos_filepath.indexOf("/") + 1
          ) +
          "/" +
          video.videos_preview;
    
        return video_url;
    }
    getVideoDetailUrl(video) {
        var video_url = env.REACT_APP_SGL_API+'/videos/detail?vid='+video.videos_sgl_id;

        return video_url;
    }
    getPlayPreviewMsg(){
      if(this.props.is_video_subscription){
        return '#'+this.props.video_to_play.entries_number +' - '+this.props.video_to_play.entries_horse;
      } else if(this.state.selected_subscription == 'myentry')  {
        return '#'+this.state.selected_my_entry_obj.number +' - '+ this.state.selected_my_entry_obj.horse;
      } else {
        return '#'+this.state.selected_clip_entry_obj.number +' - '+ this.state.selected_clip_entry_obj.horse;
      }
    }

  ///// report problem functions ///////

  callReportProblemAPI(subject, description, video_id){
    var browser = this.getBrowserInfo();
    axios.get(sgl_webservice_videos+'ReportProblem',{
      params : {
          complaintType: 'Video Problem',
          complaintSubject: subject,
          complaintDescription: description,
          vid : video_id,
          browser: browser,
          ip_address: this.context.ip_address,
          customer_id: this.context.company_id
      }
    }).then(res => {
      document.getElementById('report_problem').style.display = "block";
      document.getElementById('report_problem-wait').style.display = "none";
      if (res.data.result){
        this.setState({
          video_reported: true
        });
      } else {
        this.setState({
          report_problem_error_msg: res.data.validation_result.error_messages,
        });
        document.getElementById('report_problem_warning').style.display = "block";
      }
    })
  }
  openReportProblemModal() {
    this.setState({
      is_open_report_problem_modal: true,
      video_reported: false
    });
    window.jwplayer("myElement").play(false);
  }
  reportProblem(){
    console.log('report problem');
    document.getElementById('report_problem_warning').style.display = "none";

    let kind_of_problem = document.getElementById("kind_of_problem");
    let description = document.getElementById("prob_description");
    var error = "";
    var video_id;
    if(this.props.is_video_subscription){
      video_id = this.props.video_to_play.videos_sgl_id;
    } else if(this.state.selected_subscription == 'myentry')  {
      video_id = this.state.selected_my_entry_obj.horse_videos.video_previews[0].sgl_id;
    } else {
      video_id = this.state.selected_clip_entry_obj.horse_videos.video_previews[0].sgl_id;
    }
    console.log(kind_of_problem.value, description.value, video_id);
    if (kind_of_problem.value == '-1'){
      error = 'Please select Kind of Problem.';
    } else if (!description.value || description.value == ''){
      error = 'Please provide Description.';
    }
    if (error == ""){
      console.log('call report problem API');
      document.getElementById('report_problem').style.display = "none";
      document.getElementById('report_problem-wait').style.display = "block";
      
      this.callReportProblemAPI(kind_of_problem.value, description.value, video_id);
      // this.setState({video_reported: true});
      
    } else {
      this.setState({report_problem_error_msg: error});
      document.getElementById('report_problem_warning').style.display = "block";
    }

  }
  closeReportProblemModal() {
    this.setState({
      is_open_report_problem_modal: false,
      video_reported: false
    });
    window.jwplayer("myElement").play();
  }
  ///// end report problem functions ///////
    //////// subscription functions ////////////

    getSubscriptionDetails(video_id){
        // video_id = 929690;
          axios.get(sgl_webservice_videos_list+'/subOptions/'+video_id,{
            params : {
                customer_id: this.context.company_id,
                is_download: this.props.is_download_subscription,
                subscription_type: this.props.subscription_type
            }
          })
          .then(
            (response) => {
              var show_id = 0;
              var selected_show_sub = {};
              var selected_sub = '';
              var selected_sub_obj = {};
                if(response.data.subscription_data.shows_list && response.data.subscription_data.shows_list.length ==1){
                  if( !response.data.subscription_data.shows_list[0].deactivated ) {
                    selected_show_sub = response.data.subscription_data.shows_list[0];
                    show_id = response.data.subscription_data.shows_list[0].show_id;
                  }
                }
                if(this.props.is_download_subscription){
                  selected_sub = 'downloadclip';
                  selected_sub_obj = response.data.subscription_data.subscription_options[0]
                }
                this.setState({
                  subscription_data: response.data.subscription_data,
                  subscription_options: response.data.subscription_data.subscription_options,
                  selected_show: show_id,
                  selected_show_sub: selected_show_sub,
                  getting_subscription_options: false,
                  block_video_purchase: response.data.block_video_purchase,
                  video_disabled: response.data.video_disabled,
                  selected_subscription: selected_sub,
                  selected_subscription_obj: selected_sub_obj
                });
                this.getPaymentMethods();
            },
            (error) => {}
          );
      }
      getPaymentMethods(){
        axios.get(sgl_webservice_iphone+'GetPaymentMethods',{
          params : {
              company_id: this.context.company_id
          }
        }).then(results => {
          this.setState({
            payment_methods: results.data.payment_methods?results.data.payment_methods:[],
            check_card_email_phone_info: results.data.check_card_email_phone_info,
            check_address_info: results.data.check_address_info
          })    
        })
      }
    
      subscriptionOptionSelect(e, selected_subscription) {
        var selected_subscription_obj = this.state.subscription_options.find(c => (c.sub_name == selected_subscription));
    
        this.setState({
          selected_subscription: selected_subscription,
          selected_subscription_obj: selected_subscription_obj?selected_subscription_obj:{},
          payment_agree: false
        });
      }
      // getSelectedSubscription(){
      //   var selected_subscription_obj = this.state.subscription_options.find(c => (c.sub_name == this.state.selected_subscription));
      //   return selected_subscription_obj;
      // }
      setShow = (event) => {
        var show = this.state.subscription_data.shows_list.find(c => (c.show_id == event.currentTarget.value));
        this.setState({
          selected_show : parseInt(event.currentTarget.value),
          // selected_subscription_obj: show.subscription_obj?show.subscription_obj:{},
          selected_show_sub: show.subscription_obj?show.subscription_obj:{},
        });
      }
      
      getShowDates(show) {
        return this.format_date(show.start_date) + ' to ' + this.format_date(show.end_date)

      }
      onCircuitSelect(e) {
        var circuit = this.state.subscription_data.circuit_subscriptions.find(c => (c.sgl_id == e.target.value));

        this.setState({
          selected_circuit: e.target.value,
          // selected_circuit_obj: circuit,
          selected_subscription_obj: circuit?circuit:{},
        });
      }
      getCircuitShows() {
        var circuit = this.state.subscription_data.circuit_subscriptions.find(c => (c.sgl_id == this.state.selected_circuit));
        if(circuit.shows.length > 0){
          return circuit.shows;
        }
        return [];
      }
      getCircuitPrice() {
        var circuit = this.state.subscription_data.circuit_subscriptions.find(c => (c.sgl_id == this.state.selected_circuit));
        if(circuit){
          return this.format_currancy(circuit.price)+' (USD)';
        }
        return;
      }
      onPaymentMethodSelect(e) {
        if (e.target.value == 'ManageCards'){
          // this.openManageCardsModal();
          this.setState({
            selected_payment_method: '-1',
            is_open_manage_card_modal: true
          });
        } else {
          this.setState({
            selected_payment_method: e.target.value,
          });
        }
      }
      closeManageCardModal() {
        this.setState({
          is_open_manage_card_modal: false,
        });
        
      }
      paymentAgreeChange(e) {
        this.setState({
          payment_agree: e.target.checked
        });
      }
      paymentAgreed(){
        this.setState({
          payment_agree: true,
          is_open_terms_modal: false,
        });
      }
      openTermsModal = () => (e) => {
        this.setState({
          is_open_terms_modal: true,
        });
      }
      closeTermsModal() {
        this.setState({
          is_open_terms_modal: false,
        });
      }
      submitSubscription() {
        var error_msg = '';
        this.setState({subscribe_error_msg: error_msg});
        document.getElementById('subscribe_warning').style.display = "none";
        
        if(this.state.selected_subscription && this.state.selected_subscription != ""){
          if(this.state.selected_subscription == "myentry"){
            if(!this.props.is_video_subscription && this.state.selected_my_entry == -1){
              error_msg = "Please select your Entry for Subscription.";
            }
            if(this.state.subscription_data.trip.deactivated_myhorse) {
              error_msg = "My Horse subscription is not available for this show.";
            }
          }
          if(this.state.selected_subscription == "singleclip"){
            if(!this.props.is_video_subscription && this.state.selected_clip_entry == -1){
              error_msg = "Please select an Entry for Subscription.";
            }
            if(this.state.subscription_data.trip.deactivated_singleclip) {
              error_msg = "Single Clip subscription is not available for this show.";
            }
          }
          if(this.state.selected_subscription == "byshow"){
            if(this.state.selected_show == 0){
                error_msg = "Please select a Show for Subscription.";
            }
          }
          if(this.state.selected_subscription == "bycircuit"){
            if(this.state.selected_circuit == '-1'){
              error_msg = "Please select a Circuit for Subscription.";
            }
          }
        } else {
          error_msg = "Please select a Subscription Option.";
        }
        if(error_msg == '' && this.state.selected_payment_method == '-1'){
          error_msg = 'Please select a Credit Card.';
        }
        if(error_msg == '' && !this.state.payment_agree){
          error_msg = 'Please agree Terms and Conditions.';
        }
    
        if (error_msg == ''){
          this.setState({
            is_open_confirm_subscription_modal: true,
          });
        } else {
          this.setState({subscribe_error_msg: error_msg});
          document.getElementById('subscribe_warning').style.display = "block";
    
        }
    
        // purchase_statement = "<br/>You are purchasing \""+subscription_description+"\" subscription.";
    
        return false;
      }
      openConfirmSubscribeModal = () => (e) => {
        this.setState({
          is_open_confirm_subscription_modal: true,
        });
      }
      closeConfirmSubscribeModal() {
        this.setState({
          is_open_confirm_subscription_modal: false,
        });
      }
      getPriceToPay(){
        var price = this.state.selected_subscription_obj.price;
        if(this.state.selected_subscription == "bycircuit"){
          price = this.state.selected_subscription_obj.price;
        } else if(this.state.selected_subscription == "byshow"){
          price = this.state.selected_show_sub.subscription_obj.price  ;
        }
        return price;
      }
      SubscribeVideo(){
        console.log('subscribing video');
        var subscription_option_id = this.state.selected_subscription_obj.sgl_id;
        document.getElementById('confirm_subscribe-wait').style.display = "block";
        document.getElementById('confirm_subscribe_button').style.display = "none";
        if(this.props.subscription_type == 'channel'){
          axios.post(sgl_webservice_iphone_sub+'SubscribeChannel', null, {
            params: {
              "customer_id": this.context.company_id,
              "subscription_options": subscription_option_id,
              "credit-card-option": this.state.selected_payment_method,
              
            }
          })
          .then(results => {
            document.getElementById('confirm_subscribe-wait').style.display = "none";
            document.getElementById('confirm_subscribe_button').style.display = "block";
            this.setState({
              is_open_confirm_subscription_modal: false,
            });

            if(results.data.success) {
              console.log('video subscribed');
              // this.closeDoSubscriptionModal();
              
              document.getElementById('subscribe').style.display = "none";
              document.getElementById('subacribe_wait').style.display = "block";

              this.props.playVideoAfterSubscribe();
            } else {
              console.log('video not subscribed', results.data.message); 
              this.setState({subscribe_error_msg: results.data.message});
              document.getElementById('subscribe_warning').style.display = "block";
            }
          });
        } else {
          var entry_id, class_group_id, class_id;
          if(this.props.is_video_subscription && !this.state.subscription_data.trip.deactivated_myhorse){
            entry_id = this.state.subscription_data.trip.entry_id;
          } else {
            entry_id = this.state.selected_my_entry;
          }
          if(this.props.is_video_subscription && !this.state.subscription_data.trip.deactivated_singleclip){
            class_group_id = this.state.subscription_data.trip.entry_id + '_' + this.state.subscription_data.class_group.class_group_id + '_' + this.state.subscription_data.trip.class_id;
          } else {
            class_group_id = this.state.selected_clip_entry;
          }
          
          if(this.state.selected_subscription == "bycircuit"){
            subscription_option_id = this.state.selected_circuit;
          // } else if(this.state.selected_subscription == "byshow"){
          //   price = this.state.selected_show_sub.sgl_id;
          }
          
          axios.post(sgl_webservice_iphone_sub+'SubscribeVideo', null, {
            params: {
              "customer_id": this.context.company_id,
              "subscription_options": subscription_option_id,
              "credit-card-option": this.state.selected_payment_method,
              "terms_and_conditions": this.state.payment_agree?'on':'off',
              "entry_id": entry_id,
              "class_group_id": class_group_id,
              "show_id": this.state.selected_show,
              "circuit_id":  this.state.selected_circuit

            }
          })
          .then(results => {
            document.getElementById('confirm_subscribe-wait').style.display = "none";
            document.getElementById('confirm_subscribe_button').style.display = "block";
            this.setState({
              is_open_confirm_subscription_modal: false,
            });

            if(results.data.success) {
              console.log('video subscribed');
              // if(!this.props.is_download_subscription){
              //   this.closeDoSubscriptionModal();
              // }
              document.getElementById('subscribe').style.display = "none";
              document.getElementById('subacribe_wait').style.display = "block";
              this.props.playVideoAfterSubscribe();
            } else {
              console.log('video not subscribed', results.data.message); 
              this.setState({subscribe_error_msg: results.data.message});
              document.getElementById('subscribe_warning').style.display = "block";
            }
          });
        }
      }
      playVideoPreviews(video_previews){
        var playlist_format = [];
        for(var count=0; count<video_previews.length; count++){
          var video = video_previews[count];
            playlist_format.push({
                "image" : this.getVideoThumbUrl(video),
                "file"  : this.getPreviewUrl(video)
            });
        }
        console.log(playlist_format);
        this.setState({'play_video_path': "", videos_playlist: playlist_format, playing_preview: true})
    
        this.openPlayVideoModal();
      }
      openPlayVideoModal() {
        this.setState({
          is_open_play_video_modal: true,
        });
      
      }
      //////// end subscription functions ////////////

      handleChangeSearchMyEntryTerm(event) {
        this.setState({
          search_my_entry:event.target.value,

        })
        if (this.state.my_entry_timeout) {
          clearTimeout(this.state.my_entry_timeout);
        }
        event.persist();
        this.state.my_entry_timeout = setTimeout(() => {
          document.getElementById('search-my-entry-wait').style.display = "inline";
          this.getEntriesSearchData(
            event.target.value,
            'myentry'
          );
        }, 700);
      }

      handleChangeSearchEntryTerm(event) {
        this.setState({
          search_entry:event.target.value,

        })
        if (this.state.timeout) {
          clearTimeout(this.state.timeout);
        }
        event.persist();
        this.state.timeout = setTimeout(() => {
          document.getElementById('search-entry-wait').style.display = "inline";
          this.getEntriesSearchData(
            event.target.value,
            'singleclip'
          );
        }, 700);
      }

      getEntriesSearchData(keyword, area) {
        if (keyword !== "" && keyword.length >= 3) {
        // if (keyword !== "") {

          axios
            .get(sgl_webservice_videos_list + "/entriesSearch", {
              params: {
                keyword: keyword,
                search_area: area,
                customer_id: this.context.company_id,
              },
            })
            .then((res) => {
              
              if(area == 'myentry'){
                document.getElementById('search-my-entry-wait').style.display = "none";
                this.setState({
                  my_entries_list: res.data.entries_list,
                  selected_my_entry: -1,
                  selected_my_entry_obj: {}
                });
              } else {
                document.getElementById('search-entry-wait').style.display = "none";
                this.setState({
                  single_clip_entries_list: res.data.entries_list,
                  selected_clip_entry: -1,
                  selected_clip_entry_obj: {},
                });
              }
            });
        } else {
          if(area == 'myentry'){
            document.getElementById('search-my-entry-wait').style.display = "none";

            // this.setState({
            //   my_entries_list: [],
            // });
          } else {
            document.getElementById('search-entry-wait').style.display = "none";

            // this.setState({
            //   single_clip_entries_list: [],
            // });
          }
        }
      }
      setMyEntry = (event) => {
        var entry = this.state.my_entries_list.find(c => (c.entry_id == event.currentTarget.value));

        this.setState({
          selected_my_entry : parseInt(event.currentTarget.value),
          selected_my_entry_obj: entry?entry:{},
          // selected_show_sub: show.subscription_obj?show.subscription_obj:{},
        });
      }
      setClipEntry = (event) => {
        // var target_values = event.currentTarget.value.split('_');
        // var entry_id = target_values[0];
        // var class_group_id = target_values[1];
        var entry = this.state.single_clip_entries_list.find(c => (c.entry_group_id == event.currentTarget.value));

        this.setState({
          selected_clip_entry : event.currentTarget.value,
          selected_clip_entry_obj: entry?entry:{},
          // selected_show_sub: show.subscription_obj?show.subscription_obj:{},
        });
      }
      format_date(unformatted_date){
        var unformatted_date_split = (unformatted_date && unformatted_date.split)?unformatted_date.split('T')[0]:unformatted_date;
        var formatted_date = (new Date(unformatted_date_split));
        formatted_date = new Date(formatted_date.toISOString().slice(0, -1));
        return parseInt(formatted_date.getMonth()+1) +"/"+ formatted_date.getDate() +"/"+ formatted_date.getFullYear();
      }

    render() {
      let inIframe = false;
      if(window.location !== window.parent.location){
        inIframe = true;
      }
      const configDefaults = { width: "100%", height: "100%" };
      return (
        <div className='popup-card-deck' style={{ padding: "0px 0px" }}>
          <div class='popup-card-item'>
            <div class='bar'> &nbsp; </div>
            <div class='popup-card' style={{ background: "white" }}>
              <div className='popup-card-header' id='popup-card-header'>
                <h2 className='popup-card-title'>Video Subscription</h2>
                <div style={{ textAlign: "center", fontSize: "12px", color: "#333"}}>
                  <span>
                    {" "}
                    {process.env.REACT_APP_CREDIT_TEXT}{" "}
                    <Link to={process.env.REACT_APP_CREDIT_URL}>
                      {" "}
                      {process.env.REACT_APP_CREDIT_COMPANY}
                    </Link>
                  </span>
                </div>
              </div>
              <div className='mymodal' style={{ padding: "5px 20px 0" }}>
                {this.state.block_video_purchase || this.state.video_disabled ? (
                  <div>
                    {this.state.block_video_purchase ? (
                      <p>
                        Your account has been disabled from being able to purchase
                        video clips on our site. If you feel you have received
                        this message in error, or if you need support, please
                        contact{" "}
                        <a href='mailto:support@showgroundslive.com'>
                          support@showgroundslive.com
                        </a>
                        .
                      </p>
                    ) : (
                      <p>
                        Video service is currently not available. If you need
                        support, please contact{" "}
                        <a href='mailto:support@showgroundslive.com'>
                          support@showgroundslive.com
                        </a>
                        .
                      </p>
                    )}
  
                    <div className='continue-button-div'>
                      {this.props.is_video_subscription && (
                        <button
                          className='right sgl-button'
                          onClick={this.closeDoSubscriptionModal}>
                          Close
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className={
                        this.props.is_video_subscription
                          ? "subscription_div_overflow"
                          : ""
                      }>
                      <label className='sgl-label-border sgl-add_entry font-13 top-margin-15'>
                        Subscription Options
                      </label>
                      {this.state.getting_subscription_options ? (
                        <div>
                          <img src={indicator_icon} />
                        </div>
                      ) : (
                        <div className='flex-video-container'>
                          {this.state.subscription_options.map(
                            (subscription_option, index) => {
                              return (
                                <div className='class_list_li subscription_radios'>
                                  <div className='center-align'>
                                    <input
                                      type='radio'
                                      id={"option_" + subscription_option.sgl_id}
                                      name='ios_sub_option'
                                      className='sgl-mr-5'
                                      value={subscription_option.sgl_id}
                                      onChange={(e) =>
                                        this.subscriptionOptionSelect(
                                          e,
                                          subscription_option.sub_name
                                        )
                                      }
                                      // onChange={this.setIosSubOption}
                                      checked={
                                        this.state.selected_subscription ===
                                        subscription_option.sub_name
                                      }
                                    />
                                    <br />
                                    <label
                                      for={"option_" + subscription_option.sgl_id}
                                      className='subscription_radio_option'>
                                      <span className='font-16'>
                                        {subscription_option.name}
                                      </span>
                                      <br />
                                      {subscription_option.sub_name ===
                                      "bycircuit" ? (
                                        <span className='font-13'>
                                          {this.state.selected_circuit === "-1"
                                            ? "Select Circuit for pricing"
                                            : this.getCircuitPrice()}
                                        </span>
                                      ) : (
                                        <span className='font-13'>
                                          {this.format_currancy(
                                            subscription_option.sub_name ===
                                              "byshow" &&
                                              this.state.selected_show_sub.price
                                              ? this.state.selected_show_sub.price
                                              : subscription_option.price
                                          )}{" "}
                                          (USD)
                                        </span>
                                      )}
                                      {/* <br /> */}
                                      <hr className='ios-hr' />
                                      <span
                                        className='desc_span'
                                        dangerouslySetInnerHTML={{
                                          __html: subscription_option.description,
                                        }}
                                      />
                                      {this.props.subscription_type ===
                                        "channel" && (
                                        <span className='sub_dates'>
                                          From {subscription_option.start_date} to{" "}
                                          {subscription_option.end_date}
                                        </span>
                                      )}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                      <div style={{ clear: "both" }}></div>
                      {this.state.selected_subscription === "myentry" && (
                        <div>
                          {this.props.is_video_subscription ? (
                            <div>
                              <label className='sgl-label-border sgl-add_entry font-13 top-margin-15'>
                                Entry Information
                              </label>
                              {!this.state.subscription_data.trip
                                .deactivated_myhorse ? (
                                <table className='table video-data-table'>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b>Entry:</b>
                                        <br />
                                        {
                                          this.state.subscription_data.trip
                                            .entry_number
                                        }{" "}
                                        -{" "}
                                        {this.state.subscription_data.trip.horse}
                                      </td>
                                      <td>
                                        <b>Owner:</b>
                                        <br />
                                        {this.state.subscription_data.owner &&
                                        this.state.subscription_data.owner
                                          .owner_name
                                          ? this.state.subscription_data.owner
                                              .owner_name
                                          : "N/A"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Rider:</b>
                                        <br />
                                        {this.state.subscription_data.rider &&
                                        this.state.subscription_data.rider
                                          .rider_name
                                          ? this.state.subscription_data.rider
                                              .rider_name
                                          : "N/A"}
                                      </td>
                                      <td>
                                        <b>Show:</b>
                                        <br />
                                        {
                                          this.state.subscription_data.trip
                                            .show_name
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {this.state.subscription_data
                                          .horse_videos &&
                                          this.state.subscription_data
                                            .horse_videos.videos_count > 0 && (
                                            <b>
                                              (
                                              {
                                                this.state.subscription_data
                                                  .horse_videos.videos_count
                                              }{" "}
                                              Video
                                              {this.state.subscription_data
                                                .horse_videos.videos_count > 1
                                                ? "s) "
                                                : ") "}
                                            </b>
                                          )}
                                        {this.state.subscription_data.horse_videos
                                          .previews_count > 0 && (
                                          <a
                                            onClick={() =>
                                              this.playVideoPreviews(
                                                this.state.subscription_data
                                                  .horse_videos.video_previews
                                              )
                                            }
                                            className=''>
                                            Preview
                                          </a>
                                        )}
                                      </td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              ) : (
                                <span className='disabled_sub'>
                                  My Horse subscription is not available for this
                                  show
                                </span>
                              )}
                            </div>
                          ) : (
                            <div>
                              <label className='sgl-label-border sgl-add_entry font-13 top-margin-15'>
                                Select Entry
                              </label>
                              <input
                                type='text'
                                className='sub-input-width'
                                value={this.state.search_my_entry}
                                onChange={this.handleChangeSearchMyEntryTerm}
                                id='searchEntry'
                              />
                              <span
                                id='search-my-entry-wait'
                                className='display_none'>
                                {" "}
                                <img src={indicator_icon} />
                              </span>
  
                              <table className='table table-striped table-hover scroll_table'>
                                <thead>
                                  <tr className='sgl-primary-header'>
                                    <th
                                      className='required center-align'
                                      style={{ width: "20px" }}>
                                      *
                                    </th>
                                    <th>
                                      Entries{" "}
                                      <span className='right'>
                                        (Only entries in shows with videos are
                                        shown)
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className='table-scroll'>
                                  {this.state.my_entries_list &&
                                    this.state.my_entries_list.length > 0 &&
                                    this.state.my_entries_list.map(
                                      (entry, index) => {
                                        return (
                                          <tr className=''>
                                            <td
                                              style={{ width: "20px" }}
                                              nowrap='nowrap'>
                                              <input
                                                type='radio'
                                                name='my_entry'
                                                className='no-margin'
                                                value={entry.entry_id}
                                                checked={
                                                  this.state.selected_my_entry ===
                                                  entry.entry_id
                                                }
                                                onChange={this.setMyEntry}
                                                disabled={
                                                  entry.has_subscription ||
                                                  entry.deactivated
                                                }
                                              />
                                            </td>
                                            {/* <td> */}
                                            <td
                                              onClick={
                                                entry.has_subscription ||
                                                entry.deactivated
                                                  ? undefined
                                                  : () =>
                                                      this.setMyEntry({
                                                        currentTarget: {
                                                          value: entry.entry_id,
                                                        },
                                                      })
                                              }>
                                              #{entry.number} - {entry.horse}
                                              {entry.horse_videos &&
                                                entry.horse_videos.videos_count >
                                                  0 &&
                                                !entry.deactivated && (
                                                  <b>
                                                    {" "}
                                                    (
                                                    {
                                                      entry.horse_videos
                                                        .videos_count
                                                    }{" "}
                                                    Video
                                                    {entry.horse_videos
                                                      .videos_count > 1
                                                      ? "s) "
                                                      : ") "}
                                                  </b>
                                                )}
                                              {entry.horse_videos.previews_count >
                                                0 &&
                                                !entry.deactivated && (
                                                  <a
                                                    onClick={() =>
                                                      this.playVideoPreviews(
                                                        entry.horse_videos
                                                          .video_previews
                                                      )
                                                    }
                                                    className=''>
                                                    Preview
                                                  </a>
                                                )}
                                              {/* {(show.free_subscription) &&
                                                <span className="free_sub"><b>&nbsp; FREE</b></span>
                                              } */}
                                              <span className='right'>
                                                {entry.show_name}
                                              </span>
                                              {entry.deactivated && (
                                                <span className='disabled_sub'>
                                                  Not available for subscription
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.selected_subscription === "singleclip" && (
                        <div>
                          {this.props.is_video_subscription ? (
                            <div>
                              <label className='sgl-label-border sgl-add_entry font-13 top-margin-15'>
                                Entry Information
                              </label>
                              {!this.state.subscription_data.trip
                                .deactivated_singleclip ? (
                                <table className='table video-data-table'>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b>Entry:</b>
                                        <br />
                                        {
                                          this.state.subscription_data.trip
                                            .entry_number
                                        }{" "}
                                        -{" "}
                                        {this.state.subscription_data.trip.horse}
                                      </td>
                                      <td>
                                        <b>Owner:</b>
                                        <br />
                                        {this.state.subscription_data.owner &&
                                        this.state.subscription_data.owner
                                          .owner_name
                                          ? this.state.subscription_data.owner
                                              .owner_name
                                          : "N/A"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Rider:</b>
                                        <br />
                                        {this.state.subscription_data.rider &&
                                        this.state.subscription_data.rider
                                          .rider_name
                                          ? this.state.subscription_data.rider
                                              .rider_name
                                          : "N/A"}
                                      </td>
                                      <td>
                                        <b>Class:</b>
                                        <br />
                                        {
                                          this.state.subscription_data.trip
                                            .class_number
                                        }{" "}
                                        -{" "}
                                        {
                                          this.state.subscription_data.trip
                                            .class_name
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Show:</b>
                                        <br />
                                        {
                                          this.state.subscription_data.trip
                                            .show_name
                                        }
                                      </td>
                                      <td>
                                        {this.state.subscription_data
                                          .single_clip_videos &&
                                          this.state.subscription_data
                                            .single_clip_videos.videos_count >
                                            0 && (
                                            <b>
                                              (
                                              {
                                                this.state.subscription_data
                                                  .single_clip_videos.videos_count
                                              }{" "}
                                              Video
                                              {this.state.subscription_data
                                                .single_clip_videos.videos_count >
                                              1
                                                ? "s) "
                                                : ") "}
                                            </b>
                                          )}
                                        {this.state.subscription_data
                                          .single_clip_videos.previews_count >
                                          0 && (
                                          <a
                                            onClick={() =>
                                              this.playVideoPreviews(
                                                this.state.subscription_data
                                                  .single_clip_videos
                                                  .video_previews
                                              )
                                            }
                                            className=''>
                                            Preview
                                          </a>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              ) : (
                                <span className='disabled_sub'>
                                  Single Clip subscription is not available for
                                  this show
                                </span>
                              )}
                            </div>
                          ) : (
                            <div>
                              <label className='sgl-label-border sgl-add_entry font-13 top-margin-15'>
                                Select Entry
                              </label>
                              <input
                                type='text'
                                className='sub-input-width'
                                value={this.state.search_entry}
                                onChange={this.handleChangeSearchEntryTerm}
                                id='searchEntry'
                              />
                              <span
                                id='search-entry-wait'
                                className='display_none'>
                                {" "}
                                <img src={indicator_icon} />
                              </span>
  
                              <table className='table table-striped table-hover scroll_table'>
                                <thead>
                                  <tr className='sgl-primary-header'>
                                    <th
                                      className='required center-align'
                                      style={{ width: "20px" }}>
                                      *
                                    </th>
                                    <th>
                                      Entries{" "}
                                      <span className='right'>
                                        (Only entries in shows with videos are
                                        shown)
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className='table-scroll'>
                                  {this.state.single_clip_entries_list &&
                                    this.state.single_clip_entries_list.length >
                                      0 &&
                                    this.state.single_clip_entries_list.map(
                                      (entry, index) => {
                                        return (
                                          <tr className=''>
                                            <td
                                              style={{ width: "20px" }}
                                              nowrap='nowrap'>
                                              <input
                                                type='radio'
                                                name='my_entry'
                                                className='no-margin'
                                                value={entry.entry_group_id}
                                                checked={
                                                  this.state
                                                    .selected_clip_entry ===
                                                  entry.entry_group_id
                                                }
                                                onChange={this.setClipEntry}
                                                disabled={
                                                  entry.has_subscription ||
                                                  entry.deactivated
                                                }
                                              />
                                            </td>
                                            {/* <td> */}
                                            <td
                                              onClick={
                                                entry.has_subscription ||
                                                entry.deactivated
                                                  ? undefined
                                                  : () =>
                                                      this.setClipEntry({
                                                        currentTarget: {
                                                          value:
                                                            entry.entry_group_id,
                                                        },
                                                      })
                                              }>
                                              #{entry.number} - {entry.horse}
                                              {entry.horse_videos &&
                                                entry.horse_videos.videos_count >
                                                  0 &&
                                                !entry.deactivated && (
                                                  <b>
                                                    {" "}
                                                    (
                                                    {
                                                      entry.horse_videos
                                                        .videos_count
                                                    }{" "}
                                                    Video
                                                    {entry.horse_videos
                                                      .videos_count > 1
                                                      ? "s) "
                                                      : ") "}
                                                  </b>
                                                )}
                                              {entry.horse_videos.previews_count >
                                                0 &&
                                                !entry.deactivated && (
                                                  <a
                                                    onClick={() =>
                                                      this.playVideoPreviews(
                                                        entry.horse_videos
                                                          .video_previews
                                                      )
                                                    }
                                                    className=''>
                                                    Preview
                                                  </a>
                                                )}
                                              {/* {(show.free_subscription) &&
                                                <span className="free_sub"><b>&nbsp; FREE</b></span>
                                              } */}
                                              <span className='right'>
                                                {entry.show_name}
                                              </span>
                                              <br />
                                              <span className=''>
                                                {entry.class_number} -{" "}
                                                {entry.class_name}
                                              </span>
                                              {entry.deactivated && (
                                                <span className='disabled_sub'>
                                                  Not available for subscription
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.selected_subscription === "byshow" && (
                        <div>
                          <label className='sgl-label-border sgl-add_entry font-13 top-margin-15'>
                            Select Show
                          </label>
  
                          <table className='table table-striped table-hover scroll_table'>
                            <thead>
                              <tr className='sgl-primary-header'>
                                <th
                                  className='required center-align'
                                  style={{ width: "20px" }}>
                                  *
                                </th>
                                <th>Shows</th>
                              </tr>
                            </thead>
                            <tbody className='table-scroll'>
                              {this.state.subscription_data.shows_list &&
                                this.state.subscription_data.shows_list.length >
                                  0 &&
                                this.state.subscription_data.shows_list.map(
                                  (show, index) => {
                                    return (
                                      <tr className=''>
                                        <td
                                          style={{ width: "20px" }}
                                          nowrap='nowrap'>
                                          <input
                                            type='radio'
                                            name='show'
                                            className='no-margin'
                                            value={show.show_id}
                                            checked={
                                              this.state.selected_show ===
                                                show.show_id && !show.deactivated
                                            }
                                            onChange={this.setShow}
                                            disabled={
                                              show.free_subscription ||
                                              show.deactivated
                                            }
                                          />
                                        </td>
                                        <td
                                          onClick={
                                            show.free_subscription ||
                                            show.deactivated
                                              ? undefined
                                              : () =>
                                                  this.setShow({
                                                    currentTarget: {
                                                      value: show.show_id,
                                                    },
                                                  })
                                          }>
                                          {/* <td onClick={ () => this.setShow({currentTarget:{value:show.show_id}})}> */}
                                          {this.getShowDates(show)} -{" "}
                                          {show.show_name}
                                          {show.free_subscription && (
                                            <span className='free_sub'>
                                              <b>&nbsp; FREE</b>
                                            </span>
                                          )}
                                          {show.deactivated && (
                                            <span className='disabled_sub'>
                                              Not available for subscription
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {this.state.selected_subscription === "bycircuit" && (
                        <div>
                          <label className='sgl-label-border sgl-add_entry font-13 top-margin-15'>
                            Select circuit
                          </label>
                          <select
                            name='circuits'
                            id='circuits'
                            className='input-height select-width'
                            value={this.state.selected_circuit}
                            onChange={(e) => this.onCircuitSelect(e)}>
                            <option value='-1'>Please Select</option>
                            {this.state.subscription_data.circuit_subscriptions.map(
                              (circuit, index) => {
                                return (
                                  <option
                                    disabled={circuit.deactivated}
                                    value={`${circuit.sgl_id}`}>
                                    {circuit.name}
                                  </option>
                                );
                              }
                            )}
                          </select>
                          {this.state.selected_circuit != "-1" && (
                            <table className='table table-striped table-hover'>
                              <tbody>
                                <tr className='sgl-primary-header'>
                                  <th>
                                    You will get subscription for following Shows
                                  </th>
                                </tr>
                                {this.getCircuitShows().map((show, index) => {
                                  return (
                                    <tr className=''>
                                      <td>
                                        {this.getShowDates(show)} -{" "}
                                        {show.show_name}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      )}
                      {this.state.selected_subscription === "downloadclip" && (
                        <div>
                          <div>
                            <label className='sgl-label-border sgl-add_entry font-13 top-margin-15'>
                              Entry Information
                            </label>
                            <table className='table video-data-table'>
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Entry:</b>
                                    <br />
                                    {
                                      this.state.subscription_data.trip
                                        .entry_number
                                    }{" "}
                                    - {this.state.subscription_data.trip.horse}
                                  </td>
                                  <td>
                                    <b>Owner:</b>
                                    <br />
                                    {this.state.subscription_data.owner &&
                                    this.state.subscription_data.owner.owner_name
                                      ? this.state.subscription_data.owner
                                          .owner_name
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Rider:</b>
                                    <br />
                                    {this.state.subscription_data.rider &&
                                    this.state.subscription_data.rider.rider_name
                                      ? this.state.subscription_data.rider
                                          .rider_name
                                      : "N/A"}
                                  </td>
                                  <td>
                                    <b>Class:</b>
                                    <br />
                                    {
                                      this.state.subscription_data.trip
                                        .class_number
                                    }{" "}
                                    -{" "}
                                    {this.state.subscription_data.trip.class_name}
                                    {/* {this.state.subscription_data.class_group.group_name} */}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Show:</b>
                                    <br />
                                    {this.state.subscription_data.trip.show_name}
                                  </td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                      <div className='mr-btm-5'>
                        <label className='sgl-label-border sgl-add_entry font-13 top-margin-15'>
                          Billing Information
                        </label>
                        <label for='' className='add_entry_custom_label'>
                          {" "}
                          Credit Card &nbsp;<em className='required'>*</em>
                        </label>
  
                        <select
                          id='credit_card'
                          className='input-height select-width'
                          value={this.state.selected_payment_method}
                          onChange={(e) => this.onPaymentMethodSelect(e)}>
                          <option value='-1'>Select Credit Card...</option>
                          {this.state.payment_methods.map(
                            (payment_method, index) => {
                              return (
                                <option value={`${payment_method.sgl_id}`}>
                                  {payment_method.label}
                                </option>
                              );
                            }
                          )}
                          <option value='ManageCards'>Manage Credit Cards</option>
                        </select>
                        <input
                          type='checkbox'
                          name='checkAgree'
                          id='checkAgree'
                          checked={this.state.payment_agree}
                          onChange={(e) => this.paymentAgreeChange(e)}
                        />
                        <label for='checkAgree'>
                          {" "}
                          I understand and agree to the{" "}
                        </label>
                        <a onClick={this.openTermsModal()}>
                          <b>terms, conditions, and refund policy.</b>
                        </a>
                      </div>
                    </div>
                    <div className='continue-button-div'>
                      <div
                        id='subscribe_warning'
                        style={{ textIndent: "2px" }}
                        className='required display_none mr-btm-5'>
                        {this.state.subscribe_error_msg}
                      </div>
  
                      <div
                        id='price_usd-top'
                        className='right required sub_display_on_mobile'>
                        <b>(All prices are in US Dollars.)</b>
                      </div>
                      <div style={{ clear: "both" }}></div>
                      {/* {this.props.is_video_subscription && ( */}
                        <button
                          className='left sgl-button'
                          onClick={this.closeDoSubscriptionModal}>
                          Close
                        </button>
                      {/* )} */}
                      <div>
                        <div id='subacribe_wait' className='right display_none'>
                          <img src={indicator_icon} />
                        </div>
                        <button
                          id='subscribe'
                          className='right sgl-button'
                          onClick={() => this.submitSubscription()}>
                          Subscribe
                        </button>
                        <span
                          id='price_usd'
                          className='right required sub_display_on_desktop'>
                          <b>(All prices are in US Dollars.)</b>
                        </span>
                      </div>
                    </div>
                    {this.state.subscription_data.terms && (
                      <AddModal
                        isOpen={this.state.is_open_terms_modal}
                        // onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeTermsModal}
                        className='mymodal modal-width-small'
                        // overlayClassName="myoverlay"
                        ariaHideApp={false}
                        style={TermsModalStyles}>
                        <div className='child_modal'>
                          <h2>Terms, Conditions and Refund Policy</h2>
                        </div>
  
                        <h3 className='top-margin-15'>Terms and Conditions</h3>
                        <div className='terms_div'>
                          <span
                            className='desc_span'
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.subscription_data.terms
                                  .terms_and_conditions,
                            }}
                          />
                        </div>
                        <h3 className='top-margin-15'>Refund Policy</h3>
                        <div className='terms_div'>
                          <span
                            className='desc_span'
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.subscription_data.terms.refund_policy,
                            }}
                          />
                        </div>
                        <div className='align-buttons continue-button-div'>
                          <button
                            className='left sgl-button'
                            onClick={this.closeTermsModal}>
                            Close
                          </button>
                          <button
                            id='terms_agreed_button'
                            className='right sgl-button'
                            onClick={this.paymentAgreed}>
                            Agree
                          </button>
                        </div>
                      </AddModal>
                    )}
                    <AddModal
                      isOpen={this.state.is_open_confirm_subscription_modal}
                      // onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeConfirmSubscribeModal}
                      className='mymodal modal-width-small'
                      // overlayClassName='myoverlay'
                      style={ConfirmModalStyles}
                      ariaHideApp={false}>
                      <div className='child_modal'>
                        <h2>Please confirm your subscription purchase</h2>
                      </div>
  
                      {/* <h3 className='top-margin-15'>Terms and Conditions</h3> */}
                      <p className='top-margin-15'>
                        You are purchasing "
                        {this.state.selected_subscription_obj.name}" subscription.
                      </p>
                      {/* <div>
                          <p className='green-text'>Your credit card will be charged for {this.format_currancy(this.state.selected_subscription_obj.price)} (USD)</p>
                          </div>        */}
                      <div className='continue-button-div'>
                        <div>
                          <p className='green-text'>
                            Your credit card will be charged for{" "}
                            {this.format_currancy(this.getPriceToPay())} (USD)
                          </p>
                        </div>
                        <div style={{ clear: "both" }}></div>
                        <button
                          className='left sgl-button'
                          onClick={this.closeConfirmSubscribeModal}>
                          Close
                        </button>
                        <span
                          id='confirm_subscribe-wait'
                          className='right display_none'>
                          {" "}
                          <img src={indicator_icon} />
                        </span>
                        <button
                          id='confirm_subscribe_button'
                          className='right sgl-button'
                          onClick={this.SubscribeVideo}>
                          Continue
                        </button>
                      </div>
                    </AddModal>
                    {this.state.is_open_manage_card_modal && (
                      <ManageCards
                        payment_methods={this.state.payment_methods}
                        check_card_email_phone_info={
                          this.state.check_card_email_phone_info
                        }
                        check_address_info={this.state.check_address_info}
                        is_open_manage_card_modal={
                          this.state.is_open_manage_card_modal
                        }
                        closeManageCardModal={this.closeManageCardModal}
                        getPaymentMethods={this.getPaymentMethods}
                        ref={this.ManageCardsElement}
                      />
                    )}
  
                    <div id=''>
                      {this.state.is_open_play_video_modal && (
                        <div className='videos-player-css'>
                          <AddModal
                            isOpen={this.state.is_open_play_video_modal}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closePlayVideoModal}
                            className={
                              "modal-width-small videos-css video-modal" +
                              (inIframe ? " in-iframe" : "")
                            }
                            overlayClassName='myoverlay'
                            ariaHideApp={false}
                            // style={customStyles}
                            // contentLabel="Example Modal"
                          >
                            <div className='player_options'>
                              <div className='thePlayer_infobox_previews'>
                                <div className='top-div_previews'>
                                  {this.context.company_id === 15 ? (
                                    <b>
                                      PALM BEACH INTERNATIONAL EQUESTRIAN CENTER
                                    </b>
                                  ) : (
                                    <b>ShowGroundsLive.com</b>
                                  )}
                                  <br />
                                  <span>{this.getPlayPreviewMsg()}</span>
                                </div>
                              </div>
                              <div className='cross-button_previews'>
                                <img
                                  className='cross-btn-img'
                                  src={close_icon}
                                  onClick={this.closePlayVideoModal}
                                />
                              </div>
  
                              {/* <div className="flex-container-row">
                                  <div className="flex-container-column"> */}
                              <div
                                className='modalVideoOptionButton_previews'
                                onClick={this.openReportProblemModal}>
                                <img src={report_icon} alt='Report Icon' />
                              </div>
                              {/* </div>
                                  
                                  </div> */}
                            </div>
                            <div id='myElement' className='video-player-element'>
                              {/* <JWPlayer
                                config={configDefaults}
                                playlist={this.state.videos_playlist}
                                library={process.env.PUBLIC_URL + "/jwplayer8.js"}
                              /> */}
                            </div>
                            <div className='bottm-div display_none display_now'>
                              <div className='align-center'>
                                <span>{this.getPlayPreviewMsg()}</span>
                              </div>
                            </div>
                          </AddModal>
                          <AddModal
                            isOpen={this.state.is_open_report_problem_modal}
                            // onAfterOpen={this.afterOpenUrlModal}
                            onRequestClose={this.closeReportProblemModal}
                            className='mymodal modal-width-small'
                            overlayClassName='myoverlay'
                            ariaHideApp={false}>
                            <h2>Report a Problem</h2>
                            {this.state.video_reported ? (
                              <span id='problem_reported_success' className=''>
                                Thank You!
                                <br />
                                Your request about the problem you are facing has
                                been recieved. We shall contact you soon.
                              </span>
                            ) : (
                              <div>
                                <div className='add_modal_div' id='kind_div'>
                                  <h4>Kind of problem</h4>
                                  <div style={{ clear: "both" }}></div>
                                  <div className='add_modal_div txt-field-container-modal'>
                                    <select className='' id='kind_of_problem'>
                                      <option value='-1'>Please Select...</option>
                                      <option value='Incorrect horse/rider/class identification'>
                                        Incorrect horse/rider/class identification
                                      </option>
                                      <option value='Poor Quality'>
                                        Poor Quality
                                      </option>
                                      <option value='Other'>Other</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='add_modal_div' id='desc_div'>
                                  <h4>Description</h4>
                                  <div style={{ clear: "both" }}></div>
                                  <div className='add_modal_div txt-field-container-modal'>
                                    <textarea
                                      placeholder=''
                                      id='prob_description'
                                      rows='10'
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            <div
                              id='report_problem_warning'
                              style={{ textIndent: "2px" }}
                              className='required display_none'>
                              {this.state.report_problem_error_msg}
                            </div>
  
                            {!this.state.video_reported ? (
                              <div className='align-buttons'>
                                <button
                                  className='left'
                                  onClick={this.closeReportProblemModal}>
                                  Close
                                </button>
                                <span
                                  id='report_problem-wait'
                                  className='right display_none'>
                                  {" "}
                                  <img src={indicator_icon} />
                                </span>
                                <button
                                  id='report_problem'
                                  className='right'
                                  onClick={() => this.reportProblem()}>
                                  Submit
                                </button>
                              </div>
                            ) : (
                              <div className=''>
                                <button
                                  className='close-right'
                                  onClick={this.closeReportProblemModal}>
                                  Close
                                </button>
                              </div>
                            )}
                          </AddModal>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
}
VideoSubscription.contextType = SglContext;