import { useEffect, useState, useContext } from 'react';
import VideoPlayer from '../VideoPlayer';
import ButtonLayout from './button_layout';
import Carousel from './carousel';
import './../styles/single.css'
import SglContext from '../common/SglContext';
import "../styles/loginPopup.css";
import Loginform from "../common/loginForm";
import SubscribeDialog from '../videos/subscribeDialog';
import axios from 'axios';
import moment from 'moment';
import { env } from '../env';

function format_time(timeString) {
  var H = +timeString.substr(0, 2);
  var h = H % 12 || 12;
  var ampm = (H < 12 || H === 24) ? " AM" : " PM";
  timeString = h + timeString.substr(2, 3) + ampm;
  return timeString;
}

function SingleVideo(props) {
  const [dataset, setDataset] = useState(props.dataSet)
  const [video_asset, setVideoAsset] = useState([])
  const [related_assets, setRelatedAssets] = useState([])
  const [live_upnext, setLiveNowUpNext] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [width, setWidth] = useState(Number(window.innerWidth));
  const [updated, setUpdated] = useState(0)
  // Detect Mobile View
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const notMobile = width > 768;

  // GEG specific layout settings
  const theme = (typeof (dataset.theme) != 'undefined' && dataset.theme != '') ? dataset.theme.toLowerCase() : "";
  const video_title = (typeof (dataset.video_title) != 'undefined' && dataset.video_title != '' && dataset.video_title != 'false') ? dataset.video_title : "";
  const logged_in = true;

  // these states will handle modal open/close behaviour for Login Form
  const handleLoginShow = () => {setActivePopup('Login')};
  const handleRegisterShow = () => {setActivePopup('Register')};
  const handleSubscribeShow = () => {setActivePopup('Subscribe')};
  const handleClose = () => {setActivePopup(null)};
  const [activePopup, setActivePopup] = useState("");
  const Sgl = useContext(SglContext);
  
  function logout(e) {
    localStorage.removeItem("access_token");
    Sgl.logOutUser(Sgl.refetchVideoAsset);
  }

  useEffect(() => {
    setVideoAsset(props.video_asset)
    setRelatedAssets(props.related_assets)
    setLiveNowUpNext(props.live_upnext)
    setLoaded(true);
  }, [props]);

  useEffect(() => {
    if ( props.video_asset.type == 'Ring Stream Configuration' || true) {
      if ( props.video_asset.do_login ) {

        if (!Sgl.current_user) {
          setTimeout(() => {handleLoginShow()}, 500)
        } else {
          handleClose()
        }
      } else {
        if ( props.video_asset.do_subscription ) {
          checkSubscription()
        }
      }
    }
  }, [props.video_asset.do_subscription, props.video_asset.do_login, updated]);

  function checkSubscription() {
    axios.get(env.REACT_APP_API_URL + "/video-assets/checkSubscription", {
      params:video_asset
    }).then((response) => {
      if ( !response.data.channel_subscribed ) {
        setActivePopup('Subscribe')
      } else {
        handleClose()
      }
    })
  }

  return (
    <>
    {/* <button onClick={handleSubscribeShow}>Subscribe</button> */}
      <section className={"sgl-single-video " + theme}>

        <div className="sgl-single-video-wrapper">
          { (props.video_asset.type != 'Uploaded Videos' && (document?.sgl_plugin_page != 1 || document?.sgl_plugin_page != '1' )) &&
          <div className="sgl-login-bar">
            <ul className="sgl-login-bar-inner">
              {
                Sgl.current_user ?
                (
                  <>
                    <li>
                      {`${Sgl.current_user.full_name}`}
                    </li>

                    {
                      ( props.video_asset.do_subscription ) ? 
                      <li>
                        <a href="javascript:void(0);" target="_self" onClick={handleSubscribeShow}>SUBSCRIBE</a>
                      </li>
                      :
                      null
                    }
                    
                    <li>
                      <a href="javascript:void(0);" target="_self" onClick={logout}>LOGOUT</a>
                    </li>
                  </>
                )
                :
                (
                  <>
                    <li>
                        <a href="javascript:void(0);" target="_self" className='alt' onClick={handleLoginShow} >LOGIN</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" target="_self" onClick={handleRegisterShow}>REGISTER</a>
                    </li>
                  </>
                )
              }
              
            </ul>
          </div>
          }
          <Loginform active={activePopup} handleClose={handleClose} />
          <SubscribeDialog video_asset={props.video_asset} active={activePopup} dataSet={dataset} embed_id={props.embed_id} handleClose={handleClose} handleSubscribeSuccess={Sgl.refetchVideoAsset}/>
          { (( notMobile) || (isMobile && Sgl.current_user && activePopup !== "Subscribe" ) || activePopup == null || activePopup == '' ) && 
          (
          <>
          <div style={{width:'65%'}} className="sgl-column">
            {
              <div key={video_asset.sgl_id} className="sgl-row">
                {
                  (video_asset.type == 'Uploaded Videos' || video_asset.type == 'rvs') && (!video_asset.do_login && !video_asset.do_subscription) &&
                  <div className="sgl-img">
                    <VideoPlayer video_asset={video_asset} />
                  </div>
                }

                {
                  (
                    video_asset.event_started == '0' ||
                    (video_asset.event_ended == '1' && video_asset.archive_url == '') ||
                    (video_asset.event_started == '1' && video_asset.event_ended == '0' && video_asset.video_url == '') ||
                    (video_asset.do_login || video_asset.do_subscription)
                  ) && (video_asset.type == 'Live Stream' || video_asset.type == 'Ring Stream Configuration' || video_asset.type == 'rvs') &&
                  <div className="sgl-img" onClick={() => {setUpdated(Date.now())}}>
                    <img src={video_asset.placeholder_image != '' ? video_asset.placeholder_image : "https://showgroundslive.com/images/placeholder_video.jpg"} />
                  </div>
                }

                {
                  video_asset.event_started == '1' && video_asset.event_ended == '0' && loaded && (video_asset.type == 'Live Stream' || video_asset.type == 'Ring Stream Configuration') &&
                  <div>
                    <VideoPlayer video_asset={video_asset} />
                  </div>
                }

                {
                  video_asset.event_ended == '1' && loaded && video_asset.archive_url != '' && (video_asset.type == 'Live Stream' || video_asset.type == 'Ring Stream Configuration') &&
                  <div>
                    <VideoPlayer video_asset={video_asset} />
                  </div>
                }
              </div>
            }
          </div>

          <div className="sgl-video-details">

            <div className='sgl-details'>

              <div className="left" style={ !video_asset.description ? { width:'100%'}:{} }>
                {
                  ( (video_asset.type == "Ring Stream Configuration" || (video_asset.type == "Live Stream" )) && video_asset.event_started == '1' && video_asset.event_ended != '1') &&
                  <div className="sgl-video-status">
                    <span className="sgl-indicator"></span>LIVE
                  </div>
                }

                { props.video_asset.wp_asset == 0 &&
                  <div className="sgl-video-title">{video_asset.title}</div>   
                }

                { props.video_asset.wp_asset == 0 &&
                <div className="sgl-date">
                  {
                    (video_asset.type == "Live Stream" && video_asset.event_started == '0') ?
                      <span>Stream starts on {video_asset.friendly_date_time}</span>
                    :
                    (video_asset.type == "Ring Stream Configuration") ?
                      <span>{moment().format('D MMMM YYYY')}</span>
                    :
                      <span>{video_asset.date_time}</span>
                  }
                </div>
                }

                {
                  video_asset.type == "Live Stream" && video_asset.current_date && video_asset.event_started=='1' && video_asset.event_ended != '1' &&
                  <a href="/watch/live-streams/" className="sgl-back-livestream">Back to Live Streams</a>
                }
              </div>
              
              { 
                (video_asset.description) &&
                <div className='right'>
                    <div className="sgl-video-description">{video_asset.description}</div>
                </div>
              }
            </div>

          </div>
          </>
          )
        }
        </div>


        {/* {
          loaded && related_assets && related_assets.list.length > 0 &&
          <div className="sgl-related-content" style={{ maxWidth: video_width }}>
            {
              related_assets.meta.type != "archivestreams" ?
                (<ButtonLayout dataSet={related_assets.meta} embed_id={props.embed_id} video_assets={related_assets} />)
                :
                (<Carousel dataSet={dataset} embed_id={props.embed_id} video_assets={related_assets} />)
            }
          </div>
        }
        {
          loaded && video_asset.sponsors &&
          <div className="sgl-related-sponsors">
            {
              video_asset.sponsors.map((sponsor) => {
                return (
                  sponsor.sponsor_logo &&
                  (<div class="sgl-sponsor"><a target="_blank" href={sponsor.sponsor_site_url}><img src={sponsor.sponsor_logo} /></a></div>)
                )
              })
            }
          </div>
        }  */}
      </section>
    </>
  );
}

export default SingleVideo;
