import React from "react";
import { useState, useEffect, useContext } from "react";
import indicator_icon from "../images/Indicator.gif";
import axios from "axios";
import SglContext from "./SglContext";
import { Link } from "react-router-dom";
import { env } from "../env";

//API END POINTS
const sgl_webservice_process_forgot_password =
  env.REACT_APP_SGL_API +
  "/iphone.php/webservice/ProcessForgotPassword";

function ForgotPasswordForm(props) {
  const [emailAddress, setEmailAddress] = useState("");
  const [successMsg, setSuccessMessage] = useState(false);
  const Sgl = useContext(SglContext);
  const css =
    ".sgl-plugin-page #sgl-content-wrapper{padding-bottom: 20px;max-width: 600px;margin: 0px;}";

  useEffect(() => {
    if(props.isMobile){
      let popup_card_header = document.getElementById("popup-card-header");
      popup_card_header.style.display = "none";
    }
  }, [props.isMobile]);
  
  useEffect(() => {
    let sgl_plugin_form = document.getElementById("sgl-plugin-form");
    sgl_plugin_form.style.display = "block";

    let sgl_plugin_success_message = document.getElementById(
      "sgl-plugin-success-message"
    );
    sgl_plugin_success_message.style.display = "none";

    let please_wait = document.getElementById("please-wait");
    please_wait.style.display = "none";
  }, []);

  function processForgotPassword() {
    let email = document.getElementById("email");
    let sgl_plugin_form = document.getElementById("sgl-plugin-form");
    let sgl_plugin_success_message = document.getElementById(
      "sgl-plugin-success-message"
    );
    let errorContainer = document.getElementById("reg_error_summary");

    errorContainer.innerHTML = "";
    errorContainer.style.display = "none";
    // this.setState({ email_address: email.value });
    setEmailAddress(email.value);

    let please_wait = document.getElementById("please-wait");
    please_wait.style.display = "block";

    axios
      .post(sgl_webservice_process_forgot_password, null, {
        params: {
          email: email.value,
          customer_id: Sgl.company_id,
          site_url: `${window.location.protocol}//${window.location.host}${(Sgl.basePath && Sgl.basePath != '/' && Sgl.basePath != '')?`${Sgl.basePath}`:"/showgrounds"}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          sgl_plugin_form.innerHTML = "";
          sgl_plugin_form.style.display = "none";
          sgl_plugin_success_message.style.display = "block";
          please_wait.style.display = "none";
          setSuccessMessage(true);
        } else {
          errorContainer.innerHTML = response.data.message;
          errorContainer.style.display = "block";
          please_wait.style.display = "none";
        }
      });
  }

  return (
    <div className='popup-card-deck' style={{ padding: "0px 0px" }}>
      <div class='popup-card-item'>
        <div class='bar'> &nbsp; </div>
        <div class='popup-card' style={{'background':'white'}}>
          {/* HEADER */}
          <div class='popup-card-header' id='popup-card-header'>
            <h3 class='popup-card-title'>
              Forgot Password
            </h3>
            <div style={{ textAlign: "center", fontSize: "12px" }}>
              {process.env.REACT_APP_CREDIT_TEXT}{" "}
              <Link to={process.env.REACT_APP_CREDIT_URL}>
                {" "}
                {process.env.REACT_APP_CREDIT_COMPANY}
              </Link>
            </div>
          </div>
          {/* END HEADER */}

          {/* BODY */}

          <div
            class='popup-card-body'
            id='sgl-plugin-form'
            // style={{marginTop: "15px"}}
          >
            {/* form start */}

            <div className='div-responsive'>
              <div className='forgot-password-container'>
                <div className='fp_message'>
                  <div>
                    <p>
                      <b>RESET PASSWORD</b>
                      <br />
                      Please type your Email / User Name to reset your password.
                    </p>
                  </div>
                </div>

                <input
                  type='text'
                  placeholder='Email / User Name'
                  name='email'
                  id='email'
                  className='forgotpw'
                />

                <div className='error_container'>
                  <div>
                    <p
                      className='error_summary reg_error_summary'
                      id='reg_error_summary'></p>
                  </div>
                </div>

                <div
                  // className='full'
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}>
                  <input
                    type='button'
                    id='update'
                    className='btn'
                    onClick={processForgotPassword}
                    value='Submit'
                  />
                  &nbsp; &nbsp;
                  <input
                    type='button'
                    className='cancelBtn'
                    value='Cancel'
                    onClick={props.handleForgotPasswordClose}
                  />
                  &nbsp; &nbsp;
                  <span id='please-wait'>
                    <img src={indicator_icon} /> Verifiying...
                  </span>
                </div>
              </div>
              <div className='copyright-text' style={{ marginTop: "20px" }}>
                {process.env.REACT_APP_COPYRIGHT_TEXT}
              </div>
            </div>

            {/* form end */}
          </div>
          {/* END BODY */}
          <div id='sgl-plugin-success-message'>
            <p style={{ background: "#fff", color: "green", textAlign: "center" }}>
              <b>Email Sent</b>
              <br />
              An email containing a link to reset your password has been sent to{" "}
              {emailAddress}. Please check your email.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
// ReactDOM.render(<ForgotPasswordForm />, document.getElementById("sgl-content-wrapper"));
export default ForgotPasswordForm;
