import React from "react";
import { useState, useEffect, useContext } from "react";
import ReactModal from "react-modal";
import SglContext from "../common/SglContext";
import VideoSubscription from "./VideoSubscription";

export default function SubscribeDialog(props) {
  const [is_open_do_subscription_modal, setIs_open_do_subscription_modal] =
    useState(false);
  const Sgl = useContext(SglContext);
  const [active, setActive] = useState(props.active);
  const [width, setWidth] = useState(Number(window.innerWidth));

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const notMobile = width > 768;

  useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  function closeDialogs() {
    props.handleClose();
  }

  function playVideoAfterSubscribe() {
    props.handleSubscribeSuccess();
  }

  const SubscribeModalStyles = {
    content: {
      border: "1px solid #ccc",
      borderRadius: "4px",
      left: "50%",
      maxHeight: "90%",
      outline: "none",
      overflow: "auto",
      position: "fixed",
      top: "50%",
      transform: "translate(-50%,-50%)",
      maxWidth: "750px",
      width: "60%",
    },
    overlay: { zIndex: 1000 },
  };

  return (
    <>
    {active === "Subscribe" && notMobile ? (
      <div >       
        <ReactModal
          isOpen={active === 'Subscribe'}
          onRequestClose={closeDialogs}
          className="mymodal modal-width-small"
          style={SubscribeModalStyles}
          >

        {/* For Channel Subscription */}
          <VideoSubscription
            is_download_subscription={false}
            is_video_subscription={( props.video_asset.type == "rvs" )}
            subscription_type={ ( props.video_asset.type == "rvs" )?"rvs":"channel"}
            video_to_play={{ videos_sgl_id: ( props.video_asset.type == "rvs" ) ? props.video_asset.sgl_id : -1 }}
            closeSubscriptionModal={closeDialogs}
            playVideoAfterSubscribe={playVideoAfterSubscribe}
            company_id={Sgl.company_id}
          />

            {/*  For Video Subscription */}
            {/* <VideoSubscription
            is_download_subscription={false}
            is_video_subscription={false}
            subscription_type={"video"}
            video_to_play={{ videos_sgl_id: -1 }}
            closeSubscriptionModal={closeDoSubscriptionModal}
            playVideoAfterSubscribe = {playVideoAfterSubscribe}
          /> */}
          </ReactModal>
        </div>
      ) : active === "Subscribe" && isMobile ? (
        <VideoSubscription
          is_download_subscription={false}
          is_video_subscription={( props.video_asset.type == "rvs" )}
          subscription_type={ ( props.video_asset.type == "rvs" )?"rvs":"channel"}
          video_to_play={{ videos_sgl_id: ( props.video_asset.type == "rvs" ) ? props.video_asset.sgl_id : -1 }}
          closeSubscriptionModal={closeDialogs}
          playVideoAfterSubscribe={playVideoAfterSubscribe}
          company_id={Sgl.company_id}
          isMobile={isMobile}
        />
      ) : (
        ""
      )}
    </>
  );
}
