import './App.css';
import axios from 'axios'
import ReactModal from 'react-modal';
import Pusher from 'pusher-js';
import ReactGA from "react-ga4";
import moment from 'moment';


import md5 from "md5";
import SglContext from "./common/SglContext";
import { useEffect, useState, useMemo, useRef } from 'react';
import VideoPlayer from './VideoPlayer';
import HeroArea from './views/hero_area';
import Carousel from './views/carousel';
import SingleVideo from './views/single';
import ButtonLayout from './views/button_layout';
import Grid from './views/grid';
import SubscribeDialog from './videos/subscribeDialog';
import LiveClassDetail from './liveclass/detail';
import './styles/styles.css'
import AllVideos from './videos/allVideos';
import ViewStream from './views/view_stream';
import { env } from './env';

axios.defaults.headers.common['SGL-REQUEST-ORIGIN'] = 'SGL-API';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.defaults.headers.common['Pragma'] = 'no-cache';
axios.defaults.headers.common['Expires'] = '0';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  try{
    let token = localStorage?.getItem("access_token")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
  }catch(e){

  }
  let token = localStorage?.getItem("access_token")
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  let json_data = error.toJSON();
  if (json_data.status !== 401) {
  }

  if (error.response && error.response.data) {
    if ( error.response.data.statusCode === 401 ) {
      // send to login form
    }
  }
  return Promise.reject(error);
});


function App(props) {
  const [basePath, setBasePath] = useState("");
  const [media_id, setMediaId] = useState(0)
  const [media_type, setMediaType] = useState("")
  const [playlist, setPlaylist] = useState(0)
  const [video_assets, setVideoAssets] = useState([])
  const [modalIsOpen, setIsOpen] = useState(false)
  const [nowPlaying, setNowPlaying] = useState({})
  const [update_time, setUpdateTime] = useState(0)
  const [loaded, setLoaded] = useState(false);
  const [current_user, setCurrentUser] = useState(false);
  const [company_id, setCompanyId] = useState("-1");
  const [company_urlpath, setCompanyUrlPath] = useState("esp");
  const [company_name, setCompanyName] = useState("");
  const [new_dataset, setNewDataSet] = useState("");
  const [dataset, setDataset] = useState({});
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [unauthorizedMsg, setUnauthorizedMsg] = useState('')
  const [channel_type, setChannelType] = useState('')
  //var dataset = JSON.parse(props.dataset);
  useEffect(() => {
    setDataset(JSON.parse(props.dataset))
  }, [])
 
  // GEG specific layout settings
  const theme = (typeof(dataset.theme) != 'undefined' && dataset.theme != '') ? dataset.theme.toLowerCase() : "";
 
  const modalStyles = {
    content: {
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      padding: '0px',
      margin: '0px',
    },
    overlay: { zIndex: 1000 }
  };

  function getQueryVariable(variable)
  {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] === variable){return pair[1];}
    }
    return(false);
  }

  function openVideo(video_asset) {

    if ( video_asset.event_started == '1' || video_asset.event_ended == '1' ) {
      setNowPlaying(video_asset);
      setIsOpen(true);
    }
  }

  function closeVideo() {
    setIsOpen(false);
  }

  useEffect(() => {
    let pathname
    let bp = ''
 
    axios.get( `${env.REACT_APP_API_URL}/video-assets/getBasePath`, {
       
      // params: props.dataset,
 
    }).then( ( response ) => {
     
      if((dataset.media_id && dataset.media_id > 0 )|| (dataset.media_type && dataset.media_type !== '') ||  (dataset.playlist && dataset.playlist > 0))
      {
        let dataUpdated = false

        if(dataset.media_id > 0 && typeof(dataset.media_id) !== 'undefined')
        {
          setMediaId(dataset.media_id)
          dataUpdated = true
        }

        if(dataset.media_type !== '' && typeof(dataset.media_type) !== 'undefined')
        {
          setMediaType(dataset.media_type)
          dataUpdated = true
        }

        if(dataset.playlist > 0 && typeof(dataset.playlist) !== 'undefined')
        {
          setPlaylist(dataset.playlist)
          dataUpdated = true
        }

        if(dataUpdated)
        {
          setUpdateTime(Date.now())
        }
      }
      // Get the destination basepath if exist to remove from location.pathname
        if(response.status == 200)
        {
          bp = response.data?.destination_basepath?.length > 0 && response.data?.destination_basepath
          // Check if basepath is present or not
          if(bp?.length > 0)
          {
            pathname = window.location.pathname.replace(bp, "").split("/")

          }else{
            pathname = window.location.pathname.split("/");
          }
    
        // IF ITS A VIDEO DETAIL PAGE
        const sgl_plugin_endpoint = (document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1) &&  pathname[1].toLowerCase() == 'video-detail'
        if (pathname.length > 2 && (pathname[1].toLowerCase() == 'video' || sgl_plugin_endpoint) && pathname[2] != '') {
          // IF ITS RVS VIDEO DETAIL
          if ( pathname.length > 3 && pathname[2] == 'rider-clip' && pathname[3] != '' ) {
            setMediaId(pathname[3])
            setMediaType('rvs')
            setDataset(dataset => {
              return {...dataset, media_id: pathname[3], media_type:'rvs'}
            })
    
            // DO WE HAVE PLAYLIST ID
            if ( pathname.length > 6 && pathname[5] == 'playlist' && pathname[6] != '' ) {
              setPlaylist(pathname[6])
              setDataset(dataset => {
                return {...dataset, playlistID: pathname[6]}
              })
              
            }
    
          } else {
    
            // THIS IS VIDEO ASSET DETAIL
            setMediaId(pathname[2])
            setMediaType('video_asset')
            // dataset.media_id = pathname[2]
            // dataset.media_type = 'video_asset'
            setDataset(dataset => {
              return {...dataset, media_id: pathname[2], media_type:'video_asset'}
            })
    
            // DO WE HAVE PLAYLIST ID
            if ( pathname.length > 5 && pathname[4] == 'playlist' && pathname[5] != '' ) {
              setPlaylist(pathname[5])
              setDataset(dataset => {
                return {...dataset, playlistID: pathname[5]}
              })
            }
    
          }
        }
        else if (pathname.length > 2 && pathname[1].toLowerCase() == 'video-all' && pathname[2] != '') {
          // ITS A VIDEO ALL PAGE
          setMediaId(0)
          setMediaType('video-all')
          setPlaylist(pathname[2])
          setDataset(dataset => {
            return {...dataset, media_id: 0, media_type:'video-all', playlistID: pathname[2]}
          })
    
        }
        else if ( dataset.media_id ) {
          // ITS DETAIL PAGE WITH MEDIA ID IN EMBED CODE
          setMediaId(dataset.media_id)
          setMediaType(dataset.media_type)
          setPlaylist(dataset.playlist)
    
        }
        setUpdateTime(Date.now())
      }
      } ); 

  },[window.location.pathname])

  /**
   * EFFECT TO FETCH DATA FROM SERVER
   */
  useEffect(() => {
    //SET MODEL ELEMENT
    ReactModal.setAppElement(document.getElementById(props.embed_id))
    if(update_time !== 0)
    {
      if((typeof(dataset.media_id) !== 'undefined' && dataset.media_id > 0) || (typeof(dataset.media_type)!== 'undefined'  && dataset.media_type !== '')   ||  (typeof(dataset.playlist) && dataset.playlist > 0) || dataset.type == "liveclassplayer" || dataset?.categories != '')
      {
          //SET MEDIA ID FOR DETAIL PAGE
        if ( dataset.playlistID > 0) {
          dataset.related_playlist_id = dataset.playlistID
        }else if( dataset.playlist > 0){
          dataset.related_playlist_id = dataset.playlist
        }

        if ( dataset.type == 'liveclassplayer') {
          dataset.videoAssetId = getQueryVariable('video_asset_id')
          dataset.company = getQueryVariable('company')
          dataset.classId = getQueryVariable('class_id')
        }

        if(dataset.type !== 'viewAll' && dataset.media_type !== 'video-all' )
        {
          let session_identifier = sessionStorage.getItem('session_identifier')
          if ( session_identifier ) {
            dataset.bsi = session_identifier
          }
      
          // FETCH DATA FROM API
          axios.get( `${env.REACT_APP_API_URL}/video-assets/playlist`, {

            params: dataset

          }).then( ( video_assets ) => {

            //SET DEFAULT THEME
            if (video_assets?.data?.dataset && typeof(video_assets?.data?.dataset?.theme) == 'undefined' || video_assets?.data?.dataset?.theme == '') {
              video_assets.data.dataset.theme = process.env.REACT_APP_THEME;
            }

            if(video_assets.data.redirect_to_video){
              if(video_assets.data.destination_basepath?.length > 0){
                window.location.href = video_assets.data.destination_basepath+"/video/"+video_assets.data.redirect_to_video;
              }else{
                window.location.href = "/video/"+video_assets.data.redirect_to_video;
              }
            
              return false;
            }
            video_assets.data.destination_basepath?.length > 0 && setBasePath(basePath => video_assets.data.destination_basepath)

            /* Check if colors should be inherited from wp-options, embed code or db */
            if (document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1) {
              // background color
              if(!dataset?.background_color && video_assets.data.dataset?.background_color == '#ffffff') {
                if (document?.sgl_plugin_colors?.primary_header_color_txt) {
                  video_assets.data.dataset.background_color = document?.sgl_plugin_colors?.primary_header_color_txt
                }
              }
              //foreground color
              if(!dataset?.foreground_color && video_assets.data.dataset?.foreground_color == '#000000') {
                if (document?.sgl_plugin_colors?.primary_header_color_bg) {
                  video_assets.data.dataset.foreground_color = document?.sgl_plugin_colors?.primary_header_color_bg
                }
              }
            } 

            setNewDataSet(video_assets.data.dataset);
            setVideoAssets ( video_assets.data )
            setIsAuthorized( true );
            setUnauthorizedMsg( '' );
            setCompanyId(video_assets.data.list.primary_customer_id)
            if (dataset.type == 'detail') {
              getUser();
            }
            switch(video_assets?.data?.dataset?.type) {
              case 'detail':
                if(video_assets?.data?.list?.wp_asset == 0){
                  document.title = `${video_assets?.data?.list?.title}${(video_assets?.data?.list?.type == 'Live Stream' || video_assets?.data?.list?.type == 'Ring Stream Configuration')?" - Live Stream":""}`
                }
                ReactGA.initialize([
                  {
                    trackingId: 'G-CLZYEE5BZJ', //sgl videos
                    gtagOptions: {send_page_view : true} 
                  }, 
                  {
                    trackingId: process.env.REACT_APP_COMPANY_TRACKING_ID, //COMPANY Tracking ID
                    gtagOptions: {send_page_view : true} 
                  },
                  {
                    trackingId: 'G-J4VWS1KD5R', //sgl v4
                    gtagOptions: {send_page_view : true} 
                  } 
                ])
            }
      

            var channel_type
            if(video_assets?.data?.dataset?.type !== 'manual')
            {
              channel_type = `sgl_videos_${video_assets?.data?.dataset?.type}${(video_assets?.data?.dataset?.company)?`_${video_assets?.data?.dataset?.company}`:""}${(media_id)?`_${media_id}`:""}`
            }else{
              channel_type = `sgl_videos_${video_assets?.data?.dataset?.type}${(video_assets?.data?.dataset?.playlist)?`_${video_assets?.data?.dataset?.playlist}`:""}${(video_assets?.data?.dataset?.company)?`_${video_assets?.data?.dataset?.company}`:""}${(media_id)?`_${media_id}`:""}`
            }

            if(typeof(channel_type) !== 'undefined')
            {
              setChannelType(channel_type)
            }
            setLoaded ( true );
          } ).catch(error => {
            if(error?.response?.data?.statusCode == 401){
              setIsAuthorized(false)
              setUnauthorizedMsg(error?.response?.data?.message)
            }
          });
        }
      }
    }
 
  }, [update_time]);
 
 
  useEffect(() => {
    

    if ( channel_type != '' && channel_type != null && channel_type != undefined ) {

      switch(video_assets?.dataset?.type) {
        case 'livestream':
        case 'ringlivestream':
        // case 'detail':
        console.log('created pusher')
          var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY);
          var channel = pusher.subscribe( channel_type );

          channel.bind('updateavailable', function(data) {
    
            setUpdateTime(Date.now())
            
          });    
          
      }

    }

  }, [channel_type])
 
  function refetchVideoAsset() {
    setUpdateTime(Date.now())
  }
 
  function getUser() {
    axios.get(env.REACT_APP_API_URL + "/users/getUser").then(
      (response) => {
        if (response.data.user && response.data.user != null) {
          var user_display_name =
            response.data.user.first + " " + response.data.user.last_name;
          var user_email = response.data.user.email_address;
          if (
            JSON.stringify(response.data.user) !== JSON.stringify(current_user)
          ) {
            setCurrentUser(response.data.user);
          }

          localStorage.setItem("user_display_name", user_display_name);
          localStorage.setItem("user_email", user_email);
        } else {
          setCurrentUser(false);
        }
      },
      (error) => {
        setCurrentUser(false);
      }
    );
  }

  function logOutUser(callback=null) {
    axios.get(env.REACT_APP_API_URL + "/auth/logout").then(
      (response) => {
        if (response.data.logout) {
          LogoutSGLSession();
        }
        getUser()
        if(callback) {
          callback()
        }
      }
    );
  }

  function LogoutSGLSession() {
    axios
      .post(
        env.REACT_APP_SGL_API +
          "/iphone.php/webservice/LogoutSGLAPIUser",
        {},
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        localStorage.removeItem("user_display_name");
        localStorage.removeItem("user_email");
        localStorage.removeItem("user_id");
        localStorage.removeItem("access_token");
        // window.location.reload();
      });
  }

  function checkLoginOnSGL() {
    let currenttime = Date.now();
    let hash = md5("IphoneWebserviceApi" + currenttime + "SGL");
    axios
      .post(
        env.REACT_APP_SGL_API + "/iphone.php/webservice/IsLoggedIn",
        {
          from_wp_api: true,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        if (!res.data.success) {
          loginOnSGL2();
        }
      });
  }

  function loginOnSGL(remember_me = false) {
    let currenttime = Date.now();
    let hash = md5("IphoneWebserviceApi" + currenttime + "SGL");
    axios
      .post(
        env.REACT_APP_SGL_API +
          "/iphone.php/" +
          company_urlpath +
          "/webservice/AuthenticateUserViaJWT",
        {
          token: localStorage.getItem("access_token"),
          datetimestamp: currenttime,
          hash: hash,
          customer_id: company_id,
          from_wp_api: true,
          remember_me: remember_me,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        var ref_url;
        // getUser();
        if (window.referrer != "") {
          // ref_url = new URL(window.referrer);
        }
        // if (
        //   localStorage.getItem("playing_video_id") &&
        //   window.referrer != "" &&
        //   localStorage.getItem("playing_video_url") == window.referrer
        // ) {
        //   navigate(window.referrer);
        // } else if (res.data.verify_mobile_url != "") {
        //   navigate(res.data.verify_mobile_url)
        // } else if (
        //   window.referrer &&
        //   window.referrer != "" &&
        //   window.referrer !=
        //   "https://" +
        //   window.location.hostname +
        //   "/forgot-password?forgot_password=1"
        // ) {
        //   navigate(window.referrer);
        // } else {
        //   navigate('/')
        // }
      });
  }

  function loginOnSGL2() {
    let currenttime = Date.now();
    let hash = md5("IphoneWebserviceApi" + currenttime + "SGL");

    axios
      .post(
        env.REACT_APP_SGL_API +
          "/iphone.php/" +
          company_urlpath +
          "/webservice/AuthenticateUserViaJWT",
        {
          token: localStorage.getItem("access_token"),
          datetimestamp: currenttime,
          hash: hash,
          from_wp_api: true,
          check_already_logged_in: true,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        if (res.data.is_login_done) {
          window.location.reload();
        } else {
          if (!res.data.success) {
            axios.get(env.REACT_APP_API_URL + "/auth/logout").then(
              (response) => {
                if (response.data.logout) {
                  LogoutSGLSession();
                }
              },
              (error) => {}
            );
          }
        }
      });
  }
  
  if (dataset.type == 'viewAll' && dataset.playlistID) {
    return (
      <AllVideos plid={dataset.playlistID} dataSet={dataset} />
    )
   
  }

  /**
   * RENDER ERROR WHEN NOT AUTHORIZED
   */
  if ( !isAuthorized ) {
    return (<div className='unauthorized'>{unauthorizedMsg}</div>)
  }
  /**
   * RENDER NOTHING WHEN NOT LOADED
   */
  if ( !loaded ) {
     return (<></>)
  }

  /**
   * RENDER NOTHING WHEN NO VIDEO FOUND
   */
  if(!video_assets.list) {
    // return (<></>)
  }

  /**
   * RENDER FOR DETAIL PAGE
   */
  if (dataset.type == 'detail' && (media_id || dataset.media_id)) {
    return (
      <SglContext.Provider
      value={{
        current_user,
        company_id,
        company_name,
        company_urlpath,
        logOutUser,
        getUser,
        checkLoginOnSGL,
        loginOnSGL,
        loginOnSGL2,
        refetchVideoAsset
    }}>
      <SingleVideo dataSet={new_dataset} embed_id={props.embed_id} video_asset={video_assets.list} related_assets={video_assets.related_items} live_upnext={video_assets.live_upnext} />
    </SglContext.Provider>

    )
  }



  /**
   * RENDER FOR RELATED VIDEOS
   */
   if (dataset.type == 'related_videos' && media_id) {
    if(new_dataset.type == "ringlivestream"){
      // sending flag to indicate that component is being rendered for related videos
      new_dataset.related_videos = true
      return(
        <ButtonLayout dataSet={new_dataset} embed_id={props.embed_id} video_assets={video_assets.related_items} openVideo={openVideo} basePath={basePath}/>
      )
    }else{
      return (
        <Carousel dataSet={new_dataset} embed_id={props.embed_id} video_assets={video_assets.related_items} openVideo={openVideo} update_time={update_time} basePath={basePath}/>
      )
    }
   
  }
 
  /**
   * RENDER FOR LISTS
   */
  if(dataset.type == "live_class"){
    if(video_assets.list.class_id > 0){
      return(
        <SglContext.Provider
        value={{
          company_id,
          company_urlpath,
      }}>
        <LiveClassDetail cid={video_assets.list.class_id} sid={video_assets.list.show_id}/>
      </SglContext.Provider>
      )
    }  
  }
 
  if(dataset.type == "sponsors"){
    if(video_assets.list.sponsors){
      return(
      <div className="sgl-single-video">
        <div className="sgl-related-sponsors" style={{ gridTemplateColumns: video_assets.list.sponsors?.length <= 2 ? `repeat(${video_assets.list.sponsors?.length}, 1fr)` : '' }}>
        {
          video_assets.list.sponsors.map((sponsor) => {
            return (
              sponsor.sponsor_logo &&
              (<div class="sgl-sponsor"><a target="_blank" href={sponsor.sponsor_site_url}><img src={sponsor.sponsor_logo} /></a></div>)
            )
          })
        }
      </div>
      </div>
      )
    }
  }
 
  /**
   * RENDER FOR Player
   */
  if(dataset.type == "liveclassplayer"){
    if(video_assets?.list){
      return(<ViewStream video_asset={video_assets.list} />)
    }
  }
 
  return (
    (loaded) &&
    <>
      <style>
          {
            new_dataset?.background_color && (
              `#${props.embed_id} .sgl-hero-area.geg {\
                background-color:${new_dataset.background_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg {\
                background-color:${new_dataset.background_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg .sgl-videos-scroller-box::after, #${props.embed_id} section.sgl-videos-scroller.geg .sgl-videos-scroller-box::before {\
                background-color:${new_dataset.background_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg .owl-theme .owl-controls button:hover{\
                background: ${new_dataset.background_color} !important;\
              }\
              #${props.embed_id} .sgl-button-layout{\
                background-color:${new_dataset.background_color};\
              }\
              #${props.embed_id} .sgl-hero-area.coth {\
                background-color:${new_dataset.background_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.coth {\
                background-color:${new_dataset.background_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.coth .sgl-videos-scroller-box::after, #${props.embed_id} section.sgl-videos-scroller.coth .sgl-videos-scroller-box::before {\
                background-color:${new_dataset.background_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.coth .owl-theme .owl-controls button:hover{\
                background: ${new_dataset.background_color} !important;\
              }\
              #${props.embed_id} .sgl-hero-area {\
                background-color: ${new_dataset.background_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg .sgl-see-all {\
                color:${new_dataset.background_color}\
              }\
              `
            )
          }
          {
            new_dataset?.foreground_color && (
              `#${props.embed_id} .sgl-hero-area.geg .sgl-column h1 {\
                  color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} .sgl-hero-area.geg .sgl-column .sgl-row p {\
                  color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg .owl-theme .owl-controls button:hover{\
                color: ${new_dataset.foreground_color} !important;\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg .sgl-title-section h1 span {\
                color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg .sgl-standard-post .sgl-post-content h2 a {\
                  color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg .sgl-standard-post .sgl-post-content .sgl-carousel-text {\
                  color:${new_dataset.font_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg .owl-theme .owl-controls button {\
                color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg .owl-theme .owl-controls button {\
                border: 3px solid ${new_dataset.foreground_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg .owl-theme .owl-controls button:hover{\
                border: 1px solid ${new_dataset.foreground_color} !important;\
              }\
              #${props.embed_id} .sgl-button-layout{\
                color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} .sgl-button-layout .card-deck .card-item .card .card-title a{\
                color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} .sgl-hero-area.coth .sgl-column h1 {\
                color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} .sgl-hero-area.coth .sgl-column .sgl-row p {\
                  color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.coth .owl-theme .owl-controls button:hover{\
                color: ${new_dataset.foreground_color} !important;\
              }\
              #${props.embed_id} section.sgl-videos-scroller.coth .sgl-title-section h1 span {\
                color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.coth .sgl-standard-post .sgl-post-content h2 a {\
                  color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.coth .sgl-standard-post .sgl-post-content .sgl-carousel-text {\
                  color:${new_dataset.font_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.coth .owl-theme .owl-controls button {\
                color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.coth .owl-theme .owl-controls button {\
                border: 3px solid ${new_dataset.foreground_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.coth .owl-theme .owl-controls button:hover{\
                border: 1px solid ${new_dataset.foreground_color} !important;\
              }\
              #${props.embed_id} .sgl-button-layout .card-deck .card-item .bar {\
                background-color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} .sgl-button-layout .card-deck .card-item .card .card-footer .btn {\
                background-color:${new_dataset.foreground_color};\
              }\
              #${props.embed_id} .sgl-button-layout .card-deck .card-item .card {\
                border: 2px solid ${new_dataset.foreground_color};\
              }\
              #${props.embed_id} .sgl-hero-area .sgl-heading h1 {\
                color: ${new_dataset.foreground_color};\
              }\
              #${props.embed_id} .sgl-hero-area .sgl-column p {\
                color: ${new_dataset.foreground_color};\
              }\
              #${props.embed_id} section.sgl-videos-scroller.geg .sgl-see-all {\
                background-color: ${new_dataset.foreground_color};\
              }\
              `
            )
          }
      </style>
 
      <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeVideo}
          style={modalStyles}
          contentLabel="Livestream"
        >
      <div className={"jw-player-wrapper " + theme}>
        <a onClick={closeVideo} className="sgl-close-modal">
          <img className="cross-btn-img" src="https://showgroundslive.com/wp-content/plugins/ShowgroundsLive/images/close.png" />
        </a>
        <VideoPlayer video_asset={nowPlaying}/>
      </div>
      </ReactModal>
 
      {
        new_dataset.style == "loginpopup" ?
        <SglContext.Provider
          value={{
          company_id ,
          }}>
              <SubscribeDialog dataSet={new_dataset} embed_id={props.embed_id} />
        </SglContext.Provider> : null
      }
 
      {
        new_dataset.style == "hero_area" ?
        <HeroArea dataSet={new_dataset} embed_id={props.embed_id} video_assets={video_assets} openVideo={openVideo} basePath={basePath}/>
        : null
      }
 
      {
        new_dataset.style == "carousel" ?
        <Carousel dataSet={new_dataset} embed_id={props.embed_id} video_assets={video_assets} openVideo={openVideo} update_time={update_time} basePath={basePath}/>
        : null
      }

      {
        new_dataset.style == "button_layout" ?
        <ButtonLayout dataSet={new_dataset} embed_id={props.embed_id} video_assets={video_assets} openVideo={openVideo} basePath={basePath}/>
        : null
      }
 
      {
        new_dataset.style == "grid" ?
        <Grid dataSet={new_dataset} embed_id={props.embed_id} video_assets={video_assets} openVideo={openVideo} basePath={basePath}/>
        : null
      }
 
    </>
  );
}
 
export default App;
 
 
 
 
  // CALCULATE PATH AND MEDIA ID FROM URL OR DATASET
  // let [media_id, media_type, plid] = useMemo( () => {
  //   console.log("Inside useMemo")
  //   let media_id = 0
  //   let media_type = ''
  //   let playlist = 0
  //   let pathname
 
  //   // Check if basepath is present or not
  // if(basePath?.length == 0)
  // {
  //   // // Check if the location.pathname startWith the db basepath
  //   // if(window.location.pathname.startsWith(basePath))
  //   // {
  //   //   // Remove the basepath from location.pathname
  //   //   console.log("Pathname2: ", window.location.pathname.replace(basePath, ""))
  //   // }
  //   pathname = window.location.pathname.replace(basePath, "").split("/")
  // }else{
  //   pathname = window.location.pathname.split("/");
  // }
  //   // IF ITS A VIDEO DETAIL PAGE
  //   if (pathname.length > 2 && pathname[1].toLowerCase() == 'video' && pathname[2] != '') {
 
  //     // IF ITS RVS VIDEO DETAIL
  //     if ( pathname.length > 3 && pathname[2] == 'rider-clip' && pathname[3] != '' ) {
 
  //       media_id = pathname[3]
  //       media_type = 'rvs'
 
  //       // DO WE HAVE PLAYLIST ID
  //       if ( pathname.length > 6 && pathname[5] == 'playlist' && pathname[6] != '' ) {
  //         playlist = pathname[6]
  //       }
 
  //     } else {
 
  //       // THIS IS VIDEO ASSET DETAIL
  //       media_id = pathname[2]
  //       media_type = 'video_asset'
 
  //       // DO WE HAVE PLAYLIST ID
  //       if ( pathname.length > 5 && pathname[4] == 'playlist' && pathname[5] != '' ) {
  //         playlist = pathname[5]
  //       }
 
  //     }
 
  //   }
  //   else
  //   if (pathname.length > 2 && pathname[1].toLowerCase() == 'video-all' && pathname[2] != '') {
 
  //     // ITS A VIDEO ALL PAGE
  //     media_id = 0
  //     media_type = 'video-all'
  //     playlist = pathname[2]
 
  //   }
  //   else
  //   if ( dataset.media_id ) {
 
  //     // ITS DETAIL PAGE WITH MEDIA ID IN EMBED CODE
  //     media_id = dataset.media_id
  //     media_type = dataset.media_type
  //     playlist = dataset.playlist
 
  //   }
 
  //   return [media_id, media_type, playlist]
 
  // }, [window.location.pathname, basePath]);
 

