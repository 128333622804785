import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import SglContext from "./SglContext";
import RegistrationForm from "./registrationForm";
import ForgotPasswordForm from "./forgotPasswordForm";
import ReactModal from "react-modal";
import "../styles/loginPopup.css";
import { env } from "../env";

const auth_url = env.REACT_APP_API_URL + "/auth/login";
const sgl_webservice_resend_confirmation =
  env.REACT_APP_SGL_API + "/iphone.php/webservice/ResendConfirmation";
const sgl_webservice_login_error_log =
  env.REACT_APP_SGL_API + "/iphone.php/webservice/LoginErrorLog";

const LoginModalStyles = {
  content: {
    border: "1px solid #ccc",
    borderRadius: "4px",
    left: "50%",
    outline: "none",
    overflow: "auto",
    position: "fixed",
    top: "50%",
    transform: "translate(-50%,-50%)",
    maxWidth: "600px",
    width: "480px",
    overflow: "auto",
  },
  overlay: { zIndex: 1000 },
};
const ForgotPasswordModalStyles = {
  content: {
    border: "1px solid #ccc",
    borderRadius: "4px",
    left: "50%",
    outline: "none",
    overflow: "auto",
    position: "fixed",
    top: "50%",
    transform: "translate(-50%,-50%)",
    maxWidth: "600px",
    width: "50%",
    overflow: "auto",
  },
  overlay: { zIndex: 1000 },
};
const RegistrationModalStyles = {
  content: {
    border: "1px solid #ccc",
    borderRadius: "4px",
    maxHeight: "90%",
    left: "50%",
    outline: "none",
    overflow: "auto",
    position: "fixed",
    top: "50%",
    transform: "translate(-50%,-50%)",
    width: "50%",
    overflow: "auto",
  },
  overlay: { zIndex: 1000 },
};

export default function Loginform(props) {
  const [username, setUsername] = useState(localStorage.getItem("username"));
  const [passowrd, setPassword] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [email, setEmail] = useState("");
  const [login_id, setLoginId] = useState("");
  const [secret, setSecret] = useState("");
  const [company_name, setCompanyName] = useState("");
  const Sgl = useContext(SglContext);
  const [active, setActive] = useState(props.active);

  const [width, setWidth] = useState(Number(window.innerWidth));

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const notMobile = width > 768;

  function getUserInfo() {
    let sgl_plugin_form = document.getElementById("sgl-plugin-form");

    let username = localStorage.getItem("username");
    if (username) {
      setUsername(username);
    }

    if (Sgl.current_user && Sgl.current_user != null) {
      // sgl_plugin_form.style.display = "block";
      setShowLoginForm(false);
      setUserData(Sgl.current_user);
    } else {
      setShowLoginForm(true);
    }
  }

  useEffect(() => {
    getUserInfo();
    setUserData(Sgl.current_user);
  }, [Sgl.current_user]);

  useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  function closeDialogs() {
    props.handleClose();
  }

  function login(e) {
    let username = document.getElementById("username").value;
    let password = document.getElementById("password").value;
    let remember_me = document.getElementById("remember_me").checked
      ? "yes"
      : "no";
    authenticateUser(username, password, remember_me);
  }
  function _handleLoginKeyPress(e) {
    if (e.key === "Enter") {
      let username = document.getElementById("username").value;
      let password = document.getElementById("password").value;
      let remember_me = document.getElementById("remember_me").checked
        ? "yes"
        : "no";
      authenticateUser(username, password, remember_me);
    }
  }

  function logout(e) {
    terminateSession();
  }

  function authenticateUser(username, password, remember_me) {
    let login_errors = document.getElementById("login_errors");
    let login_button = document.getElementById("login_button");
    let confirmation_error = document.getElementById('confirmation_error')
    let login_loader = document.getElementsByClassName("login-loader");
    login_loader = login_loader[0];

    if (confirmation_error) {
      confirmation_error.style.display = "none";
    }
    login_button.style.display = "none";
    login_loader.style.display = "block";

    login_errors.innerHTML = "";
    axios
      .post(
        auth_url,
        {
          username: username,
          password: password,
          remember_me: remember_me,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(
        (response) => {
          localStorage.setItem("login_referrer", document.referrer);
          login_button.style.display = "inline-block";
          login_loader.style.display = "none";

          if (response.data.access_token) {
            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("username", username);
            Sgl.loginOnSGL(remember_me);
            Sgl.getUser();
            Sgl.refetchVideoAsset();
            setShowLoginForm(false);
            closeDialogs();
          }
        },
        (error) => {
          login_button.style.display = "inline-block";
          login_loader.style.display = "none";

          if (error.response) {
            if (error.response.data.statusCode == 401) {
              let message_array = error.response.data.message.split("___");
              let flag = message_array[0];
              if (flag == "is_invalid_user" || flag == "Unauthorized") {
                login_error_log(username, password);
                login_errors.innerHTML = "Invalid email address or password.";
              } else if (flag == "is_inactive") {
                login_error_log(username);
                login_errors.innerHTML =
                  "Your account is currently inactive. Please contact support.";
              } else if (flag == "is_pending_registration") {
                login_error_log(username);

                setEmail(message_array[1]);

                var link = document.createElement("a");
                var span = document.createElement("span");

                link.innerHTML = "resend confirmation email.";
                link.setAttribute("href", "javascript:void(0)");
                link.onclick = { resendConfirmation };

                span.innerHTML =
                  "Your registration is not confirmed. Click to ";
                span.appendChild(link);

                if ( confirmation_error ) {
                  confirmation_error.style.display = "block";
                }
                // login_errors.appendChild(span);
              } else if (flag == "is_bad_email") {
                login_error_log(username);

                setLoginId(message_array[1]);
                setSecret(message_array[2]);

                sendVerificationEmail(message_array[1], message_array[2]);
              }
            } else {
              login_error_log(username, password);
              login_errors.innerHTML = "Error occurred.";
            }
          } else {
            login_error_log(username, password);
            login_errors.innerHTML = "Error occurred.";
          }
        }
      );
  }

  function login_error_log(username, password = "") {
    let error_log = false;
    axios
      .post(
        sgl_webservice_login_error_log,
        {
          username: username,
          unhash_pass: password,
          customer_id: Sgl.company_id,
          from_wp_api: true,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        error_log = true;
      });
  }

  function resendConfirmation() {
    let sgl_plugin_form = document.getElementById("sgl-plugin-form");
    let sgl_plugin_success_message = document.getElementById(
      "sgl-plugin-success-message"
    );

    axios
      .get(sgl_webservice_resend_confirmation, {
        params: {
          email: email,
          customer_id: Sgl.company_id,
          site_url: `${window.location.protocol}//${window.location.host}${(Sgl.basePath && Sgl.basePath != '/' && Sgl.basePath != '')?`${Sgl.basePath}`:"/showgrounds"}`,
        },
      })
      .then((response) => {
        setCompanyName(response.data.company_name);
        sgl_plugin_form.innerHTML = "";
        sgl_plugin_form.style.display = "none";
        sgl_plugin_success_message.style.display = "block";
      });
  }

  function sendVerificationEmail(login_id, secret) {
    window.location.href =
      "/showgrounds/send-verification-email?loginid=" + login_id + "&secret=" + secret;
  }

  function terminateSession() {
    let login_errors = document.getElementById("login_errors");
    let login_button = document.getElementById("login_button");
    let login_loader = document.getElementsByClassName("login-loader");
    login_loader = login_loader[0];
    login_button.style.display = "none";
    login_loader.style.display = "block";
    login_errors.innerHTML = "";
    localStorage.removeItem("access_token");
    closeDialogs();
    Sgl.logOutUser(Sgl.refetchVideoAsset);
  }

  return (
    <>
      {
        (document?.sgl_plugin_page == '1' || document?.sgl_plugin_page == 1) && document?.sgl_plugin_colors && 
        <style>
          {
            document.sgl_plugin_colors?.primary_header_color_bg &&
            `.popup-card-deck .popup-card-item .bar {\
              background-color: ${document.sgl_plugin_colors.primary_header_color_bg};\
            }
            .popup-card-deck .popup-card-item .popup-card .popup-card-body .btn {\
              background-color: ${document.sgl_plugin_colors.primary_header_color_bg};\
              border-color: ${document.sgl_plugin_colors.primary_header_color_bg};\
            }\
            .popup-card-deck .popup-card-item .popup-card .popup-card-body .cancelBtn {\
              color: ${document.sgl_plugin_colors.primary_header_color_bg};\
            }\
            `
          }
          {
            document.sgl_plugin_colors?.link_color &&
            `.popup-card-deck .popup-card-item .popup-card .popup-card-body .forgot_password_link {\
              color: ${document.sgl_plugin_colors.link_color};\
            }
            .popup-card-deck .popup-card-item .popup-card .popup-card-body .register_link {\
              color: ${document.sgl_plugin_colors.link_color};\
            }\
            .popup-card-deck .popup-card-item .popup-card a {\
              color: ${document.sgl_plugin_colors.link_color};\
            }\
            `
          }
        </style>
      }

      {active === "Login" && notMobile ? (
        <>
          <ReactModal
            isOpen={active === "Login"}
            onRequestClose={closeDialogs}
            style={LoginModalStyles}
            className='modal-width-small'>
            <div className='popup-card-deck' style={{ padding: "0px 0px" }}>
              <div class='popup-card-item'>
                <div class='bar'> &nbsp; </div>
                <div class='popup-card' style={{ background: "white" }}>
                  {/* HEADER */}
                  <div class='popup-card-header' id='popup-card-header'>
                    <h3 class='popup-card-title'>Login</h3>
                    <div style={{ textAlign: "center", fontSize: "12px" }}>
                      {process.env.REACT_APP_CREDIT_TEXT}{" "}
                      <Link to={process.env.REACT_APP_CREDIT_URL}>
                        {" "}
                        {process.env.REACT_APP_CREDIT_COMPANY}
                      </Link>
                    </div>
                  </div>
                  {/* END HEADER */}

                  {/* BODY */}
                  <div
                    class='popup-card-body'
                    id='sgl-plugin-form'
                    // style={{marginTop: "10px"}}
                  >
                    {showLoginForm ? (
                      <div className='div-responsive'>
                        <input
                          defaultValue={username}
                          type='text'
                          placeholder='Username / Email Address'
                          id='username'
                          onKeyPress={_handleLoginKeyPress}
                        />

                        <input
                          type='password'
                          placeholder='Password'
                          id='password'
                          onKeyPress={_handleLoginKeyPress}
                        />

                        <input
                          value='yes'
                          type='checkbox'
                          id='remember_me'
                          name='remember_me'
                        />
                        <label
                          htmlFor='remember_me'
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                            marginLeft: "5px",
                          }}>
                          Remember Me
                        </label>
                        <p id='login_errors'></p>
                        <p style={{'color':'red', 'display':'none'}} id='confirmation_error'>
                          <span>
                            Your registration is not confirmed. Click to <a href="javascript:void(0);" onClick={resendConfirmation}>resend confirmation email</a>
                          </span>
                        </p>
                        <div
                          className='login_options'
                          style={{ marginTop: "10px" }}>
                          <Link
                            onClick={() => {
                              setActive("Forgot Password");
                            }}
                            className='forgot_password_link'>
                            Forgot password?
                          </Link>
                          <Link
                            onClick={() => {
                              setActive("Register");
                            }}
                            className='register_link'>
                            Register
                          </Link>
                        </div>
                        <div
                          className='center-align'
                          style={{ textAlign: "center" }}>
                          <input
                            type='button'
                            className='btn'
                            id='login_button'
                            value='Login'
                            onClick={login}
                          />
                          &nbsp; &nbsp;
                          <input
                            type='button'
                            className='cancelBtn'
                            value='Cancel'
                            onClick={closeDialogs}
                          />
                        </div>
                        <div
                          id='sgl-content-wrapper'
                          className='sgl-content-wrapper login-loader'></div>
                        <div
                          className='copyright-text'
                          style={{ marginTop: "20px" }}>
                          {process.env.REACT_APP_COPYRIGHT_TEXT}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className='loggedin_as_info'>
                          Logged in as {userData.first} {userData.last_name} (
                          {userData.email_address})
                        </p>
                        <p id='login_errors'></p>
                        <input
                          type='button'
                          className='btn'
                          id='login_button'
                          value='Logout'
                          onClick={logout}
                        />
                        <div
                          id='sgl-content-wrapper'
                          className='sgl-content-wrapper login-loader'></div>
                      </div>
                    )}
                  </div>

                  {/* END BODY */}
                </div>
                <div
                  class='popup-card-body'
                  id='sgl-plugin-success-message'
                  style={{display:'none',background:'white',padding:'10px',textAlign:'center'}}
                >
                  <p>
                    Thank you for registering
                    <br />
                    <br />A confirmation email has been sent to you. You must access
                    your email and confirm the registration prior to logging in.{" "}
                  </p>
                </div>
              </div>
            </div>
          </ReactModal>
        </>
      ) : active === "Register" && notMobile ? (
        <ReactModal
          isOpen={active === "Register"}
          onRequestClose={closeDialogs}
          style={RegistrationModalStyles}
          className='modal-width-small'>
          <RegistrationForm handleRegistrationClose={closeDialogs} />
        </ReactModal>
      ) : active === "Forgot Password" && notMobile ? (
        <ReactModal
          isOpen={active === "Forgot Password"}
          onRequestClose={closeDialogs}
          style={ForgotPasswordModalStyles}
          className='modal-width-small'>
          <ForgotPasswordForm handleForgotPasswordClose={closeDialogs} />
        </ReactModal>
      ) : active === "Login" && isMobile ? (
        <div className='popup-card-deck' style={{ padding: "0px 0px" }}>
          <div class='popup-card-item'>
            <div class='bar'> &nbsp; </div>
            <div class='popup-card' style={{ background: "white" }}>
              {/* BODY */}
              <div
                class='popup-card-body'
                id='sgl-plugin-form'
                // style={{marginTop: "10px"}}
              >
                {showLoginForm ? (
                  <div className='div-responsive'>
                    <input
                      defaultValue={username}
                      type='text'
                      placeholder='Username / Email Address'
                      id='username'
                      onKeyPress={_handleLoginKeyPress}
                    />

                    <input
                      type='password'
                      placeholder='Password'
                      id='password'
                      onKeyPress={_handleLoginKeyPress}
                    />

                    <input
                      value='yes'
                      type='checkbox'
                      id='remember_me'
                      name='remember_me'
                    />
                    <span>
                      <label
                        htmlFor='remember_me'
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                          marginLeft: "5px",
                          color: "black"
                        }}>
                        Remember Me
                      </label>
                    </span>

                    <p style={{ fontSize: "14px" }} id='login_errors'></p>
                    <p style={{'color':'red', 'display':'none'}} id='confirmation_error'>
                      <span>
                        Your registration is not confirmed. Click to <a href="javascript:void(0);" onClick={resendConfirmation}>resend confirmation email</a>
                      </span>
                    </p>
                    <div
                      className='login_options'
                      style={{ marginTop: "10px" }}>
                      <Link
                        onClick={() => {
                          setActive("Forgot Password");
                        }}
                        className='forgot_password_link'>
                        Forgot password?
                      </Link>
                      <Link
                        onClick={() => {
                          setActive("Register");
                        }}
                        className='register_link'>
                        Register
                      </Link>
                    </div>
                    <div
                      className='center-align'
                      style={{ textAlign: "center" }}>
                      <input
                        type='button'
                        className='btn'
                        id='login_button'
                        value='Login'
                        onClick={login}
                      />
                      &nbsp; &nbsp;
                      <input
                        type='button'
                        className='cancelBtn'
                        value='Cancel'
                        onClick={closeDialogs}
                      />
                    </div>
                    <div
                      id='sgl-content-wrapper'
                      className='sgl-content-wrapper login-loader'></div>
                    <div
                      className='copyright-text'
                      style={{ marginTop: "20px" }}>
                      {process.env.REACT_APP_COPYRIGHT_TEXT}
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className='loggedin_as_info'>
                      Logged in as {userData.first} {userData.last_name} (
                      {userData.email_address})
                    </p>
                    <p id='login_errors'></p>
                    <input
                      type='button'
                      className='btn'
                      id='login_button'
                      value='Logout'
                      onClick={logout}
                    />
                    <div
                      id='sgl-content-wrapper'
                      className='sgl-content-wrapper login-loader'></div>
                  </div>
                )}
              </div>

              {/* END BODY */}
            </div>
            <div
              class='popup-card-body'
              id='sgl-plugin-success-message'
              style={{display:'none',background:'white',padding:'10px',textAlign:'center'}}
            >
              <p>
                Thank you for registering
                <br />
                <br />A confirmation email has been sent to you. You must access
                your email and confirm the registration prior to logging in.{" "}
              </p>
            </div>
          </div>
        </div>
      ) : active === "Register" && isMobile ? (
        <RegistrationForm
          isMobile={isMobile}
          handleRegistrationClose={closeDialogs}
        />
      ) : active === "Forgot Password" && isMobile ? (
        <ForgotPasswordForm
          isMobile={isMobile}
          handleForgotPasswordClose={closeDialogs}
        />
      ) : (
        ""
      )}
    </>
  );
}
