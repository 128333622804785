import { useEffect, useState } from 'react';

import './../styles/jquery.bxslider.css'
import moment from 'moment';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

require('/node_modules/bxslider/dist/jquery.bxslider.min.js');

function HeroArea(props) {
  const [dataset, setDataset] = useState(props.dataSet)
  const verticalSliderAutoPlay = (typeof(dataset.auto_play) != 'undefined' && dataset.auto_play != '') ? dataset.auto_play : false;
  const verticalSliderNav = (typeof(dataset.navigation) != 'undefined' && dataset.navigation != '') ? dataset.navigation : false;
  const featuredSectionType = (typeof(dataset.featuredsectiontype) != 'undefined' && dataset.featuredsectiontype != '') ? dataset.featuredsectiontype : "slide"; //scroll or slide

  // GEG specific layout settings
  const theme = (typeof(dataset.theme) != 'undefined' && dataset.theme != '') ? dataset.theme.toLowerCase() : "";

  function setVerticalSliderHeight(){
    let vertical_slider_height = 0;
    let index = 0;
    let column_width = $(".sgl-hero-area .sgl-column:last-child").width();
    let theoretical_height = column_width / 1.35; //image ratio
    let final_width = 0;
    let actual_height = 0;
    $('.sgl-scroll-wrapper li').each(function () {
      if($(this).find(".sgl-row").length > 0){
        actual_height = $(this).outerHeight();
        if(theoretical_height > actual_height){
          final_width = theoretical_height;
        }else{
          final_width = actual_height;
        }
        vertical_slider_height += final_width;
        index++;
        if(index == 2){
          return false;
        }
      }
    });

    vertical_slider_height = Math.floor((vertical_slider_height));
    $('.sgl-scroll-wrapper').height(vertical_slider_height);
  }

  useEffect(() => {
    // $(`#${props.embed_id} .sgl-vertical-bxslider`).bxSlider({
    //   mode: 'vertical',
    //   auto: verticalSliderAutoPlay,
    //   touchEnabled: true,
    //   pager: false,
    //   minSlides: 2,
    //   moveSlides: 1,
    //   responsive: true,
    //   slideWidth: 'auto',
    //   controls: verticalSliderNav
    // });
  }, []);

  return (
    <>
    <section className={"sgl-hero-area "+ theme}>
      <div className="sgl-column must-see">
        <div className="sgl-heading">
          <h1><span>Must-See</span></h1>
        </div>
        {
          props.video_assets.list.slice(0,1).map((video_asset, index) => {
            return (
              <div key={video_asset.sgl_id} className="sgl-row">
                <div className="sgl-img">
                  <a href={props.basePath+`/${document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1 ? 'video-detail': 'video'}/${video_asset.sgl_id}/${video_asset.slug}`+`${dataset.playlist > 0?"/playlist/"+dataset.playlist:""}`}>
                  <img src={video_asset.placeholder_image != '' ? video_asset.placeholder_image : "https://showgroundslive.com/images/placeholder_video_small.jpg"} />
                  </a>
                  <a className="sgl-play" href={props.basePath+`/${document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1 ? 'video-detail': 'video'}/${video_asset.sgl_id}/${video_asset.slug}`+`${dataset.playlist > 0?"/playlist/"+dataset.playlist:""}`}>
                    <div className="sgl-play-icon-large"><span>&#x25BA;</span></div>
                  </a>
                  
                    {
                      (false && video_asset.event_started=='0' && video_asset.type!="Uploaded Videos" && video_asset.type!="rvs" )?
                      <div class="sgl-video-state">UPCOMING</div>
                      :
                      ""
                    }
                    {
                      (false && video_asset.event_started=='1' && video_asset.event_ended != '1' && video_asset.type!="Uploaded Videos" && video_asset.type!="rvs")?
                      <div class="sgl-video-state">LIVE NOW</div>
                      :
                      ""
                    }
                    {
                      (false && video_asset.event_started=='1' && video_asset.event_ended == '1' && video_asset.type!="Uploaded Videos" && video_asset.type!="rvs")?
                      <div class="sgl-video-state">ARCHIVE</div>
                      :
                      ""
                    }
                  
                </div>
                <p>{video_asset.title}</p>
                {/* {
                  (dataset.type != "ringlivestream") &&
                  <>
                    <ul><li>{moment(moment(video_asset.event_date).format("YYYY-MM-DD") + " " + video_asset.event_time).format('dddd, MMMM D YYYY hh:mm a')}</li></ul>
                  </>
                } */}
              </div>
            )
            
          })
        }
      </div>
      <div className={"sgl-column featured " + (featuredSectionType == "slide" ? "sgl-vertical-slider" : "sgl-vertical-scroll") }>
        <div className="sgl-heading">
          <h1><span>Featured</span></h1>
        </div>
        <ul className={(featuredSectionType == "slide" ? "sgl-vertical-bxslider" : "sgl-scroll-wrapper")}>
        {
          props.video_assets.list.slice(1, 3).map((video_asset) => {
            return (
              <li key={video_asset.sgl_id}>
                <div className="sgl-row">
                  <div className="sgl-img">
                  <a href={props.basePath+`/${document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1 ? 'video-detail': 'video'}/${video_asset.sgl_id}/${video_asset.slug}`+`${dataset.playlist > 0?"/playlist/"+dataset.playlist:""}`}>
                  <img src={video_asset.placeholder_image != '' ? video_asset.placeholder_image : "https://showgroundslive.com/images/placeholder_video_small.jpg"} />
                  </a>
                    <a className="sgl-play" href={props.basePath+`/${document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1 ? 'video-detail': 'video'}/${video_asset.sgl_id}/${video_asset.slug}`+`${dataset.playlist > 0?"/playlist/"+dataset.playlist:""}`}>
                      <div className="sgl-play-icon"><span>&#x25BA;</span></div>
                    </a>

                    {
                      (false && video_asset.event_started=='0')?
                      <div class="sgl-video-state">UPCOMING</div>
                      :
                      ""
                    }
                    {
                      (false && video_asset.event_started=='1' && video_asset.event_ended != '1')?
                      <div class="sgl-video-state">LIVE NOW</div>
                      :
                      ""
                    }
                    {
                      (false && video_asset.event_started=='1' && video_asset.event_ended == '1')?
                      <div class="sgl-video-state">ARCHIVE</div>
                      :
                      ""
                    }

                  </div>
                  <p>{video_asset.title}</p>
                  {/* {
                    (dataset.type != "ringlivestream") &&
                    <>
                      <ul><li>{moment(moment(video_asset.event_date).format("YYYY-MM-DD") + " " + video_asset.event_time).format('dddd, MMMM D YYYY hh:mm a')}</li></ul>
                    </>
                  } */}
                </div>
              </li>
            )
          })
        }
        </ul>
      </div>
    </section>
    </>
  );
}

export default HeroArea;
