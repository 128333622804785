import { useEffect, useState} from 'react';
import './../styles/owl.carousel.css'
import moment from 'moment';

const OwlCarousel = require('react-owl-carousel');

function format_time(timeString){
  var H = +timeString.substr(0, 2);
  var h = H % 12 || 12;
  var ampm = (H < 12 || H === 24) ? " AM" : " PM";
  timeString = h + timeString.substr(2, 3) + ampm;
  return timeString;
}

function Carousel(props) {
  const [dataset, setDataset] = useState(props.dataSet)

  const carouselItems = (typeof(dataset.items_per_row) != 'undefined' && dataset.items_per_row != '') ? dataset.items_per_row : 3;
  const carouselAutoPlay = (typeof(dataset.auto_play) != 'undefined' && dataset.auto_play != '') ? dataset.auto_play : false;
  const carouselSlideBy = (typeof(dataset.slide_by) != 'undefined' && dataset.slide_by != '') ? dataset.slide_by : 1;
  const carouselNavigation = (typeof(dataset.navigation) != 'undefined' && dataset.navigation != '') ? dataset.navigation : false;

  // GEG specific layout settings
  const theme = (typeof(dataset.theme) != 'undefined' && dataset.theme != '') ? dataset.theme.toLowerCase() : "";

  const OwlCarouselOptions = {
    items: carouselItems,
    nav: true,
    rewind: true,
    autoplay: carouselAutoPlay,
    slideBy: carouselSlideBy,
    dots: false,
    stageClass: "owl-wrapper",
    stageOuterClass: "owl-wrapper-outer",
    navContainerClass: "owl-controls clickable",
    loadedClass: "",
    dragClass: "",
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: (carouselItems > 2 ? 2 : carouselItems)
      },
      980: {
        items: (carouselItems > 3 ? 3 : carouselItems)
      },
      1300: {
        items: (carouselItems > 4 ? 4 : carouselItems)
      },
      1800: {
        items: (carouselItems > 5 ? 5 : carouselItems)
      }
    }
  };

  return (
    <>
      <style>
            {
              (document.sgl_plugin_page == 1 || document.sgl_plugin_page == '1') && (
                `section.sgl-videos-scroller.geg .sgl-see-all  {\
                  background-color:${dataset.foreground_color} !important;
                  color: ${dataset.background_color} !important\
                }\
                section.sgl-videos-scroller.geg .owl-theme .owl-controls button, section.sgl-videos-scroller.coth .owl-theme .owl-controls button {\
                  color:${dataset.foreground_color} !important;
                  border: 1px solid ${dataset.foreground_color} !important\
                }\
                section.sgl-videos-scroller.geg .sgl-title-section h1 span {\
                  color: ${dataset.foreground_color} !important;\
                }\
                section.sgl-videos-scroller.geg .sgl-standard-post .sgl-post-content h2 a {\
                  color: ${dataset.foreground_color} !important;\
                }
                `
              )
            }
      </style>
      <section className={"sgl-videos-scroller " + theme}>
        <div className="sgl-container">
          <div className="sgl-title-section">
            <h1><span>{props.video_assets?.display_title}</span></h1>
          </div>

          <div className="sgl-videos-scroller-box owl-wrapper">
            {
            props.video_assets?.list?.length > 0 ?
            <OwlCarousel className='owl-theme' {...OwlCarouselOptions} key={`carousel_${Date.now()}`}>
              {
                props.video_assets?.list?.map((video_asset) => {
                  return (
                    <div key={video_asset.sgl_id} className="sgl-item sgl-post sgl-standard-post">
                      <div className="sgl-post-gallery" onClick={(event) => {
                        if ( video_asset.type == 'rvs' ) {
                          window.location.href = props.basePath+`/video/rider-clip/${video_asset.sgl_id}/${video_asset.slug}`+`${dataset.playlist > 0?"/playlist/"+dataset.playlist:""}`
                        } else if (document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1){
                          window.location.href = props.basePath+`/video-detail/${video_asset.sgl_id}/${video_asset.slug}`+`${dataset.playlist > 0?"/playlist/"+dataset.playlist:""}`
                        } else {
                          window.location.href = props.basePath+`/video/${video_asset.sgl_id}/${video_asset.slug}`+`${dataset.playlist > 0?"/playlist/"+dataset.playlist:""}`
                        }
                      }}
                      >
                        <img src={video_asset.placeholder_image != '' ? video_asset.placeholder_image : "https://showgroundslive.com/images/placeholder_video_small.jpg"} onError={(e)=>{ e.target.src = "https://showgroundslive.com/images/placeholder_video_small.jpg"}}/>
                        <a className="sgl-play" href="javascript:void(0);">
                          <div className="sgl-play-icon"><span>&#x25BA;</span>
                          </div>
                        </a>
                          {
                            (video_asset.event_started=='0' && video_asset.type!="Uploaded Videos" && video_asset.type!="rvs")?
                            <div className='sgl-video-state'>UPCOMING</div>
                            :
                            ""
                          }
                          {
                            (video_asset.event_started=='1' && video_asset.event_ended != '1' && video_asset.type!="Uploaded Videos" && video_asset.type!="rvs")?
                            <div className='sgl-video-state'>LIVE NOW</div>
                            :
                            ""
                          }
                      </div>
                      <div className="sgl-post-content">
                        <h2>
                          <a href="javascript:void(0);">{video_asset.title}</a>
                        </h2>
                        {
                          (video_asset.type == "Live Stream") &&
                          <>
                            <p className="sgl-carousel-text">{moment(moment(video_asset.event_date).format("YYYY-MM-DD") + " " + video_asset.event_time).format('dddd, MMMM D YYYY hh:mm a')}</p>
                          </>
                        }
                        {
                          (video_asset.type == "Ring Stream Configuration") &&
                          <>
                          {
                          (video_asset.header_classes?.length > 0) &&
                          video_asset.header_classes.slice(0, 1).map((data, index) => {
                            return (
                              (data.interval == "Live Now:") ?
                                <p class="text"><span class="green sgl-carousel-label">{data.interval} </span>{data.header} {(data.est_time && data.time !== "00:00:00")?<b>Est. Start: </b>:''}{(data.est_time && data.time !== '00:00:00')?format_time(data.time):''}</p>
                              :
                                <p class="text"><span class="orange sgl-carousel-label">{data.interval} </span>{data.header} {(data.est_time && data.time !== "00:00:00")?<b>Est. Start: </b>:''}{(data.est_time && data.time !== '00:00:00')?format_time(data.time):''}</p>
                            )
                          })
                        }
                        {
                          (video_asset.show_header_message) &&
                            <p class="text">No classes scheduled in this ring.</p>
                        }
                          </>
                        }
                        {
                          (video_asset.type != "Ring Stream Configuration" && video_asset.description!='') &&
                          <p class="text">{video_asset.description}</p>
                        }
                      </div>
                    </div>
                  )
                })
              }
            </OwlCarousel>
            :
            <p class="no-items-to-display">No items to display.</p>
            }

            {
              (props.video_assets?.list?.length > 0 && dataset.enable_see_all_button == 1) && 
              <a href={props.basePath+"/video-all/"+dataset.playlist} class="sgl-see-all">
                { dataset.see_all_label !="" ? dataset.see_all_label : "See All" }
              </a>
            }   
          </div>

        </div>
      </section>
    </>
  );
}

export default Carousel;
