import { useEffect, useState } from 'react';
import './../styles/grid.css'
import moment from 'moment';

function format_time(timeString) {
  var H = +timeString.substr(0, 2);
  var h = H % 12 || 12;
  var ampm = (H < 12 || H === 24) ? " AM" : " PM";
  timeString = h + timeString.substr(2, 3) + ampm;
  return timeString;
}

function Grid(props) {
  const [dataset, setDataset] = useState(props.dataSet)

  // GEG specific layout settings
  const theme = (typeof (dataset.theme) != 'undefined' && dataset.theme != '') ? dataset.theme.toLowerCase() : "";
  const type = (typeof (dataset.type) != 'undefined' && dataset.type != '') ? dataset.type : "";
  const items_per_row = (typeof (dataset.items_per_row) != 'undefined' && dataset.items_per_row > 0) ? dataset.items_per_row : 3;

  return (
    <>
      <style>
        {
          items_per_row && (
            `#${props.embed_id} .sgl-grid .card-deck {\
                grid-template-columns: repeat(`+ items_per_row + `, 1fr);\
              }`
          )
        }
      </style>
      <section className={"sgl-grid " + theme}>
        <div className="sgl-title-section">
          <h1><span>{props.video_assets.display_title}</span></h1>
        </div>
        <div className="card-deck">
          {
            props.video_assets.list.map((video_asset) => {

              return (
                  <div key={video_asset.sgl_id} className="sgl-item sgl-post sgl-standard-post"
                    onClick={(event) => {
                      window.location.href = props.basePath+`/${(document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1) ? 'video-detail': 'video'}/${video_asset.sgl_id}/${video_asset.slug}`
                    }}
                  >
                    <div className="sgl-post-gallery">
                      <img src={video_asset.placeholder_image} style={{maxWidth:"100%"}}/>
                      <a className="sgl-play" href="javascript:void(0);">
                        <div className="sgl-play-icon"><span>&#x25BA;</span>
                        </div>
                      </a>
                      {
                        (video_asset.event_started=='0')?
                        <div className='sgl-video-state'>UPCOMING</div>
                        :
                        ""
                      }
                      {
                        (video_asset.event_started=='1' && video_asset.event_ended != '1')?
                        <div className='sgl-video-state'>LIVE NOW</div>
                        :
                        ""
                      }
                    </div>
                    <div className="sgl-post-content">
                      <h2>
                        <a href="javascript:void(0);">{video_asset.title}</a>
                      </h2>
                      {
                        (dataset.type != "ringlivestream") &&
                        <>
                          <p className="sgl-carousel-text">{moment(moment(video_asset.event_date).format("YYYY-MM-DD") + " " + video_asset.event_time).format('dddd, MMMM D YYYY hh:mm a')}</p>
                        </>
                      }
                      {
                        (dataset.type == "ringlivestream") &&
                        <>
                          {
                            (video_asset.header_classes?.length > 0) &&
                            video_asset.header_classes.slice(0, 1).map((data, index) => {
                              return (
                                (data.interval == "Live Now:") ?
                                  <p class="text"><span class="green sgl-carousel-label">{data.interval} </span>{data.header} {(data.est_time && data.time !== "00:00:00") ? <b>Est. Start: </b> : ''}{(data.est_time && data.time !== '00:00:00') ? format_time(data.time) : ''}</p>
                                  :
                                  <p class="text"><span class="orange sgl-carousel-label">{data.interval} </span>{data.header} {(data.est_time && data.time !== "00:00:00") ? <b>Est. Start: </b> : ''}{(data.est_time && data.time !== '00:00:00') ? format_time(data.time) : ''}</p>
                              )
                            })
                          }
                          {
                            (video_asset.show_header_message) &&
                            <p class="card-text">No classes scheduled in this ring.</p>
                          }
                        </>
                      }
                    </div>
                  </div>
                
              )
            })
          }
        </div>
      </section>
    </>
  );
}

export default Grid;
