import { useEffect, useState } from 'react';
import './../styles/button_layout.css'

function format_time(timeString){
  var H = +timeString.substr(0, 2);
  var h = H % 12 || 12;
  var ampm = (H < 12 || H === 24) ? " AM" : " PM";
  timeString = h + timeString.substr(2, 3) + ampm;
  return timeString;
}

function ButtonLayout(props) {
  const [dataset, setDataset] = useState(props.dataSet)
  // GEG specific layout settings
  const theme = (typeof (dataset.theme) != 'undefined' && dataset.theme != '') ? dataset.theme.toLowerCase() : "";
  const type = (typeof (dataset.type) != 'undefined' && dataset.type != '') ? dataset.type : "";
  const items_per_row = (typeof (dataset.items_per_row) != 'undefined' && dataset.items_per_row > 0) ? dataset.items_per_row : 3;
  return (
    <>
    <style>
          {
            items_per_row && (
              `#${props.embed_id} .sgl-button-layout .card-deck {\
                grid-template-columns: repeat(`+items_per_row+`, 1fr);\
              }`
            )
          }
    </style>

    <style>
          {
            dataset.related_videos && (
              `#${props.embed_id} .sgl-button-layout .card-deck {\
                grid-template-columns: repeat(`+items_per_row+`, 1fr);\
              }
              #${props.embed_id} .sgl-button-layout .card-deck .card-item .bar {\
                background-color:${dataset.foreground_color};\
              }\
              #${props.embed_id} .sgl-button-layout .card-deck .card-item .card .card-footer .btn {\
                background-color:${dataset.foreground_color};
                border: 1px solid ${dataset.foreground_color}\
              }\
              #${props.embed_id} .sgl-button-layout .card-deck .card-item .card {\
                border: 2px solid ${dataset.foreground_color};\
              }\
              `
            )
          }
    </style>


      <section className={"sgl-button-layout " + theme}>
        <div className="sgl-title-section">
            <h1><span>{props.video_assets.display_title}</span></h1>
        </div>
        { props.video_assets?.list?.length > 0 ?
        <div className="card-deck">
          {
            props.video_assets.list.map((video_asset) => {
              
              return (
                <div class="card-item">
                  <div class="bar">&nbsp;</div>
                  {
                      (type == "livestream")
                      ?
                        (video_asset.current_date && video_asset.event_started=='1' && video_asset.event_ended != '1')
                        &&
                        <div class="live">Live</div>
                      :
                        (video_asset.event_started=='1' && video_asset.event_ended != '1')
                        &&
                        <div class="live">Live</div>
                  }
                  
                  <div key={video_asset.sgl_id} className="card">

                    <div class="card-header">
                      <h5 class="card-title"><a href={props.basePath+`/${document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1 ? 'video-detail': 'video'}/${video_asset.sgl_id}/${video_asset.slug}`+`${dataset.playlist > 0?"/playlist/"+dataset.playlist:""}`}>{video_asset.title}</a></h5>
                    </div>
                    <div class="card-body">
                      {
                        (type == "livestream") &&
                        <>
                        <p class="card-text"><span>Date:</span>{video_asset.date}</p>
                        <p class="card-text"><span>Time:</span>{video_asset.time}</p>
                        </>
                      }
                      {
                        (type == "ringlivestream") &&
                        <>
                        {
                          (video_asset.header_classes.length > 0) &&
                          video_asset.header_classes.map((data, index) => {
                            return (
                                (data.interval == "Live Now:") ?
                                  <p class="card-text"><span class="green">{data.interval}</span>{data.header} {(data.est_time && data.time !== "00:00:00")?<b>Est. Start: </b>:''}{(data.est_time && data.time !== '00:00:00')?format_time(data.time):''}</p>
                                :
                                  <p class="card-text"><span class="orange">{data.interval}</span>{data.header} {(data.est_time && data.time !== "00:00:00")?<b>Est. Start: </b>:''}{(data.est_time && data.time !== '00:00:00')?format_time(data.time):''}</p>
                            )
                          })
                        }
                        {
                          (video_asset.show_header_message) &&
                            <p class="card-text">No classes scheduled in this ring.</p>
                        }
                        </>
                      }
                    </div>
                      {
                        (type == "livestream") &&
                        <>
                          {
                          (video_asset.current_date && video_asset.event_started=='1' && video_asset.event_ended != '1') ?
                          <div class="card-footer"><a href={props.basePath+`/${document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1 ? 'video-detail': 'video'}/${video_asset.sgl_id}/${video_asset.slug}`+`${dataset.playlist > 0?"/playlist/"+dataset.playlist:""}`} class="btn">watch live</a></div>
                          :
                          <div class="card-footer"><a href={props.basePath+`/${document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1 ? 'video-detail': 'video'}/${video_asset.sgl_id}/${video_asset.slug}`+`${dataset.playlist > 0?"/playlist/"+dataset.playlist:""}`} class="btn">view Details</a></div>
                          }
                        </>
                      }
                      {
                        (type == "ringlivestream") &&
                        <>
                          <div class="card-footer"><a href={props.basePath+`/${document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1 ? 'video-detail': 'video'}/${video_asset.sgl_id}/${video_asset.slug}`+`${dataset.playlist > 0?"/playlist/"+dataset.playlist:""}`} class="btn">watch ring {video_asset.ringsxshow_ring_number}</a></div>
                        </>
                      }
                    
                  </div>
                </div>
              )
            })
          }
        </div>
        :
        <p class="no-items-to-display">No items to display.</p>
        }
      </section>
    </>
  );
}

export default ButtonLayout;
