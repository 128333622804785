import React from "react";
import axios from "axios";
import { Component } from "react";
import ReactModal from "react-modal";
import SglContext from "./SglContext";
import { env } from "../env";
var AddModal = ReactModal;

const sgl_webservice_iphone =
  env.REACT_APP_SGL_API + "/iphonev2/addentryv2/";
const ManageCardsModalStyles = { content: { border: "1px solid #ccc", borderRadius: "4px", left: "50%", outline: "none", overflow: "auto", padding: "5px 20px 0", position: "fixed", top: "50%", transform: "translate(-50%,-50%)", maxWidth: "600px", width: "60%", }, overlay: { zIndex: 1000 }, };
const AddCreditCardModalStyles = { content: { border: "1px solid #ccc",maxHeight:"90%", borderRadius: "4px", left: "50%", outline: "none", overflow: "auto", padding: "5px 20px 0", position: "fixed", top: "50%", transform: "translate(-50%,-50%)", maxWidth: "600px", width: "60%", }, overlay: { zIndex: 1000 }, };

const card_type_array = ["Visa", "MasterCard", "Amex", "Discover"];
const months_array = [ { month_no: "01", month_name: "January", value: "1", }, { value: "2", month_no: "02", month_name: "February", }, { value: "3", month_no: "03", month_name: "March", }, { value: "4", month_no: "04", month_name: "April", }, { value: "5", month_no: "05", month_name: "May", }, { value: "6", month_no: "06", month_name: "June", }, { value: "7", month_no: "07", month_name: "July", }, { value: "8", month_no: "08", month_name: "August", }, { value: "9", month_no: "09", month_name: "September", }, { value: "10", month_no: "10", month_name: "October", }, { value: "11", month_no: "11", month_name: "November", }, { value: "12", month_no: "12", month_name: "December", }, ];
const countries_array = [
  "United States",
  "Canada",
  "Afghanistan",
  "Aland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia, Plurinational State of",
  "Bonaire, Saint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "C?te d'Ivoire",
  "Croatia",
  "Cuba",
  "Cura?ao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, the former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "R?union",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barth?lemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela, Bolivarian Republic of",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const new_card_data_initial = {
  sgl_id: "",
  label: "",
  description: "",
  card_number: "",
  card_lastfour: "",
  card_exp: "",
  card_exp_month: "",
  card_exp_year: "",
  type: "",
  name_on_card: "",
  card_first: "",
  card_last: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  country: "",
  email: "",
};

/* ManageCards Component 
-------------------------------------------------*/
class ManageCards extends React.Component {
  constructor(props) {
    super(props);

    // this.openSelectCardModal = this.openSelectCardModal.bind(this);
    this.closeSelectCardModal = this.closeSelectCardModal.bind(this);

    this.openAddCreditCardModal = this.openAddCreditCardModal.bind(this);
    this.closeAddCreditCardModal = this.closeAddCreditCardModal.bind(this);
    this.openConfirmRemoveCardModal =
      this.openConfirmRemoveCardModal.bind(this);
    this.closeConfirmRemoveCardModal =
      this.closeConfirmRemoveCardModal.bind(this);
    this.RemoveCard = this.RemoveCard.bind(this);
    this.addCreditCard = this.addCreditCard.bind(this);

    this.state = {
      // selected_payment_method: -1,
      // is_open_manage_card_modal: false,
      is_open_add_card_modal: false,
      new_card_data: JSON.parse(JSON.stringify(new_card_data_initial)),
      add_card_error_msg: "",
      is_editing_card: false,
      is_open_remove_card_modal: false,
      card_to_remove: "",
      remove_card_msg: "",
      // deposit_cvv: "",
    };
  }

  componentWillMount() {}
  /////////////// manage cards functions ////////////////////

  onDepositMethodSelect(e) {
    if (e.target.value == "ManageCards") {
      this.openSelectCardModal();
      this.setState({
        selected_payment_method: "-1",
      });
    } else {
      this.setState({
        selected_payment_method: e.target.value,
      });
    }
  }

  //   openSelectCardModal() {
  //     this.setState({
  //       is_open_manage_card_modal: true,
  //     });
  //   }

  closeSelectCardModal() {
    this.props.closeManageCardModal();
    // this.setState({
    //   is_open_manage_card_modal: false,
    // });
  }

  openAddCreditCardModal() {
    this.setState({
      is_open_add_card_modal: true,
      add_card_error_msg: "",
      new_card_data: JSON.parse(JSON.stringify(new_card_data_initial)),
      is_editing_card: false,
    });
  }

  openEditCardModal = (card_data) => (e) => {
    const card_exp_array = card_data.card_exp.split("/");
    card_data.card_exp_month = card_exp_array[0];
    card_data.card_exp_year = card_exp_array[1];
    card_data.card_number = "xxxx-xxxx-xxxx-" + card_data.card_lastfour;
    this.setState({
      is_open_add_card_modal: true,
      add_card_error_msg: "",
      new_card_data: card_data,
      is_editing_card: true,
    });
  };

  closeAddCreditCardModal() {
    this.props.getPaymentMethods();
    this.setState({
      is_open_add_card_modal: false,
      new_card_data: JSON.parse(JSON.stringify(new_card_data_initial)),
    });
  }
  openConfirmRemoveCardModal = (card_data) => (e) => {
    this.setState({
      is_open_remove_card_modal: true,
      card_to_remove: card_data.sgl_id,
    });
  };

  closeConfirmRemoveCardModal() {
    this.setState({
      is_open_remove_card_modal: false,
      card_to_remove: "",
    });
  }
  RemoveCard() {
    axios
      .get(sgl_webservice_iphone + "RemoveCard", {
        params: {
          fingerprint_id: this.state.card_to_remove,
          company_id: this.context.company_id,
        },
      })
      .then((results) => {
        if (results.data.success) {
          this.setState({
            is_open_remove_card_modal: false,
            card_to_remove: "",
            remove_card_msg: "Credit Card successfully removed.",
          });
        } else {
          this.setState({
            is_open_remove_card_modal: false,
            card_to_remove: "",
            remove_card_msg: results.data.error,
          });
        }
        document.getElementById("remove_card_warning").style.display = "block";
        this.props.getPaymentMethods();
      });
  }

  updateNewCardData(evt, field) {
    var card_data = this.state.new_card_data;
    card_data[field] = evt.target.value;

    this.setState({
      new_card_data: card_data,
    });
  }

  addCreditCard() {
    var error_msg = "";

    // if (this.state.new_card_data.description == '') {
    //   error_msg = 'Please enter Description';
    // } else
    if (this.state.new_card_data.card_number == "") {
      error_msg = "Please enter Card Number";
    } else if (this.state.new_card_data.type == "") {
      error_msg = "Please enter Credit type";
    } else if (this.state.new_card_data.card_exp_month == "") {
      error_msg = "Please enter Expiration Month";
    } else if (this.state.new_card_data.card_exp_year == "") {
      error_msg = "Please enter Expiration Year";
    } else if (this.state.new_card_data.name_on_card == "") {
      error_msg = "Please enter Name on Card";
    } else if (
      this.props.check_card_email_phone_info &&
      this.state.new_card_data.email == ""
    ) {
      error_msg = "Please enter your Email Address";
    } else if (
      this.props.check_card_email_phone_info &&
      this.state.new_card_data.phone == ""
    ) {
      error_msg = "Please enter your Phone Number";
    } else if (
      this.props.check_address_info &&
      this.state.new_card_data.address_1 == ""
    ) {
      error_msg = "Please enter your Address";
    } else if (
      this.props.check_address_info &&
      this.state.new_card_data.city == ""
    ) {
      error_msg = "Please enter your City";
    } else if (
      this.props.check_address_info &&
      this.state.new_card_data.state == ""
    ) {
      error_msg = "Please enter your State";
    } else if (
      this.props.check_address_info &&
      this.state.new_card_data.zip == ""
    ) {
      error_msg = "Please enter your Zip Code";
    } else if (
      this.props.check_address_info &&
      this.state.new_card_data.country == ""
    ) {
      error_msg = "Please enter your Country";
    }

    var card_data = this.state.new_card_data;

    card_data.card_exp =
      card_data.card_exp_month + "/" + card_data.card_exp_year;
    if (card_data.card_lastfour == "") {
      card_data.card_lastfour = card_data.card_number.substr(
        card_data.card_number.length - 4
      );
    }
    if (card_data.label == "") {
      card_data.label =
        "xxxx-" +
        card_data.card_lastfour +
        " [" +
        card_data.type +
        "] " +
        card_data.name_on_card;
    }
    if (error_msg == "") {
      var encoded_data = btoa(JSON.stringify(this.state.new_card_data));
      this.SaveCreditCard(encoded_data);

      // document.getElementById('add_people_warning').style.display = "none";
      // this.setState({is_open_people_modal: false, add_people_error_msg: ''});
    } else {
      this.setState({ add_card_error_msg: error_msg });
      document.getElementById("add_card_warning").style.display = "block";
    }
  }

  handleSaveCreditCardData(response_data) {
    if (response_data.error) {
      this.setState({ add_card_error_msg: response_data.error });
      document.getElementById("add_card_warning").style.display = "block";
    } else {
      this.props.getPaymentMethods();
      // this.setState(prevState => ({
      //   payment_methods: [...prevState.payment_methods, this.state.new_card_data],
      // }))
      // }

      document.getElementById("add_card_warning").style.display = "none";
      this.setState({ is_open_add_card_modal: false, add_card_error_msg: "" });
    }
  }

  SaveCreditCard(card_data) {
    axios
      .get(sgl_webservice_iphone + "SaveCreditCard", {
        params: {
          card_data: card_data,
          company_id: this.context.company_id,
        },
      })
      .then((results) => {
        this.handleSaveCreditCardData(results.data);
      })
      .catch((error) => console.log(error));
  }

  getExpYearsArray() {
    var years_array = [];
    var now = new Date().getFullYear() - 1;
    const years = Array(now - (now - 20))
      .fill("")
      .map((v, idx) => now + idx);
    years.forEach((year) => {
      years_array.push({
        year_name: year,
        year_value: year,
      });
    });
    return years_array;
  }
  /////////////// end manage cards functions ////////////////////

  format_currancy(amount) {
    if (amount == 0) {
      return "$0.00";
    }
    if (amount == undefined || amount == "") {
      return "";
    }
    // return '$'+(amount && amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    return (
      "$" +
      (amount &&
        Number(amount)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    );
  }

  render() {
    return (
      <div>
        <div>
          <AddModal
            isOpen={this.props.is_open_manage_card_modal}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeSelectCardModal}
            className='mymodal modal-width-responsive'
            // overlayClassName="myoverlay"
            style={ManageCardsModalStyles}
            // ariaHideApp={false}
          >
            <div className="child_modal">    <h2 >Manage Credit Cards</h2></div>
        

            <div style={{ overflowX: "auto" }}>
              <table
                style={{ width: "100%" }}
                className='table table-striped table-hover'>
                <tbody>
                  <tr class='sgl-primary-header'>
                    <th></th>
                    <th class='display_on_mobile'></th>
                    <th class='display_on_desktop'>Card No.</th>
                    <th class='display_on_desktop'>Name on Card</th>
                    <th class='display_on_desktop'>Type</th>
                    <th class='display_on_desktop'>Expiration</th>
                    <th class='display_on_desktop'></th>
                  </tr>
                  {this.props.payment_methods.length > 0 &&
                    this.props.payment_methods.map((data, index) => {
                      return (
                        <tr>
                          <td colspan=''>
                            <strong>
                              <a
                                style={{ fontSize: "12px" }}
                                onClick={this.openEditCardModal(data)}>
                                Edit
                              </a>
                            </strong>
                          </td>
                          <td class='display_on_mobile mbl_custom_row'>
                            <p class='table_row_p'>
                              <span>
                                <strong>Card No.:</strong> xxxx-
                                {data.card_lastfour}
                              </span>
                              <br></br>
                              <span>
                                <strong>Name on Card:</strong>{" "}
                                {data.name_on_card}
                              </span>
                              <br></br>
                              <span>
                                <strong>Type:</strong> {data.type}
                              </span>
                              <br></br>
                              <span>
                                <strong>Expiration:</strong> {data.card_exp}
                              </span>
                              <br></br>
                              <span>
                                <a
                                  onClick={this.openConfirmRemoveCardModal(
                                    data
                                  )}>
                                  Remove
                                </a>
                              </span>
                            </p>
                          </td>
                          <td class='display_on_desktop'>
                            xxxx-{data.card_lastfour}
                          </td>
                          <td class='display_on_desktop'>
                            {data.name_on_card}
                          </td>
                          <td class='display_on_desktop'>{data.type}</td>
                          <td class='display_on_desktop'>{data.card_exp}</td>
                          <td class='display_on_desktop'>
                            <a onClick={this.openConfirmRemoveCardModal(data)}>
                              Remove
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div class='continue-button-div'>
              <p id='remove_card_warning' class='required display_none'>
                {this.state.remove_card_msg}
              </p>
              <div style={{ clear: "both" }}></div>
              <button class='left' onClick={this.closeSelectCardModal}>
                Close
              </button>
              <button class='right' onClick={this.openAddCreditCardModal}>
                + Add another Card
              </button>
            </div>
          </AddModal>
        </div>
        <div>
          <AddModal
            isOpen={this.state.is_open_add_card_modal}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeAddCreditCardModal}
            className='mymodal modal-width-responsive'
            style={AddCreditCardModalStyles}
            // overlayClassName="myoverlay"
            // ariaHideApp={false}
          >
            <div className="child_modal">
            <h2 class='sgl-label-border'>
              {this.state.is_editing_card
                ? "Edit Credit Card"
                : "Add Credit Card"}
            </h2>
            </div>
   
            <div>
              <form>
                <ul>
                  <li className='entry_people_li'>
                    <div>
                      <label className='add_entry_label_modal'>
                        Description (Displayed at checkout)
                      </label>
                      <div style={{ clear: "both" }}></div>
                      <div class='add_modal_div'>
                        <input
                          style={{ width: "95% !important" }}
                          type='text'
                          name='description'
                          class='modal_input_right'
                          id='txt_add_people_description'
                          value={
                            this.state.new_card_data.description
                              ? this.state.new_card_data.description
                              : this.state.new_card_data.name_on_card
                          }
                          onChange={(evt) =>
                            this.updateNewCardData(evt, "description")
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li className='entry_people_li'>
                    <div className='add_modal_div'>
                      <div class='txt-field-container-div-modal'>
                        <label className='add_entry_label_modal'>
                          Credit Card Number &nbsp;<em class='required'>*</em>
                        </label>
                        <div style={{ clear: "both" }}></div>
                        <div class='txt-field-container-modal'>
                          <input
                            type='text'
                            name='card_number'
                            id='txt_add_card_number'
                            class='modal_input'
                            value={this.state.new_card_data.card_number}
                            onChange={(evt) =>
                              this.updateNewCardData(evt, "card_number")
                            }
                          />
                        </div>
                      </div>
                      <div class='txt-field-container-div-modal'>
                        <label className='add_entry_label_modal'>
                          Credit Type &nbsp;<em class='required'>*</em>
                        </label>
                        <div style={{ clear: "both" }}></div>
                        <div class='add_modal_div txt-field-container-modal'>
                          <select
                            className='modal_select'
                            value={this.state.new_card_data.type}
                            onChange={(e) => this.updateNewCardData(e, "type")}>
                            <option value='-1'>Select...</option>
                            {card_type_array.map((type, index) => {
                              return <option value={type}>{type}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* } */}
                  </li>
                  <li className='entry_people_li'>
                    <div style={{ clear: "both" }}></div>
                    <div className='add_modal_div'>
                      <div class='txt-field-container-div-modal'>
                        <label className='add_entry_label_modal'>
                          Expiration Month &nbsp;<em class='required'>*</em>
                        </label>
                        <div style={{ clear: "both" }}></div>
                        <div class='add_modal_div txt-field-container-modal'>
                          <select
                            className='modal_select'
                            value={this.state.new_card_data.card_exp_month}
                            onChange={(e) =>
                              this.updateNewCardData(e, "card_exp_month")
                            }>
                            <option value='-1'>Select...</option>
                            {months_array.map((month, index) => {
                              return (
                                <option value={`${month.value}`}>
                                  {month.month_no} - {month.month_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div class='txt-field-container-div-modal'>
                        <label className='add_entry_label_modal'>
                          Expiration Year &nbsp;<em class='required'>*</em>
                        </label>
                        <div style={{ clear: "both" }}></div>
                        <div class='add_modal_div txt-field-container-modal'>
                          <select
                            className='modal_select'
                            value={this.state.new_card_data.card_exp_year}
                            onChange={(e) =>
                              this.updateNewCardData(e, "card_exp_year")
                            }>
                            <option value='-1'>Select...</option>
                            {this.getExpYearsArray().map((type, index) => {
                              return (
                                <option value={`${type.year_value}`}>
                                  {type.year_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* } */}
                  </li>
                  <li className='entry_people_li'>
                    <div style={{ clear: "both" }}></div>
                    <div class='add_modal_div'>
                      <div class='txt-field-container-div-modal'>
                        <label className='add_entry_label_modal'>
                          Name on Card &nbsp;<em class='required'>*</em>
                        </label>
                        <div style={{ clear: "both" }}></div>
                        <div class='txt-field-container-modal'>
                          <input
                            type='text'
                            name='city'
                            id='txt_add_card_name'
                            class='modal_input'
                            value={this.state.new_card_data.name_on_card}
                            onChange={(evt) =>
                              this.updateNewCardData(evt, "name_on_card")
                            }
                          />
                        </div>
                      </div>
                      <div class='txt-field-container-div-modal'>
                        <div class='add_modal_div'>
                          <label className='add_entry_label_modal'>
                            Email Address &nbsp;
                            {this.props.check_card_email_phone_info ? (
                              <em class='required'>*</em>
                            ) : (
                              ""
                            )}
                          </label>
                          <div style={{ clear: "both" }}></div>
                          <div class='input-box txt-field-container-modal'>
                            <input
                              type='text'
                              name='state'
                              id='txt_add_card_email'
                              class='modal_input_right'
                              value={this.state.new_card_data.email}
                              onChange={(evt) =>
                                this.updateNewCardData(evt, "email")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className='entry_people_li'>
                    <div class=''>
                      <label className='add_entry_label_modal'>
                        Address Line 1 &nbsp;
                        {this.props.check_address_info ? (
                          <em class='required'>*</em>
                        ) : (
                          ""
                        )}
                      </label>
                      <div style={{ clear: "both" }}></div>
                      <div class='add_modal_div'>
                        <input
                          type='text'
                          name='address1'
                          class='modal_input_right'
                          id='txt_add_card_address1'
                          value={this.state.new_card_data.address_1}
                          onChange={(evt) =>
                            this.updateNewCardData(evt, "address_1")
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li className='entry_people_li'>
                    <div class=''>
                      <label className='add_entry_label_modal'>
                        Address Line 2
                      </label>
                      <div style={{ clear: "both" }}></div>
                      <div class='add_modal_div'>
                        <input
                          type='text'
                          name='address2'
                          class='modal_input_right'
                          id='txt_add_card_address2'
                          value={this.state.new_card_data.address_2}
                          onChange={(evt) =>
                            this.updateNewCardData(evt, "address_2")
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li className='entry_people_li'>
                    <div class='add_modal_div'>
                      <div class='txt-field-container-div-modal'>
                        <label className='add_entry_label_modal'>
                          City &nbsp;
                          {this.props.check_address_info ? (
                            <em class='required'>*</em>
                          ) : (
                            ""
                          )}
                        </label>
                        <div style={{ clear: "both" }}></div>
                        <div class='txt-field-container-modal'>
                          <input
                            type='text'
                            name='city'
                            id='txt_add_card_city'
                            class='modal_input'
                            value={this.state.new_card_data.city}
                            onChange={(evt) =>
                              this.updateNewCardData(evt, "city")
                            }
                          />
                        </div>
                      </div>
                      <div class='txt-field-container-div-modal'>
                        <div class='add_modal_div txt-field-container-modal'>
                          <label className='add_entry_label_modal'>
                            State &nbsp;
                            {this.props.check_address_info ? (
                              <em class='required'>*</em>
                            ) : (
                              ""
                            )}
                          </label>
                          <div style={{ clear: "both" }}></div>
                          <div class=''>
                            <select
                              className='modal_select'
                              value={this.state.new_card_data.state}
                              onChange={(e) =>
                                this.updateNewCardData(e, "state")
                              }>
                              <option value='-1'>
                                Select your state/province
                              </option>
                              <option value='--'>Outside U.S./Canada</option>
                              <optgroup label='Canada'>
                                <option value='AB'>Alberta</option>

                                <option value='BC'>British Columbia</option>

                                <option value='MB'>Manitoba</option>

                                <option value='NB'>New Brunswick</option>

                                <option value='NL'>
                                  Newfoundland and Labrador
                                </option>

                                <option value='NT'>
                                  Northwest Territories
                                </option>

                                <option value='NS'>Nova Scotia</option>

                                <option value='NU'>Nunavut</option>

                                <option value='ON'>Ontario</option>

                                <option value='PE'>Prince Edward Island</option>

                                <option value='QC'>Quebec</option>

                                <option value='SK'>Saskatchewan</option>

                                <option value='YT'>Yukon</option>
                              </optgroup>
                              <optgroup label='United States'>
                                <option value='AL'>Alabama</option>

                                <option value='AK'>Alaska</option>

                                <option value='AS'>American Samoa</option>

                                <option value='AZ'>Arizona</option>

                                <option value='AR'>Arkansas</option>

                                <option value='AA'>
                                  Armed Forces Americas
                                </option>

                                <option value='AE'>Armed Forces Europe</option>

                                <option value='AP'>Armed Forces Pacific</option>

                                <option value='CA'>California</option>

                                <option value='CO'>Colorado</option>

                                <option value='CT'>Connecticut</option>

                                <option value='DE'>Delaware</option>

                                <option value='DC'>District of Columbia</option>

                                <option value='FL'>Florida</option>

                                <option value='GA'>Georgia</option>

                                <option value='GU'>Guam</option>

                                <option value='HI'>Hawaii</option>

                                <option value='ID'>Idaho</option>

                                <option value='IL'>Illinois</option>

                                <option value='IN'>Indiana</option>

                                <option value='IA'>Iowa</option>

                                <option value='KS'>Kansas</option>

                                <option value='KY'>Kentucky</option>

                                <option value='LA'>Louisiana</option>

                                <option value='ME'>Maine</option>

                                <option value='MD'>Maryland</option>

                                <option value='MA'>Massachusetts</option>

                                <option value='MI'>Michigan</option>

                                <option value='MN'>Minnesota</option>

                                <option value='MS'>Mississippi</option>

                                <option value='MO'>Missouri</option>

                                <option value='MT'>Montana</option>

                                <option value='NE'>Nebraska</option>

                                <option value='NV'>Nevada</option>

                                <option value='NH'>New Hampshire</option>

                                <option value='NJ'>New Jersey</option>

                                <option value='NM'>New Mexico</option>

                                <option value='NY'>New York</option>

                                <option value='NC'>North Carolina</option>

                                <option value='ND'>North Dakota</option>

                                <option value='MP'>Northern Marianas</option>

                                <option value='OH'>Ohio</option>

                                <option value='OK'>Oklahoma</option>

                                <option value='OR'>Oregon</option>

                                <option value='PW'>Palau</option>

                                <option value='PA'>Pennsylvania</option>

                                <option value='PR'>Puerto Rico</option>

                                <option value='RI'>Rhode Island</option>

                                <option value='SC'>South Carolina</option>

                                <option value='SD'>South Dakota</option>

                                <option value='TN'>Tennessee</option>

                                <option value='TX'>Texas</option>

                                <option value='UT'>Utah</option>

                                <option value='VT'>Vermont</option>

                                <option value='VI'>Virgin Islands</option>

                                <option value='VA'>Virginia</option>

                                <option value='WA'>Washington</option>

                                <option value='WV'>West Virginia</option>

                                <option value='WI'>Wisconsin</option>

                                <option value='WY'>Wyoming</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className='entry_people_li'>
                    <div style={{ clear: "both" }}></div>
                    <div class='add_modal_div txt-field-container-modal'>
                      <div class='txt-field-container-div-modal'>
                        <label className='add_entry_label_modal'>
                          Zip Code &nbsp;
                          {this.props.check_address_info ? (
                            <em class='required'>*</em>
                          ) : (
                            ""
                          )}
                        </label>
                        <div style={{ clear: "both" }}></div>
                        <div class=''>
                          <input
                            type='text'
                            name='zip_code'
                            id='txt_add_card_zip_code'
                            class='modal_input'
                            value={this.state.new_card_data.zip}
                            onChange={(evt) =>
                              this.updateNewCardData(evt, "zip")
                            }
                          />
                        </div>
                      </div>
                      <div class='txt-field-container-div-modal'>
                        <label className='add_entry_label_modal'>
                          Country &nbsp;
                          {this.props.check_address_info ? (
                            <em class='required'>*</em>
                          ) : (
                            ""
                          )}
                        </label>
                        <div style={{ clear: "both" }}></div>
                        <div class='add_modal_div txt-field-container-modal'>
                          <select
                            className='modal_select'
                            value={this.state.new_card_data.country}
                            onChange={(e) =>
                              this.updateNewCardData(e, "country")
                            }>
                            <option value='-1'>Select...</option>
                            {
                              // countries_array.map((country,index) => {
                              //     return (
                              //         <option value={`${country}`}>
                              //             {country}
                              //         </option>
                              //     )
                              // })
                            }
                            <option value='' selected=''>
                              Select your Country
                            </option>
                            <option value='AF'>Afghanistan</option>
                            <option value='AX'>Åland Islands</option>
                            <option value='AL'>Albania</option>
                            <option value='DZ'>Algeria</option>
                            <option value='AS'>American Samoa</option>
                            <option value='AD'>Andorra</option>
                            <option value='AO'>Angola</option>
                            <option value='AI'>Anguilla</option>
                            <option value='AQ'>Antarctica</option>
                            <option value='AG'>Antigua and Barbuda</option>
                            <option value='AR'>Argentina</option>
                            <option value='AM'>Armenia</option>
                            <option value='AW'>Aruba</option>
                            <option value='AU'>Australia</option>
                            <option value='AT'>Austria</option>
                            <option value='AZ'>Azerbaijan</option>
                            <option value='BS'>Bahamas</option>
                            <option value='BH'>Bahrain</option>
                            <option value='BD'>Bangladesh</option>
                            <option value='BB'>Barbados</option>
                            <option value='BY'>Belarus</option>
                            <option value='BE'>Belgium</option>
                            <option value='BZ'>Belize</option>
                            <option value='BJ'>Benin</option>
                            <option value='BM'>Bermuda</option>
                            <option value='BT'>Bhutan</option>
                            <option value='BO'>Bolivia</option>
                            <option value='BA'>Bosnia and Herzegovina</option>
                            <option value='BW'>Botswana</option>
                            <option value='BV'>Bouvet Island</option>
                            <option value='BR'>Brazil</option>
                            <option value='IO'>
                              British Indian Ocean Territory
                            </option>
                            <option value='BN'>Brunei Darussalam</option>
                            <option value='BG'>Bulgaria</option>
                            <option value='BF'>Burkina Faso</option>
                            <option value='BI'>Burundi</option>
                            <option value='KH'>Cambodia</option>
                            <option value='CM'>Cameroon</option>
                            <option value='CA'>Canada</option>
                            <option value='CV'>Cape Verde</option>
                            <option value='KY'>Cayman Islands</option>
                            <option value='CF'>Central African Republic</option>
                            <option value='TD'>Chad</option>
                            <option value='CL'>Chile</option>
                            <option value='CN'>China</option>
                            <option value='CX'>Christmas Island</option>
                            <option value='CC'>Cocos (Keeling) Islands</option>
                            <option value='CO'>Colombia</option>
                            <option value='KM'>Comoros</option>
                            <option value='CG'>Congo</option>
                            <option value='CD'>
                              Congo, The Democratic Republic of the
                            </option>
                            <option value='CK'>Cook Islands</option>
                            <option value='CR'>Costa Rica</option>
                            <option value='CI'>Côte D’ivoire</option>
                            <option value='HR'>Croatia</option>
                            <option value='CU'>Cuba</option>
                            <option value='CY'>Cyprus</option>
                            <option value='CZ'>Czech Republic</option>
                            <option value='DK'>Denmark</option>
                            <option value='DJ'>Djibouti</option>
                            <option value='DM'>Dominica</option>
                            <option value='DO'>Dominican Republic</option>
                            <option value='EC'>Ecuador</option>
                            <option value='EG'>Egypt</option>
                            <option value='SV'>El Salvador</option>
                            <option value='GQ'>Equatorial Guinea</option>
                            <option value='ER'>Eritrea</option>
                            <option value='EE'>Estonia</option>
                            <option value='ET'>Ethiopia</option>
                            <option value='FK'>
                              Falkland Islands (Malvinas)
                            </option>
                            <option value='FO'>Faroe Islands</option>
                            <option value='FJ'>Fiji</option>
                            <option value='FI'>Finland</option>
                            <option value='FR'>France</option>
                            <option value='GF'>French Guiana</option>
                            <option value='PF'>French Polynesia</option>
                            <option value='TF'>
                              French Southern Territories
                            </option>
                            <option value='GA'>Gabon</option>
                            <option value='GM'>Gambia</option>
                            <option value='GE'>Georgia</option>
                            <option value='DE'>Germany</option>
                            <option value='GH'>Ghana</option>
                            <option value='GI'>Gibraltar</option>
                            <option value='GR'>Greece</option>
                            <option value='GL'>Greenland</option>
                            <option value='GD'>Grenada</option>
                            <option value='GP'>Guadeloupe</option>
                            <option value='GU'>Guam</option>
                            <option value='GT'>Guatemala</option>
                            <option value='GG'>Guernsey</option>
                            <option value='GN'>Guinea</option>
                            <option value='GW'>Guinea-Bissau</option>
                            <option value='GY'>Guyana</option>
                            <option value='HT'>Haiti</option>
                            <option value='HM'>
                              Heard Island and McDonald Islands
                            </option>
                            <option value='VA'>
                              Holy See (Vatican City state)
                            </option>
                            <option value='HN'>Honduras</option>
                            <option value='HK'>Hong Kong</option>
                            <option value='HU'>Hungary</option>
                            <option value='IS'>Iceland</option>
                            <option value='IN'>India</option>
                            <option value='ID'>Indonesia</option>
                            <option value='IR'>
                              Iran, Islamic Republic of
                            </option>
                            <option value='IQ'>Iraq</option>
                            <option value='IE'>Ireland</option>
                            <option value='IM'>Isle Of Man</option>
                            <option value='IL'>Israel</option>
                            <option value='IT'>Italy</option>
                            <option value='JM'>Jamaica</option>
                            <option value='JP'>Japan</option>
                            <option value='JE'>Jersey</option>
                            <option value='JO'>Jordan</option>
                            <option value='KZ'>Kazakhstan</option>
                            <option value='KE'>Kenya</option>
                            <option value='KI'>Kiribati</option>
                            <option value='KP'>
                              Korea, Democratic People’s Republic Of
                            </option>
                            <option value='KR'>Korea, Republic of</option>
                            <option value='KW'>Kuwait</option>
                            <option value='KG'>Kyrgyzstan</option>
                            <option value='LA'>
                              Lao People’s Democratic Republic
                            </option>
                            <option value='LV'>Latvia</option>
                            <option value='LB'>Lebanon</option>
                            <option value='LS'>Lesotho</option>
                            <option value='LR'>Liberia</option>
                            <option value='LY'>Libyan Arab Jamahiriya</option>
                            <option value='LI'>Liechtenstein</option>
                            <option value='LT'>Lithuania</option>
                            <option value='LU'>Luxembourg</option>
                            <option value='MO'>Macao</option>
                            <option value='MK'>
                              Macedonia, The Former Yugoslav Republic of
                            </option>
                            <option value='MG'>Madagascar</option>
                            <option value='MW'>Malawi</option>
                            <option value='MY'>Malaysia</option>
                            <option value='MV'>Maldives</option>
                            <option value='ML'>Mali</option>
                            <option value='MT'>Malta</option>
                            <option value='MH'>Marshall Islands</option>
                            <option value='MQ'>Martinique</option>
                            <option value='MR'>Mauritania</option>
                            <option value='MU'>Mauritius</option>
                            <option value='YT'>Mayotte</option>
                            <option value='MX'>Mexico</option>
                            <option value='FM'>
                              Micronesia, Federated States of
                            </option>
                            <option value='MD'>Moldova, Republic of</option>
                            <option value='MC'>Monaco</option>
                            <option value='MN'>Mongolia</option>
                            <option value='ME'>Montenegro</option>
                            <option value='MS'>Montserrat</option>
                            <option value='MA'>Morocco</option>
                            <option value='MZ'>Mozambique</option>
                            <option value='MM'>Myanmar</option>
                            <option value='NA'>Namibia</option>
                            <option value='NR'>Nauru</option>
                            <option value='NP'>Nepal</option>
                            <option value='NL'>Netherlands</option>
                            <option value='AN'>Netherlands Antilles</option>
                            <option value='NC'>New Caledonia</option>
                            <option value='NZ'>New Zealand</option>
                            <option value='NI'>Nicaragua</option>
                            <option value='NE'>Niger</option>
                            <option value='NG'>Nigeria</option>
                            <option value='NU'>Niue</option>
                            <option value='NF'>Norfolk Island</option>
                            <option value='MP'>Northern Mariana Islands</option>
                            <option value='NO'>Norway</option>
                            <option value='OM'>Oman</option>
                            <option value='PK'>Pakistan</option>
                            <option value='PW'>Palau</option>
                            <option value='PS'>
                              Palestinian Territory, Occupied
                            </option>
                            <option value='PA'>Panama</option>
                            <option value='PG'>Papua New Guinea</option>
                            <option value='PY'>Paraguay</option>
                            <option value='PE'>Peru</option>
                            <option value='PH'>Philippines</option>
                            <option value='PN'>Pitcairn</option>
                            <option value='PL'>Poland</option>
                            <option value='PT'>Portugal</option>
                            <option value='PR'>Puerto Rico</option>
                            <option value='QA'>Qatar</option>
                            <option value='RE'>Reunion</option>
                            <option value='RO'>Romania</option>
                            <option value='RU'>Russian Federation</option>
                            <option value='RW'>Rwanda</option>
                            <option value='BL'>Saint Barthélemy</option>
                            <option value='SH'>Saint Helena</option>
                            <option value='KN'>Saint Kitts and Nevis</option>
                            <option value='LC'>Saint Lucia</option>
                            <option value='MF'>Saint Martin</option>
                            <option value='PM'>
                              Saint Pierre and Miquelon
                            </option>
                            <option value='VC'>
                              Saint Vincent and the Grenadines
                            </option>
                            <option value='WS'>Samoa</option>
                            <option value='SM'>San Marino</option>
                            <option value='ST'>Sao Tome and Principe</option>
                            <option value='SA'>Saudi Arabia</option>
                            <option value='SN'>Senegal</option>
                            <option value='RS'>Serbia</option>
                            <option value='CS'>Serbia and Montenegro</option>
                            <option value='SC'>Seychelles</option>
                            <option value='SL'>Sierra Leone</option>
                            <option value='SG'>Singapore</option>
                            <option value='SK'>Slovakia</option>
                            <option value='SI'>Slovenia</option>
                            <option value='SB'>Solomon Islands</option>
                            <option value='SO'>Somalia</option>
                            <option value='ZA'>South Africa</option>
                            <option value='GS'>
                              South Georgia and the South Sandwich Islands
                            </option>
                            <option value='ES'>Spain</option>
                            <option value='LK'>Sri Lanka</option>
                            <option value='SD'>Sudan</option>
                            <option value='SR'>Suriname</option>
                            <option value='SJ'>Svalbard and Jan Mayen</option>
                            <option value='SZ'>Swaziland</option>
                            <option value='SE'>Sweden</option>
                            <option value='CH'>Switzerland</option>
                            <option value='SY'>Syrian Arab Republic</option>
                            <option value='TW'>
                              Taiwan, Province of China
                            </option>
                            <option value='TJ'>Tajikistan</option>
                            <option value='TZ'>
                              Tanzania, United Republic of
                            </option>
                            <option value='TH'>Thailand</option>
                            <option value='TL'>Timor-Leste</option>
                            <option value='TG'>Togo</option>
                            <option value='TK'>Tokelau</option>
                            <option value='TO'>Tonga</option>
                            <option value='TT'>Trinidad and Tobago</option>
                            <option value='TN'>Tunisia</option>
                            <option value='TR'>Turkey</option>
                            <option value='TM'>Turkmenistan</option>
                            <option value='TC'>Turks and Caicos Islands</option>
                            <option value='TV'>Tuvalu</option>
                            <option value='UG'>Uganda</option>
                            <option value='UA'>Ukraine</option>
                            <option value='AE'>United Arab Emirates</option>
                            <option value='GB'>United Kingdom</option>
                            <option value='US'>United States</option>
                            <option value='UM'>
                              United States Minor Outlying Islands
                            </option>
                            <option value='UY'>Uruguay</option>
                            <option value='UZ'>Uzbekistan</option>
                            <option value='VU'>Vanuatu</option>
                            <option value='VE'>Venezuela</option>
                            <option value='VN'>Viet Nam</option>
                            <option value='VG'>Virgin Islands, British</option>
                            <option value='VI'>Virgin Islands, U.S.</option>
                            <option value='WF'>Wallis and Futuna</option>
                            <option value='EH'>Western Sahara</option>
                            <option value='YE'>Yemen</option>
                            <option value='ZM'>Zambia</option>
                            <option value='ZW'>Zimbabwe</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className='entry_people_li'>
                    <div style={{ clear: "both" }}></div>
                    <div class='add_modal_div'>
                      <div class='txt-field-container-div-modal'>
                        <label className='add_entry_label_modal'>
                          Phone Number &nbsp;{" "}
                          {this.props.check_card_email_phone_info ? (
                            <em class='required'>*</em>
                          ) : (
                            ""
                          )}
                        </label>
                        <div style={{ clear: "both" }}></div>
                        <div class='input-box txt-field-container-modal'>
                          <input
                            type='text'
                            name='phone_number'
                            id='txt_add_card_phone_number'
                            class='modal_input'
                            value={this.state.new_card_data.phone}
                            onChange={(evt) =>
                              this.updateNewCardData(evt, "phone")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className='entry_people_li'>
                    <div style={{ clear: "both" }}></div>
                    <div
                      id='add_card_warning'
                      style={{ textIndent: "2px" }}
                      className='required display_none new-line'
                      dangerouslySetInnerHTML={{
                        __html: this.state.add_card_error_msg,
                      }}></div>

                    {/* <div id='add_card_warning' style={{textIndent:"2px"}}className='required display_none'>{this.state.add_card_error_msg}</div> */}
                  </li>
                </ul>
              </form>
            </div>
            <div class='continue-button-div'>
              <button class='left' onClick={this.closeAddCreditCardModal}>
                Cancel
              </button>
              <button class='right' onClick={this.addCreditCard}>
                Save
              </button>
            </div>
          </AddModal>
        </div>
        <AddModal
          isOpen={this.state.is_open_remove_card_modal}
          // onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeConfirmRemoveCardModal}
          className='mymodal modal-width-small'
          style={ManageCardsModalStyles}
          // overlayClassName="myoverlay"
          // ariaHideApp={false}
        >
          <h2>Remove Credit Card</h2>
          {/* <h3 class='top-margin-15'>Terms and Conditions</h3> */}
          <p class='top-margin-15'>
            Are you sure you want to remove this card?
          </p>
          {/* <div>
                        <p class='green-text'>Your credit card will be charged for {this.format_currancy(this.state.selected_subscription_obj.price)} (USD)</p>
                        </div>        */}
          <div className='continue-button-div'>
            <button class='left' onClick={this.closeConfirmRemoveCardModal}>
              Close
            </button>
            <button
              id='confirm_subscribe_button'
              class='right'
              onClick={this.RemoveCard}>
              OK
            </button>
          </div>
        </AddModal>
      </div>
    );
  }
}
ManageCards.contextType = SglContext;

export default ManageCards;
