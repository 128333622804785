import React, { Component } from "react";
import ReactModal from "react-modal";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { useState, useEffect, useContext } from "react";
import axios from "axios";

import SglContext from "./SglContext";

// IMPORT IMAGES
import indicator_icon from "../images/Indicator.gif";
import tick_icon from "../images/tick_icon.png";
import close_icon from "../images/close.png";
import { env } from "../env";

//API END POINTS
const sgl_webservice_terms_of_service =
  env.REACT_APP_SGL_API +
  "/iphone.php/webservice/GetTermsOfServiceForRegistration";
const sgl_webservice_excluded_countries =
  env.REACT_APP_SGL_API +
  "/iphone.php/webservice/GetRegRestrictedCountries";
const sgl_webservice_clear_session_values =
  env.REACT_APP_SGL_API + "/iphone.php/webservice/ClearSessionValues";

const sgl_webservice_verify_mobile =
  env.REACT_APP_SGL_API + "/iphone.php/webservice/VerifyMobilePhone";
const sgl_webservice_verify_otp =
  env.REACT_APP_SGL_API + "/iphone.php/webservice/VerifyOTP";
const sgl_webservice_process_registration =
  env.REACT_APP_SGL_API +
  "/iphone.php/webservice/ProcessRegisterationData";

var AddModal = ReactModal;

function RegistrationForm(props) {
  const [termsOfService, setTermsOfService] = useState("");
  const [excludeCountries, setExcludeCountries] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const [verifyMobileOpenState, setVerifyMobileOpenState] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const Sgl = useContext(SglContext);

  const verifyMobileModalStyles = {
    content: {
      border: "1px solid #ccc",
      borderRadius: "4px",
      left: "50%",
      maxHeight: "70%",
      outline: "none",
      overflow: "auto",
      position: "fixed",
      top: "55%",
      transform: "translate(-50%,-50%)",
      maxWidth: "400px",
      width: "60%",
      padding: "5px 20px 0",
    },
    overlay: { zIndex: 1000 },
  };

  useEffect(() => {
    if(props.isMobile){
      let popup_card_header = document.getElementById("popup-card-header");
      popup_card_header.style.display = "none";
    }
  }, [props.isMobile]);
  
  useEffect(() => {
    let sgl_plugin_form = document.getElementById("sgl-plugin-form");
    sgl_plugin_form.style.display = "block";
    let phone_input = document.querySelector("#telephone-input");
  }, []);

  useEffect(() => {
    axios.all([
      axios.get(sgl_webservice_excluded_countries),
      axios.get(sgl_webservice_terms_of_service, {
        params: {
          customer_id: Sgl.company_id,
        },
      })
    ]).then(axios.spread((countries_obj, terms_obj) => {
      setExcludeCountries(countries_obj.data);
      setTermsOfService(terms_obj.data);
      setLoading(false);

    }))
    // axios.get(sgl_webservice_excluded_countries)
    // .then((res) => {
    //   setExcludeCountries(res.data);
    // });

    // axios.get(sgl_webservice_terms_of_service, {
    //   params: {
    //     customer_id: Sgl.company_id,
    //   },
    // })
    // .then((res) => {
    //   setTermsOfService(res.data);
    //   setLoading(false);
    // });
  }, []);

  function handleBlur() {
    let errorContainer_bottom = document.getElementById(
      "reg_error_summary_bottom"
    );

    if (mobileNumber.formattedValue === "" || mobileNumber.isValid) {
      errorContainer_bottom.innerHTML = "";
      errorContainer_bottom.style.display = "none";
    } else {
      errorContainer_bottom.innerHTML = "Please enter valid mobile number";
      errorContainer_bottom.style.display = "block";
    }
  }

  function reset() {
    let phone_input_hidden = document.getElementById("telephone-input");
    phone_input_hidden.value = "";
  }

  function verifyMobile() {
    let errorContainer_bottom = document.getElementById(
      "reg_error_summary_bottom"
    );
    let verify_mobile_button = document.getElementById("verifyMobile_btn");
    let please_wait = document.getElementById("verify_mobile-please-wait");

    if (!mobileNumber.isValid) {
      errorContainer_bottom.innerHTML = "Please enter valid mobile number";
      errorContainer_bottom.style.display = "block";
      verify_mobile_button.style.display = "block";
      please_wait.style.display = "none";
      return 0;
    } else {
      errorContainer_bottom.innerHTML = "";
      errorContainer_bottom.style.display = "none";
      verify_mobile_button.style.display = "none";
      please_wait.style.display = "block";
    }

    axios
      .get(sgl_webservice_verify_mobile, {
        params: {
          mobile_phone: mobileNumber.formattedValue,
        },
      })
      .then((response) => {
        if (response.data.result) {
          setVerifyMobileOpenState(true);
        } else {
          errorContainer_bottom.innerHTML = response.data.error;
          errorContainer_bottom.style.display = "block";
          verify_mobile_button.style.display = "block";
          please_wait.style.display = "none";
        }
      });
  }

  function verifyOTP() {
    let otp_please_wait = document.getElementById("verify_otp-please-wait");
    let otp_verify_btn = document.getElementById("verify_otp_btn");
    let otp_error_summary = document.getElementById("verify_otp_error_summary");
    let verify_mobile_button = document.getElementById("verifyMobile_btn");
    let mobile_verified = document.getElementById("mobile-verified");
    let otp = document.getElementById("otp");
    let please_wait = document.getElementById("verify_mobile-please-wait");

    otp_please_wait.style.display = "block";
    otp_verify_btn.style.display = "none";
    otp_error_summary.style.display = "none";

    axios
      .get(sgl_webservice_verify_otp, {
        params: {
          otp: otp.value,
        },
      })
      .then((response) => {
        otp_please_wait.style.display = "none";
        if (response.data.result) {
          verify_mobile_button.style.display = "none";
          mobile_verified.style.display = "block";
          please_wait.style.display = "none";
          // phone_input_hidden.readOnly = true;
          // phone_input.readOnly = true;

          setVerifyMobileOpenState(false);
        } else {
          otp_error_summary.innerHTML = response.data.error;
          otp_error_summary.style.display = "block";
          otp_verify_btn.style.display = "block";
        }
      });
  }

  function closeVerifyMobile(e) {
    e.preventDefault();
    let verify_mobile_button = document.getElementById("verifyMobile_btn");
    let please_wait = document.getElementById("verify_mobile-please-wait");

    setVerifyMobileOpenState(false);
    please_wait.style.display = "none";
    verify_mobile_button.style.display = "block";
  }

  function showTermsAndConditions() {
    document.getElementById("terms_and_conditions_cont").style.display =
      "block";
    document.getElementById("terms_cond_overlay").style.display = "block";
    document.getElementById("terms_and_conditions_cont").style.zIndex = 9999;
    document.getElementById("terms_cond_overlay").style.zIndex = 9999;
    window.scrollTo(0, 0);
  }

  function hideTermsAndConditions() {
    document.getElementById("terms_and_conditions_cont").style.display = "none";
    document.getElementById("terms_cond_overlay").style.display = "none";
  }

  function processRegistration(e) {
    e.preventDefault();
    let email = document.getElementById("email");
    let pass = document.getElementById("pass");
    let conf_pass = document.getElementById("conf_pass");
    let first = document.getElementById("first");
    let last = document.getElementById("last");
    let address = document.getElementById("address");
    let city = document.getElementById("city");
    let state = document.getElementById("state");
    let postalcode = document.getElementById("postalcode");
    let telephone = document.getElementById("telephone");

    let sgl_plugin_form = document.getElementById("sgl-plugin-form");
    let sgl_plugin_success_message = document.getElementById(
      "sgl-plugin-success-message"
    );
    let errorContainer_bottom = document.getElementById(
      "reg_error_summary_bottom"
    );

    errorContainer_bottom.innerHTML = "";
    errorContainer_bottom.style.display = "none";

    axios
      .post(sgl_webservice_process_registration, null, {
        params: {
          email: email.value,
          pass: pass.value,
          conf_pass: conf_pass.value,
          first: first.value,
          last: last.value,
          address: address.value,
          city: city.value,
          state: state.value,
          postalcode: postalcode.value,
          telephone: mobileNumber.formattedValue,
          customer_id: Sgl.company_id,
          site_url: `${window.location.protocol}//${window.location.host}${ (Sgl.basePath && Sgl.basePath != "/" && Sgl.basePath != "") ? `${Sgl.basePath}` : "/showgrounds"}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          sgl_plugin_form.innerHTML = "";
          sgl_plugin_form.style.display = "none";
          sgl_plugin_success_message.style.display = "block";
        } else {
          let message_lines = "";
          for (let i = 0; i < response.data.message.length; i++) {
            message_lines += response.data.message[i] + "<br/>";
          }
          errorContainer_bottom.innerHTML = message_lines;
          errorContainer_bottom.style.display = "block";
        }
      });
  }

  const [mobileNumber, setMobileNumber] = useState({
    isValid: false,
    rawValue: null,
    countryData: null,
    formattedValue: "",
    extension: null,
  });

  function handlePhoneNumberChange(
    _isValid,
    _rawValue,
    _countryData,
    _formattedValue,
    _extension
  ) {
    setMobileNumber({
      isValid: _isValid,
      rawValue: _rawValue,
      countryData: _countryData,
      formattedValue: _formattedValue,
      extension: _extension,
    });
  }

  return (
    <div className='popup-card-deck' style={{ padding: "0px 0px" }}>
      <div class='popup-card-item'>
        <div class='bar'> &nbsp; </div>
        <div class='popup-card'>
          {/* HEADER */}
          <div class='popup-card-header' id='popup-card-header'>
            <h3 class='popup-card-title' style={{ textAlign: "center" }}>
              Registration
            </h3>
            <div style={{ textAlign: "center", fontSize: "12px" }}>
              {process.env.REACT_APP_CREDIT_TEXT}{" "}
              <a
                onClick={() =>
                  window.open(
                    `https://www.showgroundslive.com`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }>
                {" "}
                {process.env.REACT_APP_CREDIT_COMPANY}{" "}
              </a>
            </div>
          </div>
          {/* END HEADER */}
          <div>
            {/* form start */}

            <div className='popup-card-body' id='sgl-plugin-form'>
              <div className='div-responsive'>
                <div className='reg_message'>
                  <div>
                    <p>
                      Fill the form below to begin your registration.
                      <br />
                      You must have a valid email address to register for{" "}
                      {Sgl.company_name}
                    </p>
                  </div>

                  {/* <div className='error_container display_on_mobile'>
                  <div>
                    <p
                      className='error_summary reg_error_summary '
                      id='reg_error_summary_top'></p>
                  </div>
                </div> */}
                </div>

                <div className='full'>
                  <input
                    type='text'
                    placeholder='Email'
                    name='email'
                    id='email'
                  />
                </div>

                <div className='full'>
                  <input
                    type='password'
                    placeholder='Password'
                    name='pass'
                    id='pass'
                  />
                </div>

                <div className='full'>
                  <input
                    type='password'
                    placeholder='Confirm Password'
                    name='conf_pass'
                    id='conf_pass'
                  />
                </div>

                <div className='full'>
                  <div className='half'>
                    <input
                      type='text'
                      placeholder='First Name'
                      id='first'
                      name='first'
                    />
                  </div>

                  <div className='half'>
                    <input
                      type='text'
                      placeholder='Last Name'
                      name='last'
                      id='last'
                    />
                  </div>
                </div>

                <div className='full'>
                  <input
                    type='text'
                    placeholder='Address'
                    name='address'
                    id='address'
                  />
                </div>

                <div className='full'>
                  <div className='half'>
                    <input
                      type='text'
                      placeholder='City'
                      name='city'
                      id='city'
                    />
                  </div>

                  <div className='half'>
                    <input
                      type='text'
                      placeholder='State'
                      name='state'
                      id='state'
                    />
                  </div>
                </div>

                <div className='full'>
                  <div className='half'>
                    <input
                      type='text'
                      placeholder='Postal Code'
                      name='postalcode'
                      id='postalcode'
                    />
                  </div>

                  <div className='half'>
                    { !loading ?
                    <IntlTelInput
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                      preferredCountries={["us", "ca"]}
                      initialCountry={"us"}
                      allowDropdown={true}
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onPhoneNumberBlur={handleBlur}
                      placeholder='Mobile Number'
                      id='telephone-input'
                      excludeCountries={excludeCountries}
                    />
                    :null
                    }

                    <input type='hidden' name='telephone' id='telephone' />
                  </div>
                </div>

                <div className='full'>
                  <a
                    href='javascript:void(0);'
                    id='verifyMobile_btn'
                    onMouseDown={verifyMobile}
                    className='link'>
                    Verify Mobile
                  </a>
                  <span id='verify_mobile-please-wait'>
                    <img src={indicator_icon} /> Verifiying...
                  </span>
                  <span id='mobile-verified'>
                    <img src={tick_icon} /> Verified{" "}
                  </span>
                </div>

                <div className='error_container'>
                  <div>
                    <p
                      className='error_summary reg_error_summary'
                      id='reg_error_summary_bottom'></p>
                  </div>
                </div>

                <div className='full terms' style={{}}>
                  By creating an account, you agree to our{" "}
                  <a
                    href='javascript:void(0);'
                    onClick={showTermsAndConditions}
                    className='link'>
                    Terms &amp; Conditions
                  </a>
                </div>

                <div
                  className='full submit'
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}>
                  <input
                    type='button'
                    id='update'
                    className='btn'
                    onClick={processRegistration}
                    value='Register'
                  />
                  &nbsp; &nbsp;
                  <input
                    type='button'
                    className='cancelBtn'
                    value='Cancel'
                    onClick={props.handleRegistrationClose}
                  />
                </div>

                {/*terms and conditions start*/}
                <div
                  className='terms_cond_overlay'
                  id='terms_cond_overlay'></div>

                <div
                  className='terms_and_conditions_cont'
                  id='terms_and_conditions_cont'>
                  <div className='close_terms_cond'>
                    <a
                      href='javascript:void(0);'
                      onClick={hideTermsAndConditions}
                      className='link'>
                      <img src={close_icon} />
                    </a>
                  </div>
                  <div className='terms_cond_header'>
                    <h2>TERMS &amp; CONDITIONS</h2>
                  </div>
                  <textarea
                    className='term_and_conditions_txt'
                    id='term_and_conditions_txt'
                    value={termsOfService}
                  />
                </div>
                {/*terms and conditions end*/}

                <div className='child_modal'>
                  <AddModal
                    isOpen={verifyMobileOpenState}
                    // onAfterOpen={afterOpenVerifyMobile}
                    onRequestClose={closeVerifyMobile}
                    className='mymodal modal-width-responsive'
                    style={verifyMobileModalStyles}
                    // overlayClassName='myoverlay'
                    // ariaHideApp={false}
                  >
                    <h2 className='sgl-label-border'>Verify Mobile</h2>
                    <p>Please enter Verification Code</p>
                    <p
                      id='verify_otp_error_summary'
                      className='otp_error_summary'>
                      Please enter verification code sent to your phone.
                    </p>
                    <input
                      type='text'
                      maxlength='5'
                      id='otp'
                      name='otp'
                      className='otp'
                      placeholder='Verification Code'></input>
                    <div>
                      <button
                        onClick={closeVerifyMobile}
                        className='verify_mobile_buttons left'>
                        Close
                      </button>
                      <button
                        id='verify_otp_btn'
                        onClick={verifyOTP}
                        className='verify_mobile_buttons right'>
                        Verify
                      </button>
                      <span
                        id='verify_otp-please-wait'
                        className='verify_otp-please-wait right'>
                        <img src={indicator_icon} /> Processing...
                      </span>
                    </div>
                  </AddModal>
                </div>

                <div className='copyright-text'>
                  {process.env.REACT_APP_COPYRIGHT_TEXT}
                </div>
              </div>
            </div>

            <div
              class='popup-card-body'
              id='sgl-plugin-success-message'
              style={{display:'none',background:'white',padding:'10px',textAlign:'center'}}
              >
              <p>
                Thank you for registering
                <br />
                <br />A confirmation email has been sent to you. You must access
                your email and confirm the registration prior to logging in.{" "}
              </p>
            </div>
          </div>
          {/* form end */}

        </div>
      </div>
    </div>
  );
}
export default RegistrationForm;
